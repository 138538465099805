import { date2slash } from "@/utils/functions";
import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Client {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.clientToken = "";
    this.clientCode = "";
    this.clientNameCurrent = "";
    this.clientNameEn = "";
    this.clientNameAr = "";
    this.clientNameUnd = "";
    this.establishmentRoleToken = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.clientAddressCurrent = "";
    this.clientAddressEn = "";
    this.clientAddressAr = "";
    this.clientAddressUnd = "";
    this.clientCountryToken = "";
    this.countryNameCurrent = "";
    this.clientGovernorateToken = "";
    this.governorateNameCurrent = "";
    this.clientCityToken = "";
    this.cityNameCurrent = "";
    this.clientDistrictToken = "";
    this.districtNameCurrent = "";
    this.clientQualificationToken = "";
    this.qualificationNameCurrent = "";
    this.clientBankToken = "";
    this.bankNameCurrent = "";
    this.clientBloodTypeToken = "";
    this.bloodTypeNameCurrent = "";
    this.clientScientificDegreeToken = "";
    this.scientificDegreeNameCurrent = "";
    this.clientCountryCodeName = "";
    this.clientCountryCode = "";
    this.clientPhone = "";
    this.clientPhoneWithCC = "";
    this.clientBirthdate = "";
    this.clientUserName = "";
    this.clientEmail = "";
    this.clientNationalNumber = "";
    this.clientNationalNumberExpireDate = "";
    this.clientGenderToken = "";
    this.clientGenderNameCurrent = "";
    this.clientGenderNameAr = "";
    this.clientGenderNameEn = "";
    this.clientGenderNameUnd = "";
    this.clientImagePath = "";
    this.clientDrivingLicenceNumber = "";
    this.clientDrivingLicenceExpireDate = "";
    this.clientPassportNumber = "";
    this.clientPassportExpireDate = "";
    this.clientMaritalToken = "";
    this.clientMaritalNameCurrent = "";
    this.clientMaritalNameAr = "";
    this.clientMaritalNameEn = "";
    this.clientMaritalNameUnd = "";
    this.clientMilitaryToken = "";
    this.clientMilitaryNameCurrent = "";
    this.clientMilitaryNameAr = "";
    this.clientMilitaryNameEn = "";
    this.clientMilitaryNameUnd = "";
    this.clientMaritalStatusNotes = "";
    this.clientMilitaryStatusNotes = "";
    this.clientCitizenShipTypeToken = "";
    this.clientCitizenShipTypeNameCurrent = "";
    this.clientCitizenShipTypeNameAr = "";
    this.clientCitizenShipTypeNameEn = "";
    this.clientCitizenShipTypeNameUnd = "";
    this.clientResidencyNumber = "";
    this.clientResidencyStartDate = "";
    this.clientResidencyEndDate = "";
    this.clientPassword = "";
    this.clientNotes = "";
    this.clientActiveStatus = true;
    this.clientArchiveStatus = "";
    this.clientRelativeRelationToken = "";
    this.relativeRelationToken = "";
    this.relativeRelationNameCurrent = "";
    this.clientRelativeRelationInfoData = "";
    this.clientNickNameCurrent = "";
    this.clientNickNameEn = "";
    this.clientNickNameAr = "";
    this.clientNickNameUnd = "";
    this.clientInsuranceCompanyToken = "";
    this.insuranceCompanyNameCurrent = "";
    this.clientInsuranceNumber = "";
    this.clientAgeCurrent = "";
    this.clientDateTimeCreated = "";
    this.clientSuperToken = "";
    this.clientSuperNameCurrent = "";
    this.clientTypeToken = "";
    this.clientTypeNameCurrent = "";
    this.clientTypeNameAr = "";
    this.clientTypeNameEn = "";
    this.clientTypeNameUnd = "";
    this.knownMethodTypeToken = "";
    this.knownMethodTypeNameCurrent = "";
    this.knownMethodTypeNameAr = "";
    this.knownMethodTypeNameEn = "";
    this.knownMethodTypeNameUnd = "";
    this.knownMethodEmployeeToken = "";
    this.knownMethodEmployeeNameCurrent = "";
    this.knownMethodClientToken = "";
    this.knownMethodClientNameCurrent = "";
    this.knowingMethodToken = "";
    this.knowingMethodNameCurrent = "";
    this.clientCountFamilyMember = 0;
  }
  fillData(data) {
    this.image = data.image;
    this.clientToken = data.clientToken;
    this.clientCode = data.clientCode;
    this.clientNameCurrent = data.clientNameCurrent;
    this.clientNameEn = data.clientNameEn;
    this.clientNameAr = data.clientNameAr;
    this.clientNameUnd = data.clientNameUnd;
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.clientAddressCurrent = data.clientAddressCurrent;
    this.clientAddressEn = data.clientAddressEn;
    this.clientAddressAr = data.clientAddressAr;
    this.clientAddressUnd = data.clientAddressUnd;
    this.clientCountryToken = data.clientCountryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.clientGovernorateToken = data.clientGovernorateToken;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.clientCityToken = data.clientCityToken;
    this.cityNameCurrent = data.cityNameCurrent;
    this.clientDistrictToken = data.clientDistrictToken;
    this.districtNameCurrent = data.districtNameCurrent;
    this.clientQualificationToken = data.clientQualificationToken;
    this.qualificationNameCurrent = data.qualificationNameCurrent;
    this.clientBankToken = data.clientBankToken;
    this.bankNameCurrent = data.bankNameCurrent;
    this.clientBloodTypeToken = data.clientBloodTypeToken;
    this.bloodTypeNameCurrent = data.bloodTypeNameCurrent;
    this.clientScientificDegreeToken = data.clientScientificDegreeToken;
    this.scientificDegreeNameCurrent = data.scientificDegreeNameCurrent;
    this.clientCountryCodeName = data.clientCountryCodeName;
    this.clientCountryCode = data.clientCountryCode;
    this.clientPhone = data.clientPhone;
    this.clientPhoneWithCC = data.clientPhoneWithCC;
    this.clientBirthdate = data.clientBirthdate;
    this.clientUserName = data.clientUserName;
    this.clientEmail = data.clientEmail;
    this.clientNationalNumber = data.clientNationalNumber;
    this.clientNationalNumberExpireDate = data.clientNationalNumberExpireDate;
    this.clientGenderToken = data.clientGenderToken;
    this.clientGenderNameCurrent = data.clientGenderNameCurrent;
    this.clientGenderNameAr = data.clientGenderNameAr;
    this.clientGenderNameEn = data.clientGenderNameEn;
    this.clientGenderNameUnd = data.clientGenderNameUnd;
    this.clientImagePath = data.clientImagePath;
    this.clientDrivingLicenceNumber = data.clientDrivingLicenceNumber;
    this.clientDrivingLicenceExpireDate = data.clientDrivingLicenceExpireDate;
    this.clientPassportNumber = data.clientPassportNumber;
    this.clientPassportExpireDate = data.clientPassportExpireDate;
    this.clientMaritalToken = data.clientMaritalToken;
    this.clientMaritalNameCurrent = data.clientMaritalNameCurrent;
    this.clientMaritalNameAr = data.clientMaritalNameAr;
    this.clientMaritalNameEn = data.clientMaritalNameEn;
    this.clientMaritalNameUnd = data.clientMaritalNameUnd;
    this.clientMilitaryToken = data.clientMilitaryToken;
    this.clientMilitaryNameCurrent = data.clientMilitaryNameCurrent;
    this.clientMilitaryNameAr = data.clientMilitaryNameAr;
    this.clientMilitaryNameEn = data.clientMilitaryNameEn;
    this.clientMilitaryNameUnd = data.clientMilitaryNameUnd;
    this.clientMaritalStatusNotes = data.clientMaritalStatusNotes;
    this.clientMilitaryStatusNotes = data.clientMilitaryStatusNotes;
    this.clientCitizenShipTypeToken = data.clientCitizenShipTypeToken;
    this.clientCitizenShipTypeNameCurrent =
      data.clientCitizenShipTypeNameCurrent;
    this.clientCitizenShipTypeNameAr = data.clientCitizenShipTypeNameAr;
    this.clientCitizenShipTypeNameEn = data.clientCitizenShipTypeNameEn;
    this.clientCitizenShipTypeNameUnd = data.clientCitizenShipTypeNameUnd;
    this.clientResidencyNumber = data.clientResidencyNumber;
    this.clientResidencyStartDate = data.clientResidencyStartDate;
    this.clientResidencyEndDate = data.clientResidencyEndDate;
    this.clientPassword = data.clientPassword;
    this.clientNotes = data.clientNotes;
    this.clientActiveStatus = data.clientActiveStatus;
    this.clientArchiveStatus = data.clientArchiveStatus;
    this.clientRelativeRelationToken = data.clientRelativeRelationToken;
    this.relativeRelationToken = data.relativeRelationToken;
    this.relativeRelationNameCurrent = data.relativeRelationNameCurrent;
    this.clientRelativeRelationInfoData = data.clientRelativeRelationInfoData;
    this.clientNickNameCurrent = data.clientNickNameCurrent;
    this.clientNickNameEn = data.clientNickNameEn;
    this.clientNickNameAr = data.clientNickNameAr;
    this.clientNickNameUnd = data.clientNickNameUnd;
    this.clientInsuranceCompanyToken = data.clientInsuranceCompanyToken;
    this.insuranceCompanyNameCurrent = data.insuranceCompanyNameCurrent;
    this.clientInsuranceNumber = data.clientInsuranceNumber;
    this.clientAgeCurrent = data.clientAgeCurrent;
    this.clientDateTimeCreated = data.clientDateTimeCreated;
    this.clientSuperToken = data.clientSuperToken;
    this.clientSuperNameCurrent = data.clientSuperNameCurrent;
    this.clientTypeToken = data.clientTypeToken;
    this.clientTypeNameCurrent = data.clientTypeNameCurrent;
    this.clientTypeNameAr = data.clientTypeNameAr;
    this.clientTypeNameEn = data.clientTypeNameEn;
    this.clientTypeNameUnd = data.clientTypeNameUnd;
    this.knownMethodTypeToken = data.knownMethodTypeToken;
    this.knownMethodTypeNameCurrent = data.knownMethodTypeNameCurrent;
    this.knownMethodTypeNameAr = data.knownMethodTypeNameAr;
    this.knownMethodTypeNameEn = data.knownMethodTypeNameEn;
    this.knownMethodTypeNameUnd = data.knownMethodTypeNameUnd;
    this.knownMethodEmployeeToken = data.knownMethodEmployeeToken;
    this.knownMethodEmployeeNameCurrent = data.knownMethodEmployeeNameCurrent;
    this.knownMethodClientToken = data.knownMethodClientToken;
    this.knownMethodClientNameCurrent = data.knownMethodClientNameCurrent;
    this.knowingMethodToken = data.knowingMethodToken;
    this.knowingMethodNameCurrent = data.knowingMethodNameCurrent;
    this.clientCountFamilyMember = data.clientCountFamilyMember;
  }

  async getAllClients(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    filter
  ) {
    try {
      let response = await axios.get(
        `/Clients/GetAllClients?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&establishmentRoleToken=${filter}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getClientDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Clients/GetClientDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&clientToken=${this.clientToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfClients(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Clients/GetDialogOfClients?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateClient(language, userAuthorizeToken) {
    if (this.clientCCWithCCName) {
      let ccNameWithCC = this.clientCCWithCCName.split(" ");
      this.clientCountryCodeName = ccNameWithCC[0];
      this.clientCountryCode = ccNameWithCC[1];
    }

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      clientToken: this.clientToken,
      clientCode: this.clientCode,
      clientNameCurrent: this.clientNameCurrent,
      clientNameEn: this.clientNameEn,
      clientNameAr: this.clientNameAr,
      clientNameUnd: this.clientNameUnd,
      establishmentRoleToken: this.establishmentRoleToken,
      establishmentRoleNameCurrent: this.establishmentRoleNameCurrent,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      clientAddressCurrent: this.clientAddressCurrent,
      clientAddressEn: this.clientAddressEn,
      clientAddressAr: this.clientAddressAr,
      clientAddressUnd: this.clientAddressUnd,
      clientCountryToken: this.clientCountryToken,
      countryNameCurrent: this.countryNameCurrent,
      clientGovernorateToken: this.clientGovernorateToken,
      governorateNameCurrent: this.governorateNameCurrent,
      clientCityToken: this.clientCityToken,
      cityNameCurrent: this.cityNameCurrent,
      clientDistrictToken: this.clientDistrictToken,
      districtNameCurrent: this.districtNameCurrent,
      clientQualificationToken: this.clientQualificationToken,
      qualificationNameCurrent: this.qualificationNameCurrent,
      clientBankToken: this.clientBankToken,
      bankNameCurrent: this.bankNameCurrent,
      clientBloodTypeToken: this.clientBloodTypeToken,
      bloodTypeNameCurrent: this.bloodTypeNameCurrent,
      clientScientificDegreeToken: this.clientScientificDegreeToken,
      scientificDegreeNameCurrent: this.scientificDegreeNameCurrent,
      clientCountryCodeName: this.clientCountryCodeName,
      clientCountryCode: this.clientCountryCode,
      clientPhone: this.clientPhone,
      clientPhoneWithCC: this.clientPhoneWithCC,
      clientBirthdate: this.clientBirthdate,
      clientUserName: this.clientUserName,
      clientEmail: this.clientEmail,
      clientNationalNumber: this.clientNationalNumber,
      clientNationalNumberExpireDate: date2slash(
        this.clientNationalNumberExpireDate
      ),
      clientGenderToken: this.clientGenderToken,
      clientGenderNameCurrent: this.clientGenderNameCurrent,
      clientGenderNameAr: this.clientGenderNameAr,
      clientGenderNameEn: this.clientGenderNameEn,
      clientGenderNameUnd: this.clientGenderNameUnd,
      clientImagePath: this.clientImagePath,
      clientDrivingLicenceNumber: this.clientDrivingLicenceNumber,
      clientDrivingLicenceExpireDate: date2slash(
        this.clientDrivingLicenceExpireDate
      ),
      clientPassportNumber: this.clientPassportNumber,
      clientPassportExpireDate: date2slash(this.clientPassportExpireDate),
      clientMaritalToken: this.clientMaritalToken,
      clientMaritalNameCurrent: this.clientMaritalNameCurrent,
      clientMaritalNameAr: this.clientMaritalNameAr,
      clientMaritalNameEn: this.clientMaritalNameEn,
      clientMaritalNameUnd: this.clientMaritalNameUnd,
      clientMilitaryToken: this.clientMilitaryToken,
      clientMilitaryNameCurrent: this.clientMilitaryNameCurrent,
      clientMilitaryNameAr: this.clientMilitaryNameAr,
      clientMilitaryNameEn: this.clientMilitaryNameEn,
      clientMilitaryNameUnd: this.clientMilitaryNameUnd,
      clientMaritalStatusNotes: this.clientMaritalStatusNotes,
      clientMilitaryStatusNotes: this.clientMilitaryStatusNotes,
      clientCitizenShipTypeToken: this.clientCitizenShipTypeToken,
      clientCitizenShipTypeNameCurrent: this.clientCitizenShipTypeNameCurrent,
      clientCitizenShipTypeNameAr: this.clientCitizenShipTypeNameAr,
      clientCitizenShipTypeNameEn: this.clientCitizenShipTypeNameEn,
      clientCitizenShipTypeNameUnd: this.clientCitizenShipTypeNameUnd,
      clientResidencyNumber: this.clientResidencyNumber,
      clientResidencyStartDate: date2slash(this.clientResidencyStartDate),
      clientResidencyEndDate: date2slash(this.clientResidencyEndDate),
      clientPassword: this.clientPassword,
      clientNotes: this.clientNotes,
      clientActiveStatus: this.clientActiveStatus,
      clientArchiveStatus: this.clientArchiveStatus,
      clientRelativeRelationToken: this.clientRelativeRelationToken,
      relativeRelationToken: this.relativeRelationToken,
      relativeRelationNameCurrent: this.relativeRelationNameCurrent,
      clientRelativeRelationInfoData: this.clientRelativeRelationInfoData,
      clientNickNameCurrent: this.clientNickNameCurrent,
      clientNickNameEn: this.clientNickNameEn,
      clientNickNameAr: this.clientNickNameAr,
      clientNickNameUnd: this.clientNickNameUnd,
      clientInsuranceCompanyToken: this.clientInsuranceCompanyToken,
      insuranceCompanyNameCurrent: this.insuranceCompanyNameCurrent,
      clientInsuranceNumber: this.clientInsuranceNumber,
      clientAgeCurrent: this.clientAgeCurrent,
      clientDateTimeCreated: this.clientDateTimeCreated,
      clientSuperToken: this.clientSuperToken,
      clientSuperNameCurrent: this.clientSuperNameCurrent,
      clientTypeToken: this.clientTypeToken,
      clientTypeNameCurrent: this.clientTypeNameCurrent,
      clientTypeNameAr: this.clientTypeNameAr,
      clientTypeNameEn: this.clientTypeNameEn,
      clientTypeNameUnd: this.clientTypeNameUnd,
      knownMethodTypeToken: this.knownMethodTypeToken,
      knownMethodTypeNameCurrent: this.knownMethodTypeNameCurrent,
      knownMethodTypeNameAr: this.knownMethodTypeNameAr,
      knownMethodTypeNameEn: this.knownMethodTypeNameEn,
      knownMethodTypeNameUnd: this.knownMethodTypeNameUnd,
      knownMethodEmployeeToken: this.knownMethodEmployeeToken,
      knownMethodEmployeeNameCurrent: this.knownMethodEmployeeNameCurrent,
      knownMethodClientToken: this.knownMethodClientToken,
      knownMethodClientNameCurrent: this.knownMethodClientNameCurrent,
      knowingMethodToken: this.knowingMethodToken,
      knowingMethodNameCurrent: this.knowingMethodNameCurrent,
      clientCountFamilyMember: this.clientCountFamilyMember,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.clientToken == "" || this.clientToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(`/Clients/AddClientWithImage`, formData);
        } else {
          response = await axios.post(`/Clients/AddClientWithoutImage`, data);
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Clients/UpdateClientWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Clients/UpdateClientWithoutImage`,
            data
          );
        }
      }

      // console.log(response);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveClient(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      clientToken: this.clientToken,
    };

    try {
      let response = await axios.post(`/Clients/ArchiveClient`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
