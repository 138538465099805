<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="treasuriesTransactionsData">
      <TreasuriesTransactionsTable
        :treasuriesTransactionsData="treasuriesTransactionsData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
    </div>

    <TreasuryTransactionFloatBtns
      :textSearch="textSearch"
      :establishmentTreasuryToken="establishmentTreasuryToken"
      :transactionTypeToken="transactionTypeToken"
      v-on:search="search"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import TreasuriesTransactionsTable from "@/components/finance/treasuriesTransactions/TreasuriesTransactionsTable.vue";
import TreasuryTransactionFloatBtns from "@/components/finance/treasuriesTransactions/TreasuryTransactionFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import TreasuryTransaction from "@/models/finance/treasuriesTransactions/TreasuryTransaction";

export default {
  name: "Treasuries",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TreasuriesTransactionsTable,
    TreasuryTransactionFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      establishmentTreasuryToken: "",
      transactionTypeToken: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      treasuriesTransactionsData: null,
      treasuryTransactionData: new TreasuryTransaction(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getTreasuriesTransactions();
    },
    search(text, establishmentTreasuryToken, transactionTypeToken) {
      this.textSearch = text;
      this.establishmentTreasuryToken = establishmentTreasuryToken;
      this.transactionTypeToken = transactionTypeToken;
      this.getTreasuriesTransactions();
    },
    async getTreasuriesTransactions() {
      this.isLoading = true;
      try {
        const response =
          await this.treasuryTransactionData.getTreasuriesTransactions(
            this.language,
            this.userAuthorizeToken,
            this.selfPage,
            this.textSearch,
            this.establishmentTreasuryToken,
            this.transactionTypeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.treasuriesTransactionsData =
            response.data.establishmentTreasuryTransactionsData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.treasuriesTransactionsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.treasuriesTransactionsData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.treasuriesTransactionsData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.treasuriesTransactionsData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getTreasuriesTransactions();
  },
};
</script>
