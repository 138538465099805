export default class UsersPrivilegeEstablishmentExpenses {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentExpensesView = false;
    this.establishmentExpensesAdd = false;
    this.establishmentExpensesRefund = false;
  }
  fillData(data) {
    if (data) {
      this.establishmentExpensesView = data.establishmentExpensesView;
      this.establishmentExpensesAdd = data.establishmentExpensesAdd;
      this.establishmentExpensesRefund = data.establishmentExpensesRefund;
    } else {
      this.setInitialValue();
    }
  }
}
