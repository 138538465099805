var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(_vm.checkPlacesPrivilege())?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.places",modifiers:{"places":true}}],class:_vm.currentPage == 'places' ? 'active' : ''},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("places.places"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"places"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegePlaces.placeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'places' ? 'active' : '',attrs:{"to":{ name: 'Places' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/places.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("places.places")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegePlacesWorkPlans
                .placesWorkPlansView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'places-work-plans' ? 'active' : '',attrs:{"to":{ name: 'PlacesWorkPlans' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/places-work-plans.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("placesWorkPlans.placesWorkPlans")))])])],1):_vm._e()])])],1):_vm._e(),(_vm.checkReservationsPrivilege())?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.reservations",modifiers:{"reservations":true}}],class:_vm.currentPage == 'reservations' ? 'active' : ''},[_vm._m(1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pages.Reservations"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"reservations"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEnquiryReservations.enquiryReservationsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'enquiry-reservations' ? 'active' : '',attrs:{"to":"/reservations/enquiry-reservations"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/enquiry-reservations.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pages.EnquiryReservations")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeReservations
                .reservationsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'reservations' ? 'active' : '',attrs:{"to":"/reservations/reservations"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reservations.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pages.Reservations")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeReservationsSchedulesPayments
                .reservationsSchedulesPaymentsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'payments' ? 'active' : '',attrs:{"to":"/reservations/payments"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/money.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pages.Payments")))])])],1):_vm._e()])])],1):_vm._e(),(_vm.checkClientPrivilege())?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.clients",modifiers:{"clients":true}}],class:_vm.currentPage == 'clients' ? 'active' : ''},[_vm._m(2),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("clients"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"clients"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeClients
                .clientsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'clients' ? 'active' : '',attrs:{"to":"/clients"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/clients.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("clients")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeTransactionPointsHistory
                .transactionPointsHistoryView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'transaction-points' ? 'active' : '',attrs:{"to":"/transaction-points"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/transaction-points.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("transactionPoints.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees.employeesView
      )
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'employees' ? 'active' : '',attrs:{"to":"/employees"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employees")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeOffers.offersView
      )
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'offers' ? 'active' : '',attrs:{"to":"/offers"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/offers.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pages.Offers")))])])],1):_vm._e(),(_vm.checkFinancePrivilege())?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.finance",modifiers:{"finance":true}}],class:_vm.currentPage == 'finance' ? 'active' : ''},[_vm._m(3),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pages.Finance"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"finance"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentTreasuries
                .establishmentTreasuryView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries' ? 'active' : '',attrs:{"to":{ name: 'Treasuries' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("treasuries.treasuries")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentTreasuryTransactions
                .establishmentTreasuryTransactionsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries-transactions' ? 'active' : '',attrs:{"to":{ name: 'TreasuriesTransactions' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries-transactions.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("treasuriesTransactions")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentExpenses.establishmentExpensesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'expenses' ? 'active' : '',attrs:{"to":{ name: 'Expenses' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/expenses.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("expenses")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentRevenues.establishmentRevenuesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'revenues' ? 'active' : '',attrs:{"to":{ name: 'Revenues' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/revenues.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("revenues")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeReports
          .statisticsReportView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeReports
          .treasuriesTransactionsReportView
      )
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'reports' ? 'active' : '',attrs:{"to":"/reports"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reports.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reports")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.privileges",modifiers:{"privileges":true}}],class:_vm.currentPage == 'privileges' ? 'active' : ''},[_vm._m(4),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pages.Privileges"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"privileges"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers
                .usersPrivilegeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'users-employee-privileges' ? 'active' : '',attrs:{"to":{ name: 'UsersEmployeePrivileges' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/privilege.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("pages.UsersEmployeePrivileges")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
          .additionalSettingsView
      ) && !_vm.isClient
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],class:_vm.currentPage == 'settings' ? 'active' : ''},[_vm._m(5),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("settings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"settings"}},[_c('ul',[_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-other' ? 'active' : '',attrs:{"to":{ name: 'SettingsOther' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-other.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-other")))])])],1)])])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/places.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reservations.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/clients.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/finances.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/privileges.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg")}})])}]

export { render, staticRenderFns }