<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addTransactionPoint()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'transactionPointsHistoryCode'"
            :value="transactionPointData.transactionPointsHistoryCode"
            v-on:changeValue="
              transactionPointData.transactionPointsHistoryCode = $event
            "
            :title="$t('transactionPoints.code')"
            :imgName="'code.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'clientToken'"
            :value="transactionPointData.clientToken"
            :options="clientTokenOptions"
            v-on:changeValue="transactionPointData.clientToken = $event"
            :title="$t('selectClient')"
            :imgName="'clients.svg'"
          />

          <CustomSelectBox
            :className="'col-md-6'"
            :id="'transactionPointsTypeToken'"
            :value="transactionPointData.transactionPointsTypeToken"
            :options="transactionPointsTypeTokenOptions"
            v-on:changeValue="
              transactionPointData.transactionPointsTypeToken = $event
            "
            :title="$t('transactionPoints.selectType')"
            :imgName="'clients.svg'"
          />
          <CustomInputInt
            :className="'col-md-6'"
            :id="'transactionPointsValue'"
            :value="transactionPointData.transactionPointsValue"
            v-on:changeValue="
              transactionPointData.transactionPointsValue = $event
            "
            :title="$t('transactionPoints.value')"
            :imgName="'transaction-points.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'transactionPointsReasonAr'"
            :value="transactionPointData.transactionPointsReasonAr"
            v-on:changeValue="
              transactionPointData.transactionPointsReasonAr = $event
            "
            :title="$t('transactionPoints.reasonAr')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'transactionPointsReasonEn'"
            :value="transactionPointData.transactionPointsReasonEn"
            v-on:changeValue="
              transactionPointData.transactionPointsReasonEn = $event
            "
            :title="$t('transactionPoints.reasonEn')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'transactionPointsReasonUnd'"
            :value="transactionPointData.transactionPointsReasonUnd"
            v-on:changeValue="
              transactionPointData.transactionPointsReasonUnd = $event
            "
            :title="$t('transactionPoints.reasonUnd')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'notes'"
            :value="transactionPointData.notes"
            v-on:changeValue="transactionPointData.notes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/transaction-points" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import TextArea from "@/components/general/TextArea.vue";
import Client from "@/models/clients/Client";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "TransactionPointForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    CustomInputInt,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      clientData: new Client(),
      clientTokenOptions: [],
      transactionPointsTypeTokenOptions: [],
    };
  },
  props: ["transactionPointData", "submitName"],
  methods: {
    async addTransactionPoint() {
      this.$emit("addTransactionPoint");
    },
    async getDialogOfClients() {
      this.isLoading = true;
      this.clientTokenOptions = [];
      this.clientTokenOptions.push({
        value: "",
        text: this.$t("selectClient"),
      });
      try {
        let response = await this.clientData.getDialogOfClients(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;

        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.clientTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfTransactionPointsTypes() {
      this.isLoading = true;
      this.transactionPointsTypeTokenOptions = [];
      this.transactionPointsTypeTokenOptions.push({
        value: "",
        text: this.$t("transactionPoints.selectType"),
      });
      let itemsData =
        this.$store.getters.userData.constantsLists.listTransactionPointsType;
      for (let item in itemsData) {
        this.transactionPointsTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  async created() {
    this.getDialogOfClients();
    this.getDialogOfTransactionPointsTypes();
  },
};
</script>

<style lang="scss"></style>
