import { PAGE_SIZE } from "@/utils/constants";
import { USER_POINTS_TYPE } from "@/utils/constantLists";
import axios from "axios";

export default class TransactionPoint {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.transactionPointsHistoryToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.transactionPointsHistoryCode = "";
    this.userPointsTypeToken = USER_POINTS_TYPE.Client;
    this.userPointsTypeNameEn = "";
    this.userPointsTypeNameAr = "";
    this.userPointsTypeNameUnd = "";
    this.clientToken = "";
    this.clientNameCurrent = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.contractorToken = "";
    this.contractorNameCurrent = "";
    this.transactionPointsReasonCurrent = "";
    this.transactionPointsReasonEn = "";
    this.transactionPointsReasonAr = "";
    this.transactionPointsReasonUnd = "";
    this.transactionPointsTypeToken = "";
    this.transactionPointsTypeNameCurrent = "";
    this.transactionPointsTypeNameEn = "";
    this.transactionPointsTypeNameAr = "";
    this.transactionPointsTypeNameUnd = "";
    this.transactionPointsValue = "";
    this.transactionPointsOldValue = "";
    this.transactionPointsNewValue = "";
    this.userMakeActionToken = "";
    this.userMakeActionNameCurrent = "";
    this.notes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.transactionPointsHistoryArchiveStatus = "";
    // search
    this.textSearch = "";
  }
  fillData(data) {
    this.transactionPointsHistoryToken = data.transactionPointsHistoryToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.transactionPointsHistoryCode = data.transactionPointsHistoryCode;
    this.userPointsTypeToken = data.userPointsTypeToken;
    this.userPointsTypeNameEn = data.userPointsTypeNameEn;
    this.userPointsTypeNameAr = data.userPointsTypeNameAr;
    this.userPointsTypeNameUnd = data.userPointsTypeNameUnd;
    this.clientToken = data.clientToken;
    this.clientNameCurrent = data.clientNameCurrent;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.contractorToken = data.contractorToken;
    this.contractorNameCurrent = data.contractorNameCurrent;
    this.transactionPointsReasonCurrent = data.transactionPointsReasonCurrent;
    this.transactionPointsReasonEn = data.transactionPointsReasonEn;
    this.transactionPointsReasonAr = data.transactionPointsReasonAr;
    this.transactionPointsReasonUnd = data.transactionPointsReasonUnd;
    this.transactionPointsTypeToken = data.transactionPointsTypeToken;
    this.transactionPointsTypeNameCurrent =
      data.transactionPointsTypeNameCurrent;
    this.transactionPointsTypeNameEn = data.transactionPointsTypeNameEn;
    this.transactionPointsTypeNameAr = data.transactionPointsTypeNameAr;
    this.transactionPointsTypeNameUnd = data.transactionPointsTypeNameUnd;
    this.transactionPointsValue = data.transactionPointsValue;
    this.transactionPointsOldValue = data.transactionPointsOldValue;
    this.transactionPointsNewValue = data.transactionPointsNewValue;
    this.userMakeActionToken = data.userMakeActionToken;
    this.userMakeActionNameCurrent = data.userMakeActionNameCurrent;
    this.notes = data.notes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.transactionPointsHistoryArchiveStatus =
      data.transactionPointsHistoryArchiveStatus;
  }

  async getAllTransactionPoints(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    try {
      let response = await axios.get(
        `/TransactionPointsHistory/GetAllTransactionPointsHistory?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&userPointsTypeToken=${USER_POINTS_TYPE.Client}&clientToken=${filterData.clientToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getTransactionPointDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/TransactionPointsHistory/GetTransactionPointsHistoryDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&transactionPointsHistoryToken=${this.transactionPointsHistoryToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async addTransactionPoint(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      transactionPointsHistoryCode: this.transactionPointsHistoryCode,
      userPointsTypeToken: this.userPointsTypeToken,
      clientToken: this.clientToken,
      // employeeToken: this.employeeToken,
      // contractorToken: this.contractorToken,
      transactionPointsReasonAr: this.transactionPointsReasonAr,
      transactionPointsReasonEn: this.transactionPointsReasonEn,
      transactionPointsReasonUnd: this.transactionPointsReasonUnd,
      transactionPointsTypeToken: this.transactionPointsTypeToken,
      transactionPointsValue: this.transactionPointsValue,
      notes: this.notes,
    };

    try {
      let response = await axios.post(
        `/TransactionPointsHistory/AddTransactionPointsHistory`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
