<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="reservationData">
      <ReservationDetailsTable
        v-if="!isLoading"
        :reservationData="reservationData"
        v-on:setReservationScheduleData="setReservationData($event)"
        :reservationScheduleData="reservationScheduleData"
      />

      <ReservationScheduleApprove
        :reservationScheduleData="reservationScheduleData"
        v-on:refresh="getReservationsDetails()"
      />

      <ReservationSchedulePay
        :reservationSchedulesPaymentData="reservationSchedulesPaymentData"
        v-on:refresh="getReservationsDetails()"
      />

      <ReservationScheduleCancel
        :reservationSchedulesCancelData="reservationSchedulesCancelData"
        v-on:refresh="getReservationsDetails()"
      />
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ReservationDetailsTable from "@/components/reservations/reservations/ReservationDetailsTable.vue";
import ReservationScheduleApprove from "@/components/reservations/reservations/ReservationScheduleApprove.vue";
import ReservationSchedulePay from "@/components/reservations/reservations/ReservationSchedulePay.vue";
import ReservationScheduleCancel from "@/components/reservations/reservations/ReservationScheduleCancel.vue";
import Reservation from "@/models/reservations/reservations/Reservation";
import ReservationSchedule from "@/models/reservations/reservations/ReservationSchedule";
import ReservationsSchedulesPayment from "@/models/reservations/reservations/ReservationsSchedulesPayment";
import ReservationsSchedulesCancel from "@/models/reservations/reservations/ReservationsSchedulesCancel";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ReservationDetails",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ReservationDetailsTable,
    ReservationScheduleApprove,
    ReservationSchedulePay,
    ReservationScheduleCancel,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      reservationData: new Reservation(),
      reservationScheduleData: new ReservationSchedule(),
      reservationSchedulesPaymentData: new ReservationsSchedulesPayment(),
      reservationSchedulesCancelData: new ReservationsSchedulesCancel(),
    };
  },
  methods: {
    setReservationData(data) {
      this.reservationScheduleData.fillData(data);
      this.reservationSchedulesPaymentData.fillData(data);
      this.reservationSchedulesCancelData.fillData(data);
    },
    async getReservationsDetails() {
      this.isLoading = true;
      try {
        let response = await this.reservationData.getReservationsDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.reservationData.fillData(response.data.reservationsData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.reservationData.fillData(null);
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.reservationData.fillData(null);
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.reservationData.reservationToken = this.$route.params.reservationToken;
    this.getReservationsDetails();
  },
};
</script>

<style scoped lang="scss"></style>
