import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
export default class PlacesWorkPlanDay {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeWorkPlanDayToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.placeWorkPlanToken = "";
    this.placeWorkPlanNameCurrent = "";
    this.dayToken = "";
    this.dayNameCurrent = "";
    this.dayNameAr = "";
    this.dayNameEn = "";
    this.dayNameUnd = "";
    this.dayStatusToken = "";
    this.dayStatusNameCurrent = "";
    this.dayStatusNameAr = "";
    this.dayStatusNameEn = "";
    this.dayStatusNameUnd = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.placeWorkPlanDayArchiveStatus = "";
    this.placesSchedulesData = "";
  }
  fillData(data) {
    this.placeWorkPlanDayToken = data.placeWorkPlanDayToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.placeWorkPlanToken = data.placeWorkPlanToken;
    this.placeWorkPlanNameCurrent = data.placeWorkPlanNameCurrent;
    this.dayToken = data.dayToken;
    this.dayNameCurrent = data.dayNameCurrent;
    this.dayNameAr = data.dayNameAr;
    this.dayNameEn = data.dayNameEn;
    this.dayNameUnd = data.dayNameUnd;
    this.dayStatusToken = data.dayStatusToken;
    this.dayStatusNameCurrent = data.dayStatusNameCurrent;
    this.dayStatusNameAr = data.dayStatusNameAr;
    this.dayStatusNameEn = data.dayStatusNameEn;
    this.dayStatusNameUnd = data.dayStatusNameUnd;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.placeWorkPlanDayArchiveStatus = data.placeWorkPlanDayArchiveStatus;
    this.placesSchedulesData = data.placesSchedulesData;
  }

  async getAllPlacesWorkPlansDays(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    placeWorkPlanToken = ""
  ) {
    try {
      let response = await axios.get(
        `/PlacesWorkPlansDays/GetAllPlacesWorkPlansDays/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&textSearch=${textSearch}&placeWorkPlanToken=${placeWorkPlanToken}&includeStatus=true`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getPlaceWorkPlanDayDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesWorkPlansDays/GetPlaceWorkPlanDayDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placeWorkPlanToken=${this.placeWorkPlanToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updatePlacesWorkPlanDay(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeWorkPlanDayToken: this.placeWorkPlanDayToken,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      placeWorkPlanToken: this.placeWorkPlanToken,
      placeWorkPlanNameCurrent: this.placeWorkPlanNameCurrent,
      dayToken: this.dayToken,
      dayNameCurrent: this.dayNameCurrent,
      dayNameAr: this.dayNameAr,
      dayNameEn: this.dayNameEn,
      dayNameUnd: this.dayNameUnd,
      dayStatusToken: this.dayStatusToken,
      dayStatusNameCurrent: this.dayStatusNameCurrent,
      dayStatusNameAr: this.dayStatusNameAr,
      dayStatusNameEn: this.dayStatusNameEn,
      dayStatusNameUnd: this.dayStatusNameUnd,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      placeWorkPlanDayArchiveStatus: this.placeWorkPlanDayArchiveStatus,
      placesSchedulesData: this.placesSchedulesData,
    };

    try {
      let response = await axios.post(
        `/PlacesWorkPlansDays/UpdatePlaceWorkPlanDay`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
