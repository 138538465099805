<template>
  <ul>
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeNews.newsView
        )
      "
    >
      <router-link to="/news" :class="currentPage == 'news' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/news.svg" /></span>
        <span class="title">{{ $t("news.news") }}</span>
      </router-link>
    </li> -->

    <li class="sub-menu" v-if="checkPlacesPrivilege()">
      <a v-b-toggle.places :class="currentPage == 'places' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/places.svg" /></span>

        <span class="title">
          {{ $t("places.places") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="places">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegePlaces.placeView
              )
            "
          >
            <router-link
              :to="{ name: 'Places' }"
              :class="currentSubPage == 'places' ? 'active' : ''"
            >
              <span class="icon"><img src="@/assets/images/places.svg" /></span>
              <span class="title">{{ $t("places.places") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegePlacesWorkPlans
                  .placesWorkPlansView
              )
            "
          >
            <router-link
              :to="{ name: 'PlacesWorkPlans' }"
              :class="currentSubPage == 'places-work-plans' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/places-work-plans.svg"
              /></span>
              <span class="title">{{
                $t("placesWorkPlans.placesWorkPlans")
              }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <li class="sub-menu" v-if="checkReservationsPrivilege()">
      <a
        v-b-toggle.reservations
        :class="currentPage == 'reservations' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reservations.svg" /></span>
        <span class="title">
          {{ $t("pages.Reservations") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="reservations">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEnquiryReservations.enquiryReservationsView
              )
            "
          >
            <router-link
              to="/reservations/enquiry-reservations"
              :class="currentSubPage == 'enquiry-reservations' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/enquiry-reservations.svg"
              /></span>
              <span class="title">{{ $t("pages.EnquiryReservations") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeReservations
                  .reservationsView
              )
            "
          >
            <router-link
              to="/reservations/reservations"
              :class="currentSubPage == 'reservations' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/reservations.svg"
              /></span>
              <span class="title">{{ $t("pages.Reservations") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeReservationsSchedulesPayments
                  .reservationsSchedulesPaymentsView
              )
            "
          >
            <router-link
              to="/reservations/payments"
              :class="currentSubPage == 'payments' ? 'active' : ''"
            >
              <span class="icon"><img src="@/assets/images/money.svg" /></span>
              <span class="title">{{ $t("pages.Payments") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <li class="sub-menu" v-if="checkClientPrivilege()">
      <a v-b-toggle.clients :class="currentPage == 'clients' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/clients.svg" /></span>
        <span class="title">
          {{ $t("clients") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="clients">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeClients
                  .clientsView
              )
            "
          >
            <router-link
              to="/clients"
              :class="currentSubPage == 'clients' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/clients.svg"
              /></span>
              <span class="title">{{ $t("clients") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeTransactionPointsHistory
                  .transactionPointsHistoryView
              )
            "
          >
            <router-link
              to="/transaction-points"
              :class="currentSubPage == 'transaction-points' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/transaction-points.svg"
              /></span>
              <span class="title">{{ $t("transactionPoints.modelName") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployees.employeesView
        )
      "
    >
      <router-link
        to="/employees"
        :class="currentPage == 'employees' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/employees.svg" /></span>
        <span class="title">{{ $t("employees") }}</span>
      </router-link>
    </li>

    <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeOffers.offersView
        )
      "
    >
      <router-link
        to="/offers"
        :class="currentPage == 'offers' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/offers.svg" /></span>
        <span class="title">{{ $t("pages.Offers") }}</span>
      </router-link>
    </li>

    <li class="sub-menu" v-if="checkFinancePrivilege()">
      <a v-b-toggle.finance :class="currentPage == 'finance' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/finances.svg" /></span>

        <span class="title">
          {{ $t("pages.Finance") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="finance">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentTreasuries
                  .establishmentTreasuryView
              )
            "
          >
            <router-link
              :to="{ name: 'Treasuries' }"
              :class="currentSubPage == 'treasuries' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries.svg"
              /></span>
              <span class="title">{{ $t("treasuries.treasuries") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentTreasuryTransactions
                  .establishmentTreasuryTransactionsView
              )
            "
          >
            <router-link
              :to="{ name: 'TreasuriesTransactions' }"
              :class="
                currentSubPage == 'treasuries-transactions' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries-transactions.svg"
              /></span>
              <span class="title">{{ $t("treasuriesTransactions") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentExpenses.establishmentExpensesView
              )
            "
          >
            <router-link
              :to="{ name: 'Expenses' }"
              :class="currentSubPage == 'expenses' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/expenses.svg"
              /></span>
              <span class="title">{{ $t("expenses") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentRevenues.establishmentRevenuesView
              )
            "
          >
            <router-link
              :to="{ name: 'Revenues' }"
              :class="currentSubPage == 'revenues' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/revenues.svg"
              /></span>
              <span class="title">{{ $t("revenues") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeReports
            .statisticsReportView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeReports
            .treasuriesTransactionsReportView
        )
      "
    >
      <router-link
        to="/reports"
        :class="currentPage == 'reports' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reports.svg" /></span>
        <span class="title">{{ $t("reports") }}</span>
      </router-link>
    </li>

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
        )
      "
    >
      <a
        v-b-toggle.privileges
        :class="currentPage == 'privileges' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/privileges.svg" /></span>

        <span class="title">
          {{ $t("pages.Privileges") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="privileges">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeUsers
                  .usersPrivilegeView
              )
            "
          >
            <router-link
              :to="{ name: 'UsersEmployeePrivileges' }"
              :class="
                currentSubPage == 'users-employee-privileges' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/privilege.svg"
              /></span>
              <span class="title">{{
                $t("pages.UsersEmployeePrivileges")
              }}</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              :to="{ name: 'UsersClientPrivileges' }"
              :class="
                currentSubPage == 'users-client-privileges' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/privilege.svg"
              /></span>
              <span class="title">{{ $t("pages.UsersClientPrivileges") }}</span>
            </router-link>
          </li> -->
        </ul>
      </b-collapse>
    </li>
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
            .additionalSettingsView
        ) && !isClient
      "
    >
      <a v-b-toggle.settings :class="currentPage == 'settings' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>

        <span class="title">
          {{ $t("settings") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="settings">
        <ul>
          <li>
            <router-link
              :to="{ name: 'SettingsOther' }"
              :class="currentSubPage == 'settings-other' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-other.svg"
              /></span>
              <span class="title">{{ $t("settings-other") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
// import UsersEstablishmentPrivilege from "@/models/privileges/usersPrivileges/UsersEstablishmentPrivilege";

export default {
  name: "Sidebar",
  data() {
    return {
      isClient: false,
      language: localStorage.getItem("userLanguage") || "ar",
      // usersEstablishmentPrivilegeData:
      //   this.$store.getters.userData.usersEstablishmentPrivilegeData ||
      //   new UsersEstablishmentPrivilege(),
      usersEstablishmentPrivilegeData:
        this.$store.getters.usersEstablishmentPrivilegeData,
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    getCurrentPage(path) {
      let currentAndSubPages = path.split("/");
      this.currentPage = currentAndSubPages[1];
      this.currentSubPage = currentAndSubPages[2];
    },
    checkPrivilege(privilegeStatus) {
      return checkPrivilege(privilegeStatus);
    },
    checkReservationsPrivilege() {
      return (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeEnquiryReservations
            .enquiryReservationsView
        ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeReservations
            .reservationsView
        ) ||
        // this.checkPrivilege(
        //   this.usersEstablishmentPrivilegeData
        //     .usersPrivilegeReservationsSchedules.reservationsSchedulesView
        // ) ||
        // this.checkPrivilege(
        //   this.usersEstablishmentPrivilegeData
        //     .usersPrivilegeReservationsSchedulesCancle
        //     .reservationsSchedulesCancelView
        // ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeReservationsSchedulesPayments
            .reservationsSchedulesPaymentsView
        )
      );
    },
    checkClientPrivilege() {
      return (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegeClients.clientsView
        ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
        )
      );
    },
    checkPlacesPrivilege() {
      return (
        // this.checkPrivilege(
        //   this.usersEstablishmentPrivilegeData.usersPrivilegePlaceSchedule
        //     .placeScheduleView
        // ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegePlaces.placeView
        ) ||
        // this.checkPrivilege(
        //   this.usersEstablishmentPrivilegeData.usersPrivilegePlacesMedia
        //     .placeMediaView
        // ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData.usersPrivilegePlacesWorkPlans
            .placesWorkPlansView
        )
        // ||
        // this.checkPrivilege(
        //   this.usersEstablishmentPrivilegeData.usersPrivilegePlacesWorkPlansDays
        //     .placesWorkPlansDaysView
        // )
      );
    },
    checkFinancePrivilege() {
      return (
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeEstablishmentRevenues.establishmentRevenuesView
        ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeEstablishmentExpenses.establishmentExpensesView
        ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeEstablishmentTreasuryTransactions
            .establishmentTreasuryTransactionsView
        ) ||
        this.checkPrivilege(
          this.usersEstablishmentPrivilegeData
            .usersPrivilegeEstablishmentTreasuries.establishmentTreasuryView
        )
      );
    },
  },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
    },
  },
  async created() {
    await this.setUserType();
    this.getCurrentPage(this.$route.path);
  },
};
</script>

<style lang="scss"></style>
