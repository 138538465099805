<template>
  <div>
    <h1>Test General</h1>
  </div>
</template>

<script>
export default {
  name: "TestGenerals",
  data() {
    return {};
  },
  components: {},
  methods: {
    getPageName(name = this.$route.name) {
      let arr = name.split(/(?=[A-Z])/);
      return arr.join(" ");
    },
  },
  //   computed: {
  //     get() {
  //       return this.$route.name;
  //     },
  //   },
  created() {
    console.log(this.$route.name);
    console.log(this.getPageName(this.$route.name));
  },
};
</script>
