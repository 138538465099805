<template>
  <div v-if="treasuriesData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("treasuryCode") }}</th>
            <th>{{ $t("treasuryMoney") }}</th>
            <th>{{ $t("general.role.actionDateTime") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(treasury, index) in treasuriesData"
            :key="treasury.establishmentTreasuryToken"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(treasury.establishmentTreasuryNameCurrent) }}
            </td>
            <td>{{ timeToLang(treasury.establishmentTreasuryCode) }}</td>
            <td>{{ isDataExist(treasury.establishmentTreasuryMoney) }}</td>
            <td>
              {{
                formateDateDMY(treasury.actionDate) +
                " - " +
                timeToLang(treasury.actionTime)
              }}
            </td>
            <td>{{ isDataExist(treasury.treasuryNote) }}</td>
            <!-- <td>
              <button
                v-b-modal.TreasuryInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setTreasuryData(treasury)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td> -->
            <td>
              <router-link
                :to="{
                  name: 'TreasuryEdit',
                  params: {
                    establishmentTreasuryToken: `${treasury.establishmentTreasuryToken}`,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.TreasuryDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setTreasuryData(treasury)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateDMY,
} from "@/utils/functions";

export default {
  name: "TreasuriesTable",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["treasuriesData", "treasuryData"],
  methods: {
    setTreasuryData(treasuryData) {
      this.$emit("setTreasuryData", treasuryData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateDMY(date, lang = this.language) {
      return formateDateDMY(date, lang);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
