<template>
  <div v-if="reservationData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>{{ $t("reservations.code") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("activities.name") }}</th>
            <th>{{ $t("places.code") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th class="cell-lg">{{ $t("reservations.name") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ isDataExist(reservationData.reservationCode) }}
            </td>
            <td>
              {{ isDataExist(reservationData.clientNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(reservationData.activityNameCurrent) }}
            </td>
            <td>{{ isDataExist(reservationData.placeCode) }}</td>
            <td>{{ isDataExist(reservationData.placeNameCurrent) }}</td>
            <td>{{ isDataExist(reservationData.reservationNameCurrent) }}</td>
            <td>
              {{
                formateDateTimeLang(
                  reservationData.actionDate,
                  reservationData.actionTime
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="reservationData.reservationsSchedulesData"
      class="table-container"
    >
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("enquiryReservations.scheduleCode") }}</th>
            <th>{{ $t("day") }}</th>
            <th>{{ $t("enquiryReservations.fromDateTime") }}</th>
            <th>{{ $t("enquiryReservations.toDateTime") }}</th>
            <th>{{ $t("enquiryReservations.duration") }}</th>
            <th>{{ $t("enquiryReservations.totalPrice") }}</th>
            <th>{{ $t("enquiryReservations.remainingAmount") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("status") }}</th>
            <th v-if="!isClient">{{ $t("reservations.approveSchedule") }}</th>
            <th v-if="!isClient">{{ $t("reservations.pay") }}</th>
            <th>{{ $t("reservations.cancel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in reservationData.reservationsSchedulesData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.enquiryReservationScheduleCode) }}</td>
            <td>{{ isDataExist(item.dayNameCurrent) }}</td>
            <td>
              {{ formateDateTimeLang(item.dateFrom, item.timeFrom) }}
            </td>
            <td>
              {{ formateDateTimeLang(item.dateTo, item.timeTo) }}
            </td>
            <td>{{ isDataExist(item.actualDurationTime) }}</td>
            <td>{{ moneyFormat(item.actualPrice) }}</td>
            <td>{{ moneyFormat(item.remainingAmount) }}</td>
            <td>{{ isDataExist(item.enquiryReservationScheduleNotes) }}</td>
            <td>
              {{ isDataExist(item.reservationScheduleStatusNameCurrent) }}
            </td>
            <td v-if="!isClient">
              <button
                v-if="
                  item.reservationScheduleStatusToken ==
                  reservationScheduleStatus.waitingApproval
                "
                v-b-modal.ReservationScheduleApprove
                class="btn p-0"
                :title="$t('reservations.approveSchedule')"
                @click="setReservationScheduleData(item)"
              >
                <img
                  src="@/assets/images/approve-schedule.svg"
                  class="icon-lg"
                />
              </button>
              <span v-else>-</span>
            </td>
            <td v-if="!isClient">
              <button
                v-if="item.remainingAmount > 0"
                v-b-modal.ReservationSchedulePay
                class="btn p-0"
                :title="$t('reservations.pay')"
                @click="setReservationScheduleData(item)"
              >
                <img src="@/assets/images/money.svg" class="icon-lg" />
              </button>
              <span v-else>-</span>
            </td>
            <td>
              <button
                v-if="item.finishStatus == false && item.cancelStatus == false"
                v-b-modal.ReservationScheduleCancel
                class="btn p-0"
                :title="$t('reservations.cancel')"
                @click="setReservationScheduleData(item)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
              <span v-if="item.cancelStatus == true">
                {{ $t("reservations.canceled") }}
              </span>
              <span v-else-if="item.finishStatus == true">
                {{ $t("reservations.finished") }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";
import { RESERVATION_SCHEDULE_STATUS } from "@/utils/constantLists";

export default {
  name: "EnquiryReservationDetailsTable",
  data() {
    return {
      isClient: false,
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      reservationScheduleStatus: RESERVATION_SCHEDULE_STATUS,
    };
  },
  props: ["reservationData", "reservationScheduleData"],
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    setReservationScheduleData(reservationScheduleData) {
      this.$emit("setReservationScheduleData", reservationScheduleData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {
    await this.setUserType();
  },
};
</script>

<style lang="scss"></style>
