import { render, staticRenderFns } from "./ReservationAdd.vue?vue&type=template&id=b24bb7dc&scoped=true&"
import script from "./ReservationAdd.vue?vue&type=script&lang=js&"
export * from "./ReservationAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b24bb7dc",
  null
  
)

export default component.exports