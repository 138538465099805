<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="transactionPoints.transactionPointsHistoryData">
      <TransactionPointsTable
        :transactionPointsHistoryData="
          transactionPoints.transactionPointsHistoryData
        "
        v-on:setTransactionPointData="transactionPointData.fillData($event)"
        :transactionPointData="transactionPointData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="transactionPoints.pagination"
        v-on:changePage="changePage"
      />
      <TransactionPointInfo :transactionPointData="transactionPointData" />
    </div>

    <TransactionPointFloatBtns
      :theFilterData="transactionPoints.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import TransactionPointsTable from "@/components/transactionPoints/TransactionPointsTable.vue";
import TransactionPointInfo from "@/components/transactionPoints/TransactionPointInfo.vue";
import TransactionPointFloatBtns from "@/components/transactionPoints/TransactionPointFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import TransactionPoints from "@/models/transactionPoints/TransactionPoints";
import TransactionPoint from "@/models/transactionPoints/TransactionPoint";
// import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "TransactionPoints",
  // mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TransactionPointsTable,
    TransactionPointInfo,
    TransactionPointFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      transactionPoints: new TransactionPoints(),
      transactionPointData: new TransactionPoint(),
    };
  },
  methods: {
    changePage(page) {
      this.transactionPoints.pagination.selfPage = page;
      this.getAllTransactionPoints();
    },
    search(data) {
      this.transactionPoints.filterData.fillData(data);
      this.transactionPoints.transactionPointsHistoryData = null;
      this.getAllTransactionPoints();
    },
    async getAllTransactionPoints() {
      this.isLoading = true;
      try {
        const response =
          await this.transactionPoints.transactionPoint.getAllTransactionPoints(
            this.language,
            this.userAuthorizeToken,
            this.transactionPoints.pagination,
            this.transactionPoints.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.transactionPoints.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.transactionPoints.transactionPointsHistoryData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.transactionPoints.transactionPointsHistoryData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.transactionPoints.transactionPointsHistoryData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.transactionPoints.transactionPointsHistoryData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllTransactionPoints();
  },
};
</script>
