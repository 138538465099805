import { BASE_URL, PAGE_SIZE } from "@/utils/constants";
import { date2slash } from "@/utils/functions";
import axios from "axios";

export default class Expense {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.imageSrc = BASE_URL + this.establishmentExpensesImagePath;
    this.establishmentExpensesToken = "";
    this.userToken = "";
    this.userNameCurrent = "";
    this.establishmentExpensesCode = "";
    this.establishmentTreasuryToken = "";
    this.establishmentTreasuryNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.establishmentExpensesImagePath = "";
    this.establishmentExpensesTypeToken = "";
    this.establishmentExpensesTypeNameCurrent = "";
    this.establishmentExpensesDatePayed = "";
    this.establishmentExpensesDateTimeAction = "";
    this.establishmentExpensesDateAction = "";
    this.establishmentExpensesTimeAction = "";
    this.establishmentExpensesMoneyValue = "";
    this.establishmentExpensesNotes = "";
    this.userTokenReturned = "";
    this.userNameCurrentReturned = "";
    this.establishmentExpensesReturnedNote = "";
    this.establishmentExpensesReturnedDateTime = "";
    this.establishmentExpensesReturnedDate = "";
    this.establishmentExpensesReturnedTime = "";
    this.establishmentExpensesReturnedStatus = "";
    this.establishmentExpensesArchiveStatus = "";
  }
  fillData(expenseData) {
    this.image = expenseData.image;
    this.imageSrc = BASE_URL + expenseData.establishmentExpensesImagePath;
    this.establishmentExpensesToken = expenseData.establishmentExpensesToken;
    this.userToken = expenseData.userToken;
    this.userNameCurrent = expenseData.userNameCurrent;
    this.establishmentExpensesCode = expenseData.establishmentExpensesCode;
    this.establishmentTreasuryToken = expenseData.establishmentTreasuryToken;
    this.establishmentTreasuryNameCurrent =
      expenseData.establishmentTreasuryNameCurrent;
    this.establishmentToken = expenseData.establishmentToken;
    this.establishmentNameCurrent = expenseData.establishmentNameCurrent;
    this.establishmentExpensesImagePath =
      expenseData.establishmentExpensesImagePath;
    this.establishmentExpensesTypeToken =
      expenseData.establishmentExpensesTypeToken;
    this.establishmentExpensesTypeNameCurrent =
      expenseData.establishmentExpensesTypeNameCurrent;
    this.establishmentExpensesDatePayed =
      expenseData.establishmentExpensesDatePayed;
    this.establishmentExpensesDateTimeAction =
      expenseData.establishmentExpensesDateTimeAction;
    this.establishmentExpensesDateAction =
      expenseData.establishmentExpensesDateAction;
    this.establishmentExpensesTimeAction =
      expenseData.establishmentExpensesTimeAction;
    this.establishmentExpensesMoneyValue =
      expenseData.establishmentExpensesMoneyValue;
    this.establishmentExpensesNotes = expenseData.establishmentExpensesNotes;
    this.userTokenReturned = expenseData.userTokenReturned;
    this.userNameCurrentReturned = expenseData.userNameCurrentReturned;
    this.establishmentExpensesReturnedNote =
      expenseData.establishmentExpensesReturnedNote;
    this.establishmentExpensesReturnedDateTime =
      expenseData.establishmentExpensesReturnedDateTime;
    this.establishmentExpensesReturnedDate =
      expenseData.establishmentExpensesReturnedDate;
    this.establishmentExpensesReturnedTime =
      expenseData.establishmentExpensesReturnedTime;
    this.establishmentExpensesReturnedStatus =
      expenseData.establishmentExpensesReturnedStatus;
    this.establishmentExpensesArchiveStatus =
      expenseData.establishmentExpensesArchiveStatus;
  }

  async getAllExpenses(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    treasury = "",
    expensesType = ""
  ) {
    try {
      let response = await axios.get(
        `/EstablishmentExpenses/GetAllEstablishmentExpenses/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&establishmentTreasuryToken=${treasury}&establishmentExpensesTypeToken=${expensesType}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getExpenseDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentExpenses/GetEstablishmentExpensesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentExpensesToken=${this.establishmentExpensesToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addExpense(language, userAuthorizeToken) {
    var data = {
      image: this.image,
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentExpensesTypeToken: this.establishmentExpensesTypeToken,
      establishmentTreasuryToken: this.establishmentTreasuryToken,
      establishmentExpensesDatePayed: date2slash(
        this.establishmentExpensesDatePayed
      ),
      establishmentExpensesMoneyValue: this.establishmentExpensesMoneyValue,
      establishmentExpensesNotes: this.establishmentExpensesNotes,
      establishmentExpensesCode: this.establishmentExpensesCode,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.image != "" && this.image != undefined) {
        response = await axios.post(
          `/EstablishmentExpenses/AddEstablishmentExpensesWithImage`,
          formData
        );
      } else {
        response = await axios.post(
          `/EstablishmentExpenses/AddEstablishmentExpenses`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async refundExpense(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentExpensesToken: this.establishmentExpensesToken,
    };

    try {
      let response = await axios.post(
        `/EstablishmentExpenses/RefundExpenses`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
