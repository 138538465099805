export default class UsersPrivilegePlacesWorkPlansDays {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placesWorkPlansDaysView = false;
    this.placesWorkPlansDaysUpdate = false;
  }
  fillData(data) {
    if (data) {
      this.placesWorkPlansDaysView = data.placesWorkPlansDaysView;
      this.placesWorkPlansDaysUpdate = data.placesWorkPlansDaysUpdate;
    } else {
      this.setInitialValue();
    }
  }
}
