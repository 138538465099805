<template>
  <figure
    class="media-card"
    @mouseover="showAllActions"
    @mouseleave="hideAllActions"
  >
    <router-link
      :to="`/offer-item/${offerData.offerToken}`"
      :title="$t('info')"
      class="mainLink"
    >
      <img
        v-if="offerData.offerMediaTypeToken == imageToken"
        class="mainImg"
        :src="mediaSrc"
        :onerror="`this.src='${defaultImg}'`"
        alt="Image"
      />
      <b-icon
        v-if="offerData.offerMediaTypeToken == videoToken"
        icon="play-circle"
        animation="throb"
        variant="danger"
        scale="0.9"
        font-scale="5"
        id="play-icon"
      ></b-icon>

      <video
        v-if="offerData.offerMediaTypeToken == videoToken"
        class="video-container"
      >
        <source
          :src="mediaSrc"
          type="video/mp4"
          :onerror="`this.src='${defaultImg}'`"
        />
        Your browser does not support the video tag.
      </video>
    </router-link>
    <footer v-show="showActions">
      <div class="actions">
        <router-link
          :to="`/edit-offer/${offerData.offerToken}`"
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" class="icon-lg" />
        </router-link>
        <button
          v-b-modal.OfferDelete
          class="btn p-0"
          :title="$t('delete')"
          @click="setOfferData(offerData)"
        >
          <img src="@/assets/images/trash.svg" class="icon-lg" />
        </button>
      </div>
    </footer>
    <router-link
      :to="`/offer-item/${offerData.offerToken}`"
      :title="offerData.offerTitleCurrent"
    >
      <figcaption>
        <h3 class="cut-2line">{{ offerData.offerTitleCurrent }}</h3>
      </figcaption>
    </router-link>
  </figure>
</template>

<script>
import {
  BASE_URL,
  NEWS_MEDIA_TYPE_TOKEN_IMAGE,
  NEWS_MEDIA_TYPE_TOKEN_VIDEO,
} from "@/utils/constants";
import DEFAULT_OFFERS_IMG from "@/assets/images/offers.svg";

export default {
  name: "OfferCard",
  data() {
    return {
      imageToken: NEWS_MEDIA_TYPE_TOKEN_IMAGE,
      videoToken: NEWS_MEDIA_TYPE_TOKEN_VIDEO,
      language: localStorage.getItem("userLanguage") || "ar",
      listMediaTypes: this.$store.getters.userData.constantsLists.listMediaType,
      defaultImg: DEFAULT_OFFERS_IMG,
      showActions: false,
    };
  },
  props: ["offerData"],
  methods: {
    setOfferData() {
      this.$emit("setOfferData", this.offerData);
    },
    showAllActions() {
      this.showActions = true;
    },
    hideAllActions() {
      this.showActions = false;
    },
  },
  computed: {
    mediaSrc: function () {
      if (this.offerData.offerMediaPath) {
        return BASE_URL + this.offerData.offerMediaPath;
      }
      return this.defaultImg;
    },
  },
};
</script>
