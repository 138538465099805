<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <PlaceForm
      v-if="!isLoading"
      :placeData="placeData"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdatePlace="updatePlace"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/places.svg";
// import { date2slash, date2dash, timeTo12, timeTo24 } from "@/utils/functions";
import PlaceForm from "@/components/places/places/PlaceForm.vue";
import Place from "@/models/places/places/Place";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "PlaceEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    PlaceForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      imageSrc: DEFAULT_IMG,
      placeData: new Place(),
      placeToken: this.$route.params.placeToken,
    };
  },
  methods: {
    async getPlaceDetails() {
      this.isLoading = true;
      this.placeData.placeToken = this.placeToken;
      try {
        let response = await this.placeData.getPlaceDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.placeData.fillData(response.data.placeData);

          let ccName1 = response.data.placeData.placePhone1CountryCodeName;
          let cc1 = response.data.placeData.placePhone1CountryCode;
          this.placeData.placePhone1WithCC =
            ccName1 === "" || cc1 === "" ? "" : ccName1 + " " + cc1;

          let ccName2 = response.data.placeData.placePhone2CountryCodeName;
          let cc2 = response.data.placeData.placePhone2CountryCode;
          this.placeData.placePhone2WithCC =
            ccName2 === "" || cc2 === "" ? "" : ccName2 + " " + cc2;

          let ccName3 = response.data.placeData.placePhone3CountryCodeName;
          let cc3 = response.data.placeData.placePhone3CountryCode;
          this.placeData.placePhone3WithCC =
            ccName3 === "" || cc3 === "" ? "" : ccName3 + " " + cc3;

          let ccName4 = response.data.placeData.placePhone4CountryCodeName;
          let cc4 = response.data.placeData.placePhone4CountryCode;
          this.placeData.placePhone4WithCC =
            ccName4 === "" || cc4 === "" ? "" : ccName4 + " " + cc4;

          this.placeData.image = "";
          if (this.placeData.placeImagePath) {
            this.imageSrc = BASE_URL + this.placeData.placeImagePath;
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.placeData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placeData = null;
      }
      this.isLoading = false;
    },
    async updatePlace() {
      this.isLoading = true;
      try {
        let response = await this.placeData.addOrUpdatePlace(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Places" });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getPlaceDetails();
  },
};
</script>

<style scoped lang="scss"></style>
