<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="placesWorkPlanDaysData">
      <PlacesWorkPlanDaysTable
        :placesWorkPlanDaysData="placesWorkPlanDaysData"
        :placesWorkPlanDayData="placesWorkPlanDayData"
        v-on:setPlacesWorkPlanDayData="placesWorkPlanDayData.fillData($event)"
        v-on:setPlaceScheduleData="placeScheduleData.fillData($event)"
      />

      <PlacesWorkPlanDayEdit
        :placesWorkPlanDayData="placesWorkPlanDayData"
        v-on:refresh="getAllPlacesWorkPlansDays()"
      />
      <PlaceScheduleAdd
        :placesWorkPlanDayData="placesWorkPlanDayData"
        v-on:refresh="getAllPlacesWorkPlansDays()"
      />
      <PlaceScheduleEdit
        :placeScheduleData="placeScheduleData"
        v-on:refresh="getAllPlacesWorkPlansDays()"
      />
      <PlaceScheduleDelete
        :placeScheduleData="placeScheduleData"
        v-on:refresh="getAllPlacesWorkPlansDays()"
      />
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";
import PlacesWorkPlanDaysTable from "@/components/places/placesWorkPlanDays/PlacesWorkPlanDaysTable.vue";
import PlacesWorkPlanDayEdit from "@/components/places/placesWorkPlanDays/PlacesWorkPlanDayEdit.vue";
import PlacesWorkPlanDay from "@/models/places/placesWorkPlanDays/PlacesWorkPlanDay";
import PlaceSchedule from "@/models/places/placesSchedules/PlaceSchedule";
import PlaceScheduleAdd from "@/components/places/placesSchedules/PlaceScheduleAdd.vue";
import PlaceScheduleEdit from "@/components/places/placesSchedules/PlaceScheduleEdit.vue";
import PlaceScheduleDelete from "@/components/places/placesSchedules/PlaceScheduleDelete.vue";

export default {
  name: "PlacesWorkPlanDays",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    PlacesWorkPlanDaysTable,
    PlacesWorkPlanDayEdit,
    PlaceScheduleAdd,
    PlaceScheduleEdit,
    PlaceScheduleDelete,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      placeWorkPlanToken: this.$route.params.placeWorkPlanToken,
      placesWorkPlanDaysData: null,
      placesWorkPlanDayData: new PlacesWorkPlanDay(),
      placeScheduleData: new PlaceSchedule(),
    };
  },
  methods: {
    async getAllPlacesWorkPlansDays() {
      this.isLoading = true;
      try {
        const response =
          await this.placesWorkPlanDayData.getAllPlacesWorkPlansDays(
            this.language,
            this.userAuthorizeToken,
            this.selfPage,
            this.textSearch,
            this.placeWorkPlanToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.placesWorkPlanDaysData = response.data.placesWorkPlansDaysData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.placesWorkPlanDaysData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.placesWorkPlanDaysData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placesWorkPlanDaysData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placesWorkPlanDaysData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllPlacesWorkPlansDays();
  },
};
</script>
