<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off" method="post" @submit.prevent="addReservation()">
      <div class="my-card">
        <div class="row">
          <TextArea
            :className="'col-md-6'"
            :id="'reservationDescriptionAr'"
            :value="reservationData.reservationDescriptionAr"
            v-on:changeValue="reservationData.reservationDescriptionAr = $event"
            :title="$t('reservations.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'reservationDescriptionEn'"
            :value="reservationData.reservationDescriptionEn"
            v-on:changeValue="reservationData.reservationDescriptionEn = $event"
            :title="$t('reservations.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'reservationDescriptionUnd'"
            :value="reservationData.reservationDescriptionUnd"
            v-on:changeValue="
              reservationData.reservationDescriptionUnd = $event
            "
            :title="$t('reservations.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'reservationNotes'"
            :value="reservationData.reservationNotes"
            v-on:changeValue="reservationData.reservationNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="`/reservations/enquiry-reservation-details/${reservationData.enquiryReservationToken}`"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ReservationForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["reservationData", "submitName"],
  methods: {
    async addReservation() {
      this.$emit("addReservation");
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
