<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="expensesData">
      <ExpensesTable
        :expensesData="expensesData"
        :expenseData="expenseData"
        v-on:setExpenseData="expenseData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <ExpenseInfo :expenseData="expenseData" />
      <ExpenseRefund
        :expenseData="expenseData"
        v-on:refresh="getAllExpenses()"
      />
    </div>

    <ExpenseFloatBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ExpensesTable from "@/components/finance/expenses/ExpensesTable.vue";
import ExpenseInfo from "@/components/finance/expenses/ExpenseInfo.vue";
import ExpenseRefund from "@/components/finance/expenses/ExpenseRefund.vue";
import ExpenseFloatBtns from "@/components/finance/expenses/ExpenseFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Expense from "@/models/finance/expenses/Expense";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Expenses",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ExpensesTable,
    ExpenseInfo,
    ExpenseRefund,
    ExpenseFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      treasury: "",
      expensesType: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      expensesData: null,
      expenseData: new Expense(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllExpenses();
    },
    search(text, treasury, expensesType) {
      this.textSearch = text;

      this.treasury = treasury;
      this.expensesType = expensesType;

      this.getAllExpenses();
    },
    async getAllExpenses() {
      this.isLoading = true;
      try {
        const response = await this.expenseData.getAllExpenses(
          this.language,
          this.userAuthorizeToken,
          this.selfPage,
          this.textSearch,
          this.treasury,
          this.expensesType
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.expensesData = response.data.establishmentExpensesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.expensesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.expensesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.expensesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
        this.expensesData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllExpenses();
  },
};
</script>
