<template>
  <div v-if="enquiryReservationData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>{{ $t("enquiryReservations.reservationsStatus") }}</th>
            <th v-if="enquiryReservationData.reservationsStatus != true">
              {{ $t("enquiryReservations.reserve") }}
            </th>
            <th>{{ $t("enquiryReservations.code") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("activities.name") }}</th>
            <th>{{ $t("places.code") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th class="cell-lg">{{ $t("enquiryReservations.name") }}</th>
            <th>{{ $t("enquiryReservations.totalPrice") }}</th>
            <th>{{ $t("userMakeAction") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                enquiryReservationData.reservationsStatus == true
                  ? $t("yes")
                  : $t("no")
              }}
            </td>
            <td v-if="enquiryReservationData.reservationsStatus != true">
              <router-link
                :to="`/reservations/add-reservation/${enquiryReservationData.enquiryReservationToken}`"
                :title="$t('enquiryReservations.reserve')"
              >
                <img src="@/assets/images/reserve.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              {{ isDataExist(enquiryReservationData.enquiryReservationCode) }}
            </td>
            <td>
              {{ isDataExist(enquiryReservationData.clientNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(enquiryReservationData.activityNameCurrent) }}
            </td>
            <td>{{ isDataExist(enquiryReservationData.placeCode) }}</td>
            <td>{{ isDataExist(enquiryReservationData.placeNameCurrent) }}</td>
            <td>
              {{
                isDataExist(
                  enquiryReservationData.enquiryReservationNameCurrent
                )
              }}
            </td>
            <td>{{ moneyFormat(enquiryReservationData.totalPrice) }}</td>
            <td>
              {{ isDataExist(enquiryReservationData.addedByUserNameCurrent) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  enquiryReservationData.actionDate,
                  enquiryReservationData.actionTime
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="enquiryReservationData.enquiryReservationsSchedulesData"
      class="table-container"
    >
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("enquiryReservations.scheduleCode") }}</th>
            <th>{{ $t("day") }}</th>
            <th>{{ $t("enquiryReservations.fromDateTime") }}</th>
            <th>{{ $t("enquiryReservations.toDateTime") }}</th>
            <th>{{ $t("enquiryReservations.duration") }}</th>
            <th>{{ $t("enquiryReservations.totalPrice") }}</th>
            <th>{{ $t("notes") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              item, index
            ) in enquiryReservationData.enquiryReservationsSchedulesData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.enquiryReservationScheduleCode) }}</td>
            <td>{{ isDataExist(item.dayNameCurrent) }}</td>
            <td>
              {{ formateDateTimeLang(item.dateFrom, item.timeFrom) }}
            </td>
            <td>
              {{ formateDateTimeLang(item.dateTo, item.timeTo) }}
            </td>
            <td>{{ isDataExist(item.actualDurationTime) }}</td>
            <td>{{ moneyFormat(item.appointmentPrice) }}</td>
            <td>{{ isDataExist(item.enquiryReservationScheduleNotes) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";

export default {
  name: "EnquiryReservationDetailsTable",
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["enquiryReservationData"],
  methods: {
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
