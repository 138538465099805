<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <DistrictForm
      v-if="!isLoading"
      :districtData="districtData"
      :submitName="$t('add')"
      v-on:addOrUpdateDistrict="addOrUpdateDistrict"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import DistrictForm from "@/components/settings/settingsOther/districts/DistrictForm.vue";
import District from "@/models/settings/settingsOther/districts/District";

export default {
  name: "DistrictAdd",
  mixins: [createToastMixin],
  components: {
    DistrictForm,
  },
  data() {
    return {
      isLoading: false,

      districtData: new District(),
    };
  },
  methods: {
    async addOrUpdateDistrict() {
      this.isLoading = true;
      try {
        let response = await this.districtData.addOrUpdateDistrict(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.districtData.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>

<style scoped lang="scss"></style>
