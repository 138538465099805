<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off" method="post" @submit.prevent="addOrUpdatePlace">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.backgroundImage = url(${defaultImg})`"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeCode'"
            :value="placeData.placeCode"
            :title="$t('places.code')"
            :imgName="'number.svg'"
            v-on:changeValue="placeData.placeCode = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeNameAr'"
            :value="placeData.placeNameAr"
            :title="$t('places.nameAr')"
            :imgName="'places.svg'"
            v-on:changeValue="placeData.placeNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeNameEn'"
            :value="placeData.placeNameEn"
            :title="$t('places.nameEn')"
            :imgName="'places.svg'"
            v-on:changeValue="placeData.placeNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeNameUnd'"
            :value="placeData.placeNameUnd"
            :title="$t('places.nameUnd')"
            :imgName="'places.svg'"
            v-on:changeValue="placeData.placeNameUnd = $event"
          />

          <CustomSelectBox
            :className="'form-label-group  col-6'"
            :id="'activityToken'"
            :value="placeData.activityToken"
            :options="activityTokenOptions"
            v-on:changeValue="placeData.activityToken = $event"
            :title="$t('activities.select')"
            :imgName="'type.svg'"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeAddressAr'"
            :value="placeData.placeAddressAr"
            :title="$t('places.addressAr')"
            :imgName="'address.svg'"
            v-on:changeValue="placeData.placeAddressAr = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeAddressEn'"
            :value="placeData.placeAddressEn"
            :title="$t('places.addressEn')"
            :imgName="'address.svg'"
            v-on:changeValue="placeData.placeAddressEn = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeAddressUnd'"
            :value="placeData.placeAddressUnd"
            :title="$t('places.addressUnd')"
            :imgName="'address.svg'"
            v-on:changeValue="placeData.placeAddressUnd = $event"
          />

          <CustomSelectBox
            :className="'form-label-group  col-6'"
            :id="'countryToken'"
            :value="placeData.countryToken"
            :options="countryTokenOptions"
            v-on:changeValue="changeGovernate($event)"
            :title="$t('countries.select')"
            :imgName="'country.svg'"
          />
          <CustomSelectBox
            :className="'form-label-group  col-6'"
            :id="'governorateToken'"
            :value="placeData.governorateToken"
            :options="governateTokenOptions"
            v-on:changeValue="changeCity($event)"
            :title="$t('governates.select')"
            :imgName="'governate.svg'"
          />
          <CustomSelectBox
            :className="'form-label-group  col-6'"
            :id="'cityToken'"
            :value="placeData.cityToken"
            :options="cityTokenOptions"
            v-on:changeValue="changeDistrict($event)"
            :title="$t('cities.select')"
            :imgName="'city.svg'"
          />
          <CustomSelectBox
            :className="'form-label-group  col-6'"
            :id="'districtToken'"
            :value="placeData.districtToken"
            :options="districtTokenOptions"
            v-on:changeValue="placeData.districtToken = $event"
            :title="$t('districts.select')"
            :imgName="'district.svg'"
          />

          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'minimumReservationTime'"
            :value="placeData.minimumReservationTime"
            :title="$t('places.minimumReservationTime')"
            :imgName="'time.svg'"
            v-on:changeValue="placeData.minimumReservationTime = $event"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-6'"
            :value="placeData.minimumReservationTimeStatus"
            :title="$t('general.activeStatus')"
            v-on:changeValue="placeData.minimumReservationTimeStatus = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'maximumReservationTime'"
            :value="placeData.maximumReservationTime"
            :title="$t('places.maximumReservationTime')"
            :imgName="'time.svg'"
            v-on:changeValue="placeData.maximumReservationTime = $event"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-6'"
            :value="placeData.maximumReservationTimeStatus"
            :title="$t('general.activeStatus')"
            v-on:changeValue="placeData.maximumReservationTimeStatus = $event"
          />

          <CustomInput
            :className="'col-md-12'"
            :id="'mapLink'"
            :value="placeData.mapLink"
            :title="$t('places.mapLink')"
            :imgName="'hyperlink.svg'"
            v-on:changeValue="placeData.mapLink = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeLatitude'"
            :value="placeData.placeLatitude"
            :title="$t('places.placeLatitude')"
            :imgName="'latitude.svg'"
            v-on:changeValue="placeData.placeLatitude = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeEndLatitude'"
            :value="placeData.placeEndLatitude"
            :title="$t('places.placeEndLatitude')"
            :imgName="'latitude.svg'"
            v-on:changeValue="placeData.placeEndLatitude = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeLongitude'"
            :value="placeData.placeLongitude"
            :title="$t('places.placelongitude')"
            :imgName="'longitude.svg'"
            v-on:changeValue="placeData.placeLongitude = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placeEndLongitude'"
            :value="placeData.placeEndLongitude"
            :title="$t('places.placeEndlongitude')"
            :imgName="'longitude.svg'"
            v-on:changeValue="placeData.placeEndLongitude = $event"
          />

          <TelPicker
            :className="'col-md-6 col-lg-6'"
            :value="placeData.placePhone1WithCC"
            v-on:changeCC="placeData.placePhone1WithCC = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placePhone1'"
            :value="placeData.placePhone1"
            :title="$t('places.phone1')"
            :imgName="'phone.svg'"
            v-on:changeValue="placeData.placePhone1 = $event"
          />
          <TelPicker
            :className="'col-md-6 col-lg-6'"
            :value="placeData.placePhone2WithCC"
            v-on:changeCC="placeData.placePhone2WithCC = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placePhone2'"
            :value="placeData.placePhone2"
            :title="$t('places.phone2')"
            :imgName="'phone.svg'"
            v-on:changeValue="placeData.placePhone2 = $event"
          />
          <TelPicker
            :className="'col-md-6 col-lg-6'"
            :value="placeData.placePhone3WithCC"
            v-on:changeCC="placeData.placePhone3WithCC = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placePhone3'"
            :value="placeData.placePhone3"
            :title="$t('places.phone3')"
            :imgName="'phone.svg'"
            v-on:changeValue="placeData.placePhone3 = $event"
          />
          <TelPicker
            :className="'col-md-6 col-lg-6'"
            :value="placeData.placePhone4WithCC"
            v-on:changeCC="placeData.placePhone4WithCC = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'placePhone4'"
            :value="placeData.placePhone4"
            :title="$t('places.phone4')"
            :imgName="'phone.svg'"
            v-on:changeValue="placeData.placePhone4 = $event"
          />

          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'placeDescriptionAr'"
            :value="placeData.placeDescriptionAr"
            :title="$t('places.descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="placeData.placeDescriptionAr = $event"
          />
          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'placeDescriptionEn'"
            :value="placeData.placeDescriptionEn"
            :title="$t('places.descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="placeData.placeDescriptionEn = $event"
          />
          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'placeDescriptionUnd'"
            :value="placeData.placeDescriptionUnd"
            :title="$t('places.descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="placeData.placeDescriptionUnd = $event"
          />

          <CustomCheckbox
            :className="'col-md-6 col-lg-6'"
            :value="placeData.placeActiveStatus"
            :title="$t('general.activeStatus')"
            v-on:changeValue="placeData.placeActiveStatus = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Places' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import DEFAULT_IMG_CLIENT from "@/assets/images/places.svg";
import { setDataMultiLang } from "@/utils/functions";
import axios from "axios";
import TelPicker from "@/components/general/TelPicker.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import Country from "@/models/settings/settingsOther/countries/Country";
import Governate from "@/models/settings/settingsOther/governates/Governate";
import City from "@/models/settings/settingsOther/cities/City";
import District from "@/models/settings/settingsOther/districts/District";

export default {
  name: "PlaceForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TelPicker,
    CustomInput,
    CustomCheckbox,
    TextArea,
    CustomSelectBox,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      countryData: new Country(),
      governateData: new Governate(),
      cityData: new City(),
      districtData: new District(),
      defaultImg: DEFAULT_IMG_CLIENT,

      countryTokenOptions: [],
      governateTokenOptions: [],
      cityTokenOptions: [],
      districtTokenOptions: [],
      activityTokenOptions: [],

      showPassword: false,
    };
  },
  props: ["placeData", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.placeData.image = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG_CLIENT;
        this.placeData.image = "";
      }
      // console.log(this.imageSrc);
    },
    async getDialogOfCountries() {
      this.isLoading = true;
      this.countryTokenOptions = [];
      this.countryTokenOptions.push({
        value: "",
        text: this.$t("countries.select"),
      });
      try {
        const response = await this.countryData.getDialogOfCountries(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.countryTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.countryTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeGovernate(country) {
      this.placeData.countryToken = country;
      this.placeData.governorateToken = "";
      this.placeData.cityToken = "";
      this.placeData.districtToken = "";
      this.governateTokenOptions = [];
      this.cityTokenOptions = [];
      this.districtTokenOptions = [];
      this.getDialogOfGovernates(this.placeData.countryToken);
    },
    async getDialogOfGovernates(countryToken) {
      this.isLoading = true;
      this.governateTokenOptions = [];
      this.governateTokenOptions.push({
        value: "",
        text: this.$t("governates.select"),
      });
      try {
        const response = await this.governateData.getDialogOfGovernates(
          this.language,
          this.userAuthorizeToken,
          countryToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.governateTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.governateTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeCity(governate) {
      this.placeData.governorateToken = governate;
      this.placeData.cityToken = "";
      this.placeData.districtToken = "";
      this.cityTokenOptions = [];
      this.districtTokenOptions = [];
      this.getDialogOfCities(this.placeData.governorateToken);
    },
    async getDialogOfCities(cityToken) {
      this.isLoading = true;
      this.cityTokenOptions = [];
      this.cityTokenOptions.push({
        value: "",
        text: this.$t("cities.select"),
      });
      try {
        const response = await this.cityData.getDialogOfCities(
          this.language,
          this.userAuthorizeToken,
          cityToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.cityTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.cityTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeDistrict(city) {
      this.placeData.cityToken = city;
      this.placeData.districtToken = "";
      this.districtTokenOptions = [];
      this.getDialogOfDistricts(this.placeData.cityToken);
    },
    async getDialogOfDistricts(cityToken) {
      this.isLoading = true;
      this.districtTokenOptions = [];
      this.districtTokenOptions.push({
        value: "",
        text: this.$t("districts.select"),
      });
      try {
        const response = await this.districtData.getDialogOfDistricts(
          this.language,
          this.userAuthorizeToken,
          cityToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.districtTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.districtTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfActivities() {
      this.isLoading = true;
      this.activityTokenOptions = [];
      this.activityTokenOptions.push({
        value: "",
        text: this.$t("activities.select"),
      });
      try {
        const response = await axios.get(
          `/Activities/GetDialogOfActivities?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.activityTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.activityTokenOptions = null;
      }
      this.isLoading = false;
    },

    async addOrUpdatePlace() {
      this.$emit("addOrUpdatePlace", this.placeData);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  async created() {
    this.getDialogOfActivities();
    this.getDialogOfCountries();
    if (this.placeData.placeToken) {
      this.getDialogOfGovernates(this.placeData.countryToken);
      this.getDialogOfCities(this.placeData.governorateToken);
      this.getDialogOfDistricts(this.placeData.cityToken);
    }
  },
};
</script>

<style lang="scss"></style>
