import { MainConstansModuleImport } from "@/utils/constants";
import { Validator } from "@/utils/functions";
import axios from "axios";
import i18n from "@/i18n/index.js";

export default class Country {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.countryNameEn = "";
    this.countryNameAr = "";
    this.countryNameUnd = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.alpha2Code = "";
    this.alpha3Code = "";
    this.countryDescriptionCurrent = "";
    this.countryDescriptionEn = "";
    this.countryDescriptionAr = "";
    this.countryDescriptionUnd = "";
    this.countryNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.countryArchiveStatus = "";
  }
  fillData(data) {
    this.countryToken = data.countryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.countryNameEn = data.countryNameEn;
    this.countryNameAr = data.countryNameAr;
    this.countryNameUnd = data.countryNameUnd;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.alpha2Code = data.alpha2Code;
    this.alpha3Code = data.alpha3Code;
    this.countryDescriptionCurrent = data.countryDescriptionCurrent;
    this.countryDescriptionEn = data.countryDescriptionEn;
    this.countryDescriptionAr = data.countryDescriptionAr;
    this.countryDescriptionUnd = data.countryDescriptionUnd;
    this.countryNotes = data.countryNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.countryArchiveStatus = data.countryArchiveStatus;
  }
  async countryValidator(data) {
    console.log("in validator");
    //#region  validation
    // trim data

    data.countryNameEn = data.countryNameEn.trim();
    data.countryNameAr = data.countryNameAr.trim();
    data.countryNameUnd = data.countryNameUnd.trim();
    data.countryDescriptionEn = data.countryDescriptionEn.trim();
    data.countryDescriptionAr = data.countryDescriptionAr.trim();
    data.countryDescriptionUnd = data.countryDescriptionUnd.trim();

    let response = {
      data: {
        status: MainConstansModuleImport.STATUS.ERROR,
        msg: i18n.t("failedOperation"),
        errors: [],
      },
    };
    if (!data.countryNameEn && !data.countryNameAr && !data.countryNameUnd) {
      response.data.msg = i18n.t("validationTest");
      response.data.errors.push("countryNameEn");
      return response;
    }
    if (data.countryNameAr) {
      console.log(data.countryNameAr);
      if (!Validator.isNameAr(data.countryNameAr)) {
        response.data.msg = i18n.t("test");
        return response;
      }
      // check  min length
      if (
        Validator.isLessThan(
          data.countryNameAr,
          MainConstansModuleImport.NAME_MIN_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.NAME_MIN_LENGTH;
        return response;
      }
      if (
        Validator.isGreaterThan(
          data.countryNameAr,
          MainConstansModuleImport.NAME_MAX_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.NAME_MIN_LENGTH;
        return response;
      }
    }
    if (data.countryNameEn) {
      // Validator.is valid englValidator.ish name
      if (!Validator.isNameEN(data.countryNameEn)) {
        response.data.msg = i18n.t("test");
        return response;
      }
      // check length
      if (
        data.countryNameEn.length > MainConstansModuleImport.NAME_MAX_LENGTH
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.NAME_MAX_LENGTH;
        return response;
      }
      // check  min length
      if (
        Validator.isLessThan(
          data.countryNameEn,
          MainConstansModuleImport.NAME_MIN_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.NAME_MIN_LENGTH;
        return response;
      }
      if (
        Validator.isGreaterThan(
          data.countryNameEn,
          MainConstansModuleImport.NAME_MAX_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.NAME_MIN_LENGTH;
        return response;
      }
    }
    if (data.countryDescriptionAr) {
      // Validator.is valid arabic Description
      if (!Validator.isTextAr(data.countryDescriptionAr)) {
        response.data.msg = i18n.t("test");
        return response;
      }
      // check length
      if (
        Validator.isGreaterThan(
          data.countryDescriptionAr,
          MainConstansModuleImport.TEXT_MAX_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.TEXT_MAX_LENGTH;
        return response;
      }
    }
    if (data.countryDescriptionEn) {
      // Validator.is valid englValidator.ish Description
      if (!Validator.isTextEn(data.countryDescriptionEn)) {
        response.data.msg = i18n.t("test");
        return response;
      }

      // check length
      if (
        Validator.isGreaterThan(
          data.countryDescriptionEn,
          MainConstansModuleImport.TEXT_MAX_LENGTH
        )
      ) {
        response.data.msg =
          i18n.t("test") + MainConstansModuleImport.TEXT_MAX_LENGTH;
        return response;
      }
    }
    response.data.status = MainConstansModuleImport.STATUS.SUCCESS;
    response.data.msg = i18n.t("successOperation");
    return response;

    //#endregion validation
  }

  async getAllCountries(
    language,
    userAuthorizeToken,
    selfPage,
    filterStatus = "",
    textSearch = ""
  ) {
    try {
      let response = await axios.get(
        `/Countries/GetAllCountries?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&paginationStatus=&pageSize=${MainConstansModuleImport.PAGE_SIZE}&filterStatus=${filterStatus}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getCountryDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Countries/GetCountryDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&countryToken=${this.countryToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfCountries(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Countries/GetDialogOfCountries?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateCountry(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      countryToken: this.countryToken,
      countryNameEn: this.countryNameEn,
      countryNameAr: this.countryNameAr,
      countryNameUnd: this.countryNameUnd,
      alpha2Code: this.alpha2Code,
      alpha3Code: this.alpha3Code,
      countryDescriptionEn: this.countryDescriptionEn,
      countryDescriptionAr: this.countryDescriptionAr,
      countryDescriptionUnd: this.countryDescriptionUnd,
      countryNotes: this.countryNotes,
    };
    // validation
    // let response = await this.countryValidator(data);
    // if (response.data.status != MainConstansModuleImport.STATUS.SUCCESS) {
    //   return response;
    // }
    try {
      let response = "";
      if (this.countryToken == "" || this.countryToken == undefined) {
        response = await axios.post(`/Countries/AddCountry`, data);
      } else {
        response = await axios.post(`/Countries/UpdateCountry`, data);
      }
      return response;
    } catch (e) {
      console.log(e);
      return this.$t("errorCatch");
    }
  }

  async archiveCountry(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      countryToken: this.countryToken,
    };

    try {
      let response = await axios.post(`/Countries/ArchiveCountry`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
