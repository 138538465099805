<template>
  <div v-if="enquiryReservationsData">
    <div
      v-for="(enquiry, index) in enquiryReservationsData"
      :key="index"
      class="container-collapse-with-btns"
    >
      <b-button
        v-b-toggle="`item${index}`"
        class="btn btn-lg btn-collapse collapse-data"
      >
        <div class="p-t-container">
          <div class="row collapse-title">
            {{
              `${$t("enquiryReservations.code")}: ${isDataExist(
                enquiry.enquiryReservationCode
              )} - ${isDataExist(enquiry.enquiryReservationNameCurrent)}`
            }}
          </div>
          <div class="collapse-icon">
            <span class="counter">{{ index + 1 }}</span>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </div>
      </b-button>
      <div class="collapse-actions btn-collapse">
        <!-- <div>
          <router-link
            :to="`/reservations/enquiry-reservation-details/${enquiry.enquiryReservationToken}`"
            :title="$t('info')"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </router-link>
        </div> -->
        <div v-if="enquiry.reservationsStatus != true">
          <router-link
            :to="`/reservations/add-reservation/${enquiry.enquiryReservationToken}`"
            :title="$t('enquiryReservations.reserve')"
          >
            <img src="@/assets/images/reserve.svg" class="icon-lg" />
          </router-link>
        </div>
        <div>
          <button
            v-b-modal.EnquiryReservationDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setEnquiryReservationData(enquiry)"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
        </div>
      </div>
      <b-collapse :id="`item${index}`">
        <!-- #region enquiry -->
        <div class="table-container">
          <table class="my-table">
            <thead>
              <tr>
                <th>{{ $t("enquiryReservations.reservationsStatus") }}</th>
                <th>{{ $t("enquiryReservations.code") }}</th>
                <th>{{ $t("clientName") }}</th>
                <th>{{ $t("activities.name") }}</th>
                <th>{{ $t("places.code") }}</th>
                <th>{{ $t("places.name") }}</th>
                <th>{{ $t("enquiryReservations.totalPrice") }}</th>
                <th>{{ $t("userMakeAction") }}</th>
                <th>{{ $t("actionDateTime") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    enquiry.reservationsStatus == true ? $t("yes") : $t("no")
                  }}
                </td>
                <td>{{ isDataExist(enquiry.enquiryReservationCode) }}</td>
                <td>{{ isDataExist(enquiry.clientNameCurrent) }}</td>
                <td>{{ isDataExist(enquiry.activityNameCurrent) }}</td>
                <td>{{ isDataExist(enquiry.placeCode) }}</td>
                <td>{{ isDataExist(enquiry.placeNameCurrent) }}</td>
                <td>{{ moneyFormat(enquiry.totalPrice) }}</td>
                <td>{{ isDataExist(enquiry.addedByUserNameCurrent) }}</td>
                <td>
                  {{
                    formateDateTimeLang(enquiry.actionDate, enquiry.actionTime)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #endregion enquiry -->

        <!-- #endregion enquirySchedules -->
        <div
          v-if="enquiry.enquiryReservationsSchedulesData"
          class="table-container"
        >
          <table class="my-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("enquiryReservations.scheduleCode") }}</th>
                <th>{{ $t("day") }}</th>
                <th>{{ $t("enquiryReservations.fromDateTime") }}</th>
                <th>{{ $t("enquiryReservations.toDateTime") }}</th>
                <th>{{ $t("enquiryReservations.duration") }}</th>
                <th>{{ $t("enquiryReservations.totalPrice") }}</th>
                <th>{{ $t("notes") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  item, indexSchedule
                ) in enquiry.enquiryReservationsSchedulesData"
                :key="indexSchedule"
              >
                <td>{{ ++indexSchedule }}</td>
                <td>{{ isDataExist(item.enquiryReservationScheduleCode) }}</td>
                <td>{{ isDataExist(item.dayNameCurrent) }}</td>
                <td>{{ formateDateTimeLang(item.dateFrom, item.timeFrom) }}</td>
                <td>{{ formateDateTimeLang(item.dateTo, item.timeTo) }}</td>
                <td>{{ isDataExist(item.actualDurationTime) }}</td>
                <td>{{ moneyFormat(item.appointmentPrice) }}</td>
                <td>{{ isDataExist(item.enquiryReservationScheduleNotes) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #endregion enquirySchedules -->
      </b-collapse>
    </div>

    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("enquiryReservations.reservationsStatus") }}</th>
            <th>{{ $t("enquiryReservations.code") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("activities.name") }}</th>
            <th>{{ $t("places.code") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th class="cell-lg">{{ $t("enquiryReservations.name") }}</th>
            <th>{{ $t("enquiryReservations.totalPrice") }}</th>
            <th>{{ $t("userMakeAction") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in enquiryReservationsData" :key="index">
            <td>{{ ++index }}</td>
            <td>
              {{ item.reservationsStatus == true ? $t("yes") : $t("no") }}
            </td>
            <td>{{ isDataExist(item.enquiryReservationCode) }}</td>
            <td>{{ isDataExist(item.clientNameCurrent) }}</td>
            <td>{{ isDataExist(item.activityNameCurrent) }}</td>
            <td>{{ isDataExist(item.placeCode) }}</td>
            <td>{{ isDataExist(item.placeNameCurrent) }}</td>
            <td>{{ isDataExist(item.enquiryReservationNameCurrent) }}</td>
            <td>{{ moneyFormat(item.totalPrice) }}</td>
            <td>{{ isDataExist(item.addedByUserNameCurrent) }}</td>
            <td>
              {{ formateDateTimeLang(item.actionDate, item.actionTime) }}
            </td>
            <td>
              <router-link
                :to="`/reservations/enquiry-reservation-details/${item.enquiryReservationToken}`"
                :title="$t('info')"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.EnquiryReservationDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setEnquiryReservationData(item)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";

export default {
  name: "TransactionPointsTable",
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["enquiryReservationsData", "enquiryReservationData"],
  methods: {
    setEnquiryReservationData(enquiryReservationData) {
      this.$emit("setEnquiryReservationData", enquiryReservationData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
