<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <UserRoleForm
      v-if="!isLoading"
      :userRoleData="userRoleData"
      :submitName="$t('add')"
      v-on:addOrUpdateUserRole="addOrUpdateUserRole"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { ROLE_TYPE } from "@/utils/constantLists";
import UserRoleForm from "@/components/settings/settingsOther/userRoles/UserRoleForm.vue";
import UserRole from "@/models/settings/settingsOther/userRoles/UserRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ClientRoleAdd",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    UserRoleForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,

      userRoleData: new UserRole(),
      roleTypeToken: ROLE_TYPE.Client,
    };
  },
  methods: {
    async addOrUpdateUserRole() {
      this.isLoading = true;
      this.userRoleData.roleTypeToken = this.roleTypeToken;
      try {
        let response = await this.userRoleData.addOrUpdateEstablishmentRole(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.userRoleData.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.userRoleData.userType = "client";
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.userRoleData.userType = "client";
  },
};
</script>

<style scoped lang="scss"></style>
