export default class UsersPrivilegeReservationsSchedules {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationsSchedulesView = false;
    this.reservationsSchedulesAdd = false;
    this.reservationsSchedulesApprove = false;
  }
  fillData(data) {
    if (data) {
      this.reservationsSchedulesView = data.reservationsSchedulesView;
      this.reservationsSchedulesAdd = data.reservationsSchedulesAdd;
      this.reservationsSchedulesApprove = data.reservationsSchedulesApprove;
    } else {
      this.setInitialValue();
    }
  }
}
