import { ifNotAuthenticated } from "@/utils/functions";

import Reports from "@/views/reports/Reports.vue";

const Routes = [
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: ifNotAuthenticated,
  },
];

export default Routes;
