<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="clientData.clientNameCurrent">
            {{ clientData.clientNameCurrent }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">{{ clientData.clientCode }}</h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.ClientInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setClientData"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            :to="'/edit-client/' + clientData.clientToken"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <!-- <br /> -->
          <button
            v-b-modal.ClientDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setClientData"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <!-- <button @click="goToFamily()" :title="$t('family')">
            <img src="@/assets/images/family.svg" class="icon-lg" />
          </button> -->
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="clientData.clientNameCurrent">
            {{ clientData.clientNameCurrent }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/utils/constants";
import DEFAULT_IMG_CLIENT from "@/assets/images/client.svg";

export default {
  name: "ClientCard",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG_CLIENT,
    };
  },
  props: ["clientData"],
  methods: {
    setClientData() {
      this.$emit("setClientData", this.clientData);
    },
    goToFamily() {
      this.$store.dispatch("updateClientToken", this.clientData.clientToken);
      this.$router.push("/client-family").catch(() => {});
    },
  },
  computed: {
    imgSrc: function () {
      return BASE_URL + this.clientData.clientImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
