import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Treasury {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentTreasuryToken = "";
    this.establishmentTreasuryNameCurrent = "";
    this.establishmentTreasuryNameEn = "";
    this.establishmentTreasuryNameAr = "";
    this.establishmentTreasuryNameUnd = "";
    this.establishmentTreasuryCode = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.establishmentTreasuryDescriptionCurrent = "";
    this.establishmentTreasuryDescriptionEn = "";
    this.establishmentTreasuryDescriptionAr = "";
    this.establishmentTreasuryDescriptionUnd = "";
    this.establishmentTreasuryMoney = "";
    this.establishmentTreasuryNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.establishmentTreasuryArchiveStatus = "";
  }
  fillData(data) {
    this.establishmentTreasuryToken = data.establishmentTreasuryToken;
    this.establishmentTreasuryNameCurrent =
      data.establishmentTreasuryNameCurrent;
    this.establishmentTreasuryNameEn = data.establishmentTreasuryNameEn;
    this.establishmentTreasuryNameAr = data.establishmentTreasuryNameAr;
    this.establishmentTreasuryNameUnd = data.establishmentTreasuryNameUnd;
    this.establishmentTreasuryCode = data.establishmentTreasuryCode;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.establishmentTreasuryDescriptionCurrent =
      data.establishmentTreasuryDescriptionCurrent;
    this.establishmentTreasuryDescriptionEn =
      data.establishmentTreasuryDescriptionEn;
    this.establishmentTreasuryDescriptionAr =
      data.establishmentTreasuryDescriptionAr;
    this.establishmentTreasuryDescriptionUnd =
      data.establishmentTreasuryDescriptionUnd;
    this.establishmentTreasuryMoney = data.establishmentTreasuryMoney;
    this.establishmentTreasuryNotes = data.establishmentTreasuryNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.establishmentTreasuryArchiveStatus =
      data.establishmentTreasuryArchiveStatus;
  }

  async getAllTreasuries(language, userAuthorizeToken, selfPage, textSearch) {
    try {
      let response = await axios.get(
        `/EstablishmentTreasuries/GetAllEstablishmentTreasuries/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getTreasuryDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentTreasuries/GetEstablishmentTreasuryDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentTreasuryToken=${this.establishmentTreasuryToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfTreasuries(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentTreasuries/GetDialogOfEstablishmentTreasuries?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateTreasury(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentTreasuryToken: this.establishmentTreasuryToken,
      establishmentTreasuryNameCurrent: this.establishmentTreasuryNameCurrent,
      establishmentTreasuryNameEn: this.establishmentTreasuryNameEn,
      establishmentTreasuryNameAr: this.establishmentTreasuryNameAr,
      establishmentTreasuryNameUnd: this.establishmentTreasuryNameUnd,
      establishmentTreasuryCode: this.establishmentTreasuryCode,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      establishmentTreasuryDescriptionCurrent:
        this.establishmentTreasuryDescriptionCurrent,
      establishmentTreasuryDescriptionEn:
        this.establishmentTreasuryDescriptionEn,
      establishmentTreasuryDescriptionAr:
        this.establishmentTreasuryDescriptionAr,
      establishmentTreasuryDescriptionUnd:
        this.establishmentTreasuryDescriptionUnd,
      establishmentTreasuryMoney: this.establishmentTreasuryMoney,
      establishmentTreasuryNotes: this.establishmentTreasuryNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      establishmentTreasuryArchiveStatus:
        this.establishmentTreasuryArchiveStatus,
    };

    try {
      let response = "";
      if (
        this.establishmentTreasuryToken == "" ||
        this.establishmentTreasuryToken == undefined
      ) {
        response = await axios.post(
          `/EstablishmentTreasuries/AddEstablishmentTreasury`,
          data
        );
      } else {
        response = await axios.post(
          `/EstablishmentTreasuries/UpdateEstablishmentTreasury`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveTreasury(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentTreasuryToken: this.establishmentTreasuryToken,
    };

    try {
      let response = await axios.post(
        `/EstablishmentTreasuries/ArchiveEstablishmentTreasury`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
