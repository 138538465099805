import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import { date2slash } from "@/utils/functions";

export default class Offer {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.offerToken = "";
    this.userToken = "";
    this.userNameCurrent = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.offerCode = "";
    this.offerMediaTypeToken = "";
    this.offerMediaTypeNameCurrent = "";
    this.offerMediaTypeNameAr = "";
    this.offerMediaTypeNameEn = "";
    this.offerMediaTypeNameUnd = "";
    this.offerTitleCurrent = "";
    this.offerTitleEn = "";
    this.offerTitleAr = "";
    this.offerTitleUnd = "";
    this.offerStartDate = "";
    this.offerEndDate = "";
    this.offerDuration = "";
    this.offerStartTimeInDay = "";
    this.offerEndTimeInDay = "";
    this.offerDurationInDay = "";
    this.hourPriceInOffer = "";
    this.offerDescriptionCurrent = "";
    this.offerDescriptionEn = "";
    this.offerDescriptionAr = "";
    this.offerDescriptionUnd = "";
    this.offerNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.offerMediaPath = "";
    this.offerArchiveStatus = "";
  }
  fillData(data) {
    this.image = data.image;
    this.offerToken = data.offerToken;
    this.userToken = data.userToken;
    this.userNameCurrent = data.userNameCurrent;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.offerCode = data.offerCode;
    this.offerMediaTypeToken = data.offerMediaTypeToken;
    this.offerMediaTypeNameCurrent = data.offerMediaTypeNameCurrent;
    this.offerMediaTypeNameAr = data.offerMediaTypeNameAr;
    this.offerMediaTypeNameEn = data.offerMediaTypeNameEn;
    this.offerMediaTypeNameUnd = data.offerMediaTypeNameUnd;
    this.offerTitleCurrent = data.offerTitleCurrent;
    this.offerTitleEn = data.offerTitleEn;
    this.offerTitleAr = data.offerTitleAr;
    this.offerTitleUnd = data.offerTitleUnd;
    this.offerStartDate = data.offerStartDate;
    this.offerEndDate = data.offerEndDate;
    this.offerDuration = data.offerDuration;
    this.offerStartTimeInDay = data.offerStartTimeInDay;
    this.offerEndTimeInDay = data.offerEndTimeInDay;
    this.offerDurationInDay = data.offerDurationInDay;
    this.hourPriceInOffer = data.hourPriceInOffer;
    this.offerDescriptionCurrent = data.offerDescriptionCurrent;
    this.offerDescriptionEn = data.offerDescriptionEn;
    this.offerDescriptionAr = data.offerDescriptionAr;
    this.offerDescriptionUnd = data.offerDescriptionUnd;
    this.offerNotes = data.offerNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.offerMediaPath = data.offerMediaPath;
    this.offerArchiveStatus = data.offerArchiveStatus;
  }

  async getAllOffers(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    placeToken = "",
    offerMediaTypeToken = ""
  ) {
    try {
      let response = await axios.get(
        `/Offers/GetAllOffers?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&placeToken=${placeToken}&offerMediaTypeToken=${offerMediaTypeToken}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getOfferDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `Offers/GetOffersDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&offerToken=${this.offerToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateOffer(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      offerToken: this.offerToken,
      userToken: this.userToken,
      userNameCurrent: this.userNameCurrent,
      placeToken: this.placeToken,
      placeNameCurrent: this.placeNameCurrent,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      offerCode: this.offerCode,
      offerMediaTypeToken: this.offerMediaTypeToken,
      offerMediaTypeNameCurrent: this.offerMediaTypeNameCurrent,
      offerMediaTypeNameAr: this.offerMediaTypeNameAr,
      offerMediaTypeNameEn: this.offerMediaTypeNameEn,
      offerMediaTypeNameUnd: this.offerMediaTypeNameUnd,
      offerTitleCurrent: this.offerTitleCurrent,
      offerTitleEn: this.offerTitleEn,
      offerTitleAr: this.offerTitleAr,
      offerTitleUnd: this.offerTitleUnd,
      offerStartDate: date2slash(this.offerStartDate),
      offerEndDate: date2slash(this.offerEndDate),
      offerDuration: this.offerDuration,
      offerStartTimeInDay: this.offerStartTimeInDay,
      offerEndTimeInDay: this.offerEndTimeInDay,
      offerDurationInDay: this.offerDurationInDay,
      hourPriceInOffer: this.hourPriceInOffer,
      offerDescriptionCurrent: this.offerDescriptionCurrent,
      offerDescriptionEn: this.offerDescriptionEn,
      offerDescriptionAr: this.offerDescriptionAr,
      offerDescriptionUnd: this.offerDescriptionUnd,
      offerNotes: this.offerNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      offerMediaPath: this.offerMediaPath,
      offerArchiveStatus: this.offerArchiveStatus,
    };

    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.offerToken == "" || this.offerToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(`/Offers/AddOffersWithMedia`, formData);
        } else {
          response = await axios.post(`/Offers/AddOffersWithoutMedia`, data);
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Offers/UpdateOffersWithMedia`,
            formData
          );
        } else {
          response = await axios.post(`/Offers/UpdateoffersWithoutMedia`, data);
        }
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveOffer(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      offerToken: this.offerToken,
    };

    try {
      let response = await axios.post(`/Offers/ArchiveOffers`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
