<template>
  <div v-if="reservationsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("activities.name") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("reservations.code") }}</th>
            <!-- <th>{{ $t("places.code") }}</th> -->
            <th class="cell-lg">{{ $t("reservations.name") }}</th>
            <!-- <th>{{ $t("reservations.totalPrice") }}</th> -->
            <!-- <th>{{ $t("userMakeAction") }}</th> -->
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("reservations.QRcode") }}</th>
            <th>{{ $t("info") }}</th>
            <!-- <th>{{ $t("cancel") }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in reservationsData" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.activityNameCurrent) }}</td>
            <td>{{ isDataExist(item.placeNameCurrent) }}</td>
            <td>{{ isDataExist(item.clientNameCurrent) }}</td>
            <td>{{ isDataExist(item.reservationCode) }}</td>
            <!-- <td>{{ isDataExist(item.placeCode) }}</td> -->
            <td>{{ isDataExist(item.reservationNameCurrent) }}</td>
            <!-- <td>{{ moneyFormat(item.totalPrice) }}</td> -->
            <!-- <td>{{ isDataExist(item.addedByUserNameCurrent) }}</td> -->
            <td>
              {{ formateDateTimeLang(item.actionDate, item.actionTime) }}
            </td>
            <td>
              <button
                v-b-modal.ReservationQRCode
                class="btn p-0"
                :title="$t('reservations.QRcode')"
                @click="setReservationData(item)"
              >
                <img src="@/assets/images/qr-code.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="`/reservations/reservation-details/${item.reservationToken}`"
                :title="$t('info')"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </router-link>
            </td>
            <!-- <td>
              <button
                v-b-modal.ReservationCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="setReservationData(item)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";

export default {
  name: "ReservationsTable",
  // components: {},
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["reservationsData", "enquiryReservationData"],
  methods: {
    setReservationData(enquiryReservationData) {
      this.$emit("setReservationData", enquiryReservationData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
