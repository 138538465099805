import { BASE_URL, PAGE_SIZE } from "@/utils/constants";
import { date2slash } from "@/utils/functions";
import axios from "axios";

export default class Revenue {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.imageSrc = BASE_URL + this.establishmentRevenuesImagePath;
    this.establishmentRevenuesToken = "";
    this.userToken = "";
    this.userNameCurrent = "";
    this.establishmentRevenuesCode = "";
    this.establishmentTreasuryToken = "";
    this.establishmentTreasuryNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.establishmentRevenuesImagePath = "";
    this.establishmentRevenuesTypeToken = "";
    this.establishmentRevenuesTypeNameCurrent = "";
    this.establishmentRevenuesDatePayed = "";
    this.establishmentRevenuesDateTimeAction = "";
    this.establishmentRevenuesDateAction = "";
    this.establishmentRevenuesTimeAction = "";
    this.establishmentRevenuesMoneyValue = "";
    this.establishmentRevenuesNotes = "";
    this.userTokenReturned = "";
    this.userNameCurrentReturned = "";
    this.establishmentRevenuesReturnedNote = "";
    this.establishmentRevenuesReturnedDateTime = "";
    this.establishmentRevenuesReturnedDate = "";
    this.establishmentRevenuesReturnedTime = "";
    this.establishmentRevenuesReturnedStatus = "";
    this.establishmentRevenuesArchiveStatus = "";
  }
  fillData(revenueData) {
    this.image = revenueData.image;
    this.imageSrc = BASE_URL + revenueData.establishmentRevenuesImagePath;
    this.establishmentRevenuesToken = revenueData.establishmentRevenuesToken;
    this.userToken = revenueData.userToken;
    this.userNameCurrent = revenueData.userNameCurrent;
    this.establishmentRevenuesCode = revenueData.establishmentRevenuesCode;
    this.establishmentTreasuryToken = revenueData.establishmentTreasuryToken;
    this.establishmentTreasuryNameCurrent =
      revenueData.establishmentTreasuryNameCurrent;
    this.establishmentToken = revenueData.establishmentToken;
    this.establishmentNameCurrent = revenueData.establishmentNameCurrent;
    this.establishmentRevenuesImagePath =
      revenueData.establishmentRevenuesImagePath;
    this.establishmentRevenuesTypeToken =
      revenueData.establishmentRevenuesTypeToken;
    this.establishmentRevenuesTypeNameCurrent =
      revenueData.establishmentRevenuesTypeNameCurrent;
    this.establishmentRevenuesDatePayed =
      revenueData.establishmentRevenuesDatePayed;
    this.establishmentRevenuesDateTimeAction =
      revenueData.establishmentRevenuesDateTimeAction;
    this.establishmentRevenuesDateAction =
      revenueData.establishmentRevenuesDateAction;
    this.establishmentRevenuesTimeAction =
      revenueData.establishmentRevenuesTimeAction;
    this.establishmentRevenuesMoneyValue =
      revenueData.establishmentRevenuesMoneyValue;
    this.establishmentRevenuesNotes = revenueData.establishmentRevenuesNotes;
    this.userTokenReturned = revenueData.userTokenReturned;
    this.userNameCurrentReturned = revenueData.userNameCurrentReturned;
    this.establishmentRevenuesReturnedNote =
      revenueData.establishmentRevenuesReturnedNote;
    this.establishmentRevenuesReturnedDateTime =
      revenueData.establishmentRevenuesReturnedDateTime;
    this.establishmentRevenuesReturnedDate =
      revenueData.establishmentRevenuesReturnedDate;
    this.establishmentRevenuesReturnedTime =
      revenueData.establishmentRevenuesReturnedTime;
    this.establishmentRevenuesReturnedStatus =
      revenueData.establishmentRevenuesReturnedStatus;
    this.establishmentRevenuesArchiveStatus =
      revenueData.establishmentRevenuesArchiveStatus;
  }

  async getAllRevenues(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    treasury = "",
    revenuesType = ""
  ) {
    try {
      let response = await axios.get(
        `/EstablishmentRevenues/GetAllEstablishmentRevenues/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&establishmentTreasuryToken=${treasury}&establishmentRevenuesTypeToken=${revenuesType}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getRevenueDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentRevenues/GetEstablishmentRevenuesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRevenuesToken=${this.establishmentRevenuesToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addRevenue(language, userAuthorizeToken) {
    var data = {
      image: this.image,
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRevenuesTypeToken: this.establishmentRevenuesTypeToken,
      establishmentTreasuryToken: this.establishmentTreasuryToken,
      establishmentRevenuesDatePayed: date2slash(
        this.establishmentRevenuesDatePayed
      ),
      establishmentRevenuesMoneyValue: this.establishmentRevenuesMoneyValue,
      establishmentRevenuesNotes: this.establishmentRevenuesNotes,
      establishmentRevenuesCode: this.establishmentRevenuesCode,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.image != "" && this.image != undefined) {
        response = await axios.post(
          `/EstablishmentRevenues/AddEstablishmentRevenuesWithImage`,
          formData
        );
      } else {
        response = await axios.post(
          `/EstablishmentRevenues/AddEstablishmentRevenues`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async refundRevenue(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRevenuesToken: this.establishmentRevenuesToken,
    };

    try {
      let response = await axios.post(
        `/EstablishmentRevenues/RefundRevenues`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
