import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class UserRole {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userType = "";
    this.establishmentRoleToken = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentRoleNameEn = "";
    this.establishmentRoleNameAr = "";
    this.establishmentRoleNameUnd = "";
    this.roleTypeToken = "";
    this.roleTypeNameEn = "";
    this.roleTypeNameAr = "";
    this.roleTypeNameUnd = "";
    this.roleTypeNameCurrent = "";
    this.establishmentRoleDescriptionCurrent = "";
    this.establishmentRoleDescriptionEn = "";
    this.establishmentRoleDescriptionAr = "";
    this.establishmentRoleDescriptionUnd = "";
    this.establishmentRoleNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.establishmentRoleDefaultStatus = "";
    this.establishmentRoleArchiveStatus = "";
  }
  fillData(data) {
    this.userType = data.userType;
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentRoleNameEn = data.establishmentRoleNameEn;
    this.establishmentRoleNameAr = data.establishmentRoleNameAr;
    this.establishmentRoleNameUnd = data.establishmentRoleNameUnd;
    this.roleTypeToken = data.roleTypeToken;
    this.roleTypeNameEn = data.roleTypeNameEn;
    this.roleTypeNameAr = data.roleTypeNameAr;
    this.roleTypeNameUnd = data.roleTypeNameUnd;
    this.roleTypeNameCurrent = data.roleTypeNameCurrent;
    this.establishmentRoleDescriptionCurrent =
      data.establishmentRoleDescriptionCurrent;
    this.establishmentRoleDescriptionEn = data.establishmentRoleDescriptionEn;
    this.establishmentRoleDescriptionAr = data.establishmentRoleDescriptionAr;
    this.establishmentRoleDescriptionUnd = data.establishmentRoleDescriptionUnd;
    this.establishmentRoleNotes = data.establishmentRoleNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.establishmentRoleDefaultStatus = data.establishmentRoleDefaultStatus;
    this.establishmentRoleArchiveStatus = data.establishmentRoleArchiveStatus;
  }

  async getAllEstablishmentRoles(
    language,
    userAuthorizeToken,
    selfPage,
    filterStatus,
    textSearch,
    roleTypeToken
  ) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetAllEstablishmentRoles/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=${filterStatus}&textSearch=${textSearch}&roleTypeToken=${roleTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEstablishmentRoleDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetEstablishmentRoleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRoleToken=${this.establishmentRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEstablishmentRoles(
    language,
    userAuthorizeToken,
    roleTypeToken = ""
  ) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetDialogOfEstablishmentRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&roleTypeToken=${roleTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEstablishmentRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
      establishmentRoleNameAr: this.establishmentRoleNameAr,
      establishmentRoleNameEn: this.establishmentRoleNameEn,
      establishmentRoleNameUnd: this.establishmentRoleNameUnd,
      roleTypeToken: this.roleTypeToken,
      establishmentRoleDescriptionEn: this.establishmentRoleDescriptionEn,
      establishmentRoleDescriptionAr: this.establishmentRoleDescriptionAr,
      establishmentRoleDescriptionUnd: this.establishmentRoleDescriptionUnd,
      establishmentRoleDefaultStatus: this.establishmentRoleDefaultStatus,
      establishmentRoleNotes: this.establishmentRoleNotes,
    };

    try {
      let response = "";
      if (
        this.establishmentRoleToken == "" ||
        this.establishmentRoleToken == undefined
      ) {
        response = await axios.post(
          `/EstablishmentRoles/AddEstablishmentRole`,
          data
        );
      } else {
        response = await axios.post(
          `/EstablishmentRoles/UpdateEstablishmentRole`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEstablishmentRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
    };

    try {
      let response = await axios.post(
        `/EstablishmentRoles/ArchiveEstablishmentRole`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
