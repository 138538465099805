<template>
  <b-modal id="ActivityInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/type.svg" class="icon-lg" />
        {{ $t("activities.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityNameAr"
        :title="$t('activities.nameAr')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityNameEn"
        :title="$t('activities.nameEn')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityNameUnd"
        :title="$t('activities.nameUnd')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityNameCurrent"
        :title="$t('activities.name')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="
          formateDateDMY(activityData.actionDate) +
          ' ' +
          timeToLang(activityData.actionTime)
        "
        :title="$t('countries.actionDateTime')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="activityData.activityCode"
        :title="$t('activities.code')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          activityData.activityActiveStatus === true ? $t('yes') : $t('no')
        "
        :title="$t('general.activeStatus')"
        :imgName="'type.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateDMY,
} from "@/utils/functions";

export default {
  name: "ActivityInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["activityData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    formateDateDMY(date, lang = this.language) {
      return formateDateDMY(date, lang);
    },
  },
};
</script>

<style scoped lang="scss"></style>
