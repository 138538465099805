<template>
  <div class="">
    <div v-if="this.$route.name === pageName">
      <PreLoader v-if="isLoading" />
      <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

      <div v-if="placesData">
        <div class="row">
          <PlaceCard
            v-for="place in placesData"
            :key="place.placeToken"
            :placeData="place"
            v-on:setPlaceData="placeData.fillData($event)"
          />
        </div>
        <Pagination
          v-if="!isLoading"
          :paginationData="paginationData"
          v-on:changePage="changePage"
        />
        <PlaceInfo :placeData="placeData" />
        <PlaceDelete :placeData="placeData" v-on:refresh="getAllPlaces()" />
      </div>

      <PlaceFloatBtns
        :textSearch="textSearch"
        :filter="filter"
        v-on:search="search"
      />
    </div>
    <router-view v-else :key="$router.path" />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import PlaceCard from "@/components/places/places/PlaceCard.vue";
import PlaceInfo from "@/components/places/places/PlaceInfo.vue";
import PlaceDelete from "@/components/places/places/PlaceDelete.vue";
import PlaceFloatBtns from "@/components/places/places/PlaceFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Place from "@/models/places/places/Place";

export default {
  name: "Places",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    PlaceCard,
    PlaceInfo,
    PlaceDelete,
    PlaceFloatBtns,
    Pagination,
  },
  data() {
    return {
      pageName: "Places",
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      placesData: null,
      placeData: new Place(),
      filter: {
        countryToken: "",
        governorateToken: "",
        cityToken: "",
        districtToken: "",
      },
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllPlaces();
    },
    search(text, filter) {
      this.textSearch = text;
      this.filter.countryToken = filter.countryToken;
      this.filter.governorateToken = filter.governorateToken;
      this.filter.cityToken = filter.cityToken;
      this.filter.districtToken = filter.districtToken;

      this.getAllPlaces();
    },
    async getAllPlaces() {
      this.isLoading = true;
      try {
        const response = await this.placeData.getAllPlaces(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch,
          this.filter
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.placesData = response.data.placesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.placesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.placesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placesData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.placesData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllPlaces();
  },
};
</script>
