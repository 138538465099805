<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="citiesData">
      <CitiesTable
        :citiesData="citiesData"
        :cityData="cityData"
        v-on:setCityData="cityData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <CityInfo :cityData="cityData" />
      <CityDelete :cityData="cityData" v-on:refresh="getAllCities()" />
    </div>

    <CityBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";

import { STATUS } from "@/utils/constants";
import CitiesTable from "@/components/settings/settingsOther/cities/CitiesTable.vue";
import CityInfo from "@/components/settings/settingsOther/cities/CityInfo.vue";
import CityDelete from "@/components/settings/settingsOther/cities/CityDelete.vue";
import CityBtns from "@/components/settings/settingsOther/cities/CityBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import City from "@/models/settings/settingsOther/cities/City";

export default {
  name: "Cities",
  mixins: [createToastMixin],
  components: {
    CitiesTable,
    CityInfo,
    CityDelete,
    CityBtns,
    Pagination,
  },
  data() {
    return {
      citiesData: null,
      cityData: new City(),
      filter: {
        country: "",
        governate: "",
      },
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllCities();
    },
    search(text, country, governate) {
      this.textSearch = text;
      this.filter.country = country;
      this.filter.governate = governate;
      this.getAllCities();
    },
    async getAllCities() {
      this.isLoading = true;
      try {
        const response = await this.cityData.getAllCities(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch,
          this.filter.country,
          this.filter.governate
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.citiesData = response.data.citiesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.citiesData = null;
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.citiesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.citiesData = null;
          this.showMsg(response.data.msg);
          this.errorCatch();
        }
      } catch (error) {
        this.citiesData = null;
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllCities();
    // console.log(this.citiesData);
  },
};
</script>
