<template>
  <div v-if="paymentMethodesData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(paymentMethode, index) in paymentMethodesData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(paymentMethode.paymentMethodeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(paymentMethode.paymentMethodeDescriptionCurrent) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  paymentMethode.actionDate,
                  paymentMethode.actionTime
                )
              }}
            </td>
            <td>{{ isDataExist(paymentMethode.paymentMethodeNotes) }}</td>
            <td>
              <button
                v-b-modal.PaymentMethodeInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setPaymentMethodeData(paymentMethode)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'PaymentMethodeEdit',
                  params: {
                    paymentMethodeToken: `${paymentMethode.paymentMethodeToken}`,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.PaymentMethodeDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setPaymentMethodeData(paymentMethode)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "PaymentMethodesTable",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["paymentMethodesData", "paymentMethodeData"],
  methods: {
    setPaymentMethodeData(paymentMethodeData) {
      this.$emit("setPaymentMethodeData", paymentMethodeData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
