<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'establishmentRoleToken'"
            :value="userEstablishmentPrivilegeDate.establishmentRoleToken"
            :options="establishmentRoleTokenOptions"
            v-on:changeValue="changeEstablishmentRoleToken($event)"
            :title="$t('setting.others.establishmentRolesMsgs.select')"
            :imgName="'establishment-roles.svg'"
          />
        </div>
      </div>

      <div v-if="userEstablishmentPrivilegeDate.establishmentRoleToken">
        <UsersPrivilegeEmployees
          :usersPrivilegeEmployees="
            userEstablishmentPrivilegeDate.usersPrivilegeEmployees
          "
        />

        <UsersPrivilegeClients
          :usersPrivilegeClients="
            userEstablishmentPrivilegeDate.usersPrivilegeClients
          "
        />
        <UsersPrivilegeNews
          :usersPrivilegeNews="
            userEstablishmentPrivilegeDate.usersPrivilegeNews
          "
        />
        <UsersPrivilegeOffers
          :usersPrivilegeOffers="
            userEstablishmentPrivilegeDate.usersPrivilegeOffers
          "
        />
        <UsersPrivilegePlaces
          :usersPrivilegePlaces="
            userEstablishmentPrivilegeDate.usersPrivilegePlaces
          "
        />
        <UsersPrivilegePlaceSchedule
          :usersPrivilegePlaceSchedule="
            userEstablishmentPrivilegeDate.usersPrivilegePlaceSchedule
          "
        />
        <UsersPrivilegePlaceMedia
          :usersPrivilegePlaceMedia="
            userEstablishmentPrivilegeDate.usersPrivilegePlacesMedia
          "
        />
        <UsersPrivilegePlacesWorkPlans
          :usersPrivilegePlacesWorkPlans="
            userEstablishmentPrivilegeDate.usersPrivilegePlacesWorkPlans
          "
        />
        <UsersPrivilegePlacesWorkPlansDays
          :usersPrivilegePlacesWorkPlansDays="
            userEstablishmentPrivilegeDate.usersPrivilegePlacesWorkPlansDays
          "
        />

        <UsersPrivilegeTreasuries
          :usersPrivilegeTreasuries="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentTreasuries
          "
        />
        <UsersPrivilegeTreasuriesTransaction
          :usersPrivilegeTreasuriesTransaction="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentTreasuryTransactions
          "
        />
        <UsersPrivilegeExpenses
          :usersPrivilegeExpenses="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentExpenses
          "
        />
        <UsersPrivilegeRevenus
          :usersPrivilegeRevenus="
            userEstablishmentPrivilegeDate.usersPrivilegeEstablishmentRevenues
          "
        />

        <UsersPrivilegAdditionalSettings
          :usersPrivilegAdditionalSettings="
            userEstablishmentPrivilegeDate.usersPrivilegAdditionalSettings
          "
        />

        <UsersPrivilegeActivities
          :usersPrivilegeActivities="
            userEstablishmentPrivilegeDate.usersPrivilegeActivities
          "
        />
        <UsersPrivilegeTransactionPointsHistory
          :usersPrivilegeTransactionPointsHistory="
            userEstablishmentPrivilegeDate.usersPrivilegeTransactionPointsHistory
          "
        />
        <UsersPrivilegeReservations
          :usersPrivilegeReservations="
            userEstablishmentPrivilegeDate.usersPrivilegeReservations
          "
        />
        <UsersPrivilegeReservationsSchedules
          :usersPrivilegeReservationsSchedules="
            userEstablishmentPrivilegeDate.usersPrivilegeReservationsSchedules
          "
        />
        <UsersPrivilegeEnquiryReservations
          :usersPrivilegeEnquiryReservations="
            userEstablishmentPrivilegeDate.usersPrivilegeEnquiryReservations
          "
        />
        <UsersPrivilegeReservationsSchedulesPayments
          :usersPrivilegeReservationsSchedulesPayments="
            userEstablishmentPrivilegeDate.usersPrivilegeReservationsSchedulesPayments
          "
        />
        <UsersPrivilegeReservationsSchedulesCancle
          :usersPrivilegeReservationsSchedulesCancle="
            userEstablishmentPrivilegeDate.usersPrivilegeReservationsSchedulesCancle
          "
        />

        <UsersPrivilegeReports
          :usersPrivilegeReports="
            userEstablishmentPrivilegeDate.usersPrivilegeReports
          "
        />
        <UsersPrivilegeUsers
          :usersPrivilegeUsers="
            userEstablishmentPrivilegeDate.usersPrivilegeUsers
          "
        />

        <div class="form-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="updateUserEstablishmentPrivilege"
          >
            {{ submitName }}
          </button>
          <router-link to="/news" class="btn btn-cancel">
            {{ $t("cancel") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import UsersPrivilegAdditionalSettings from "@/components/privileges/usersPrivileges/UsersPrivilegAdditionalSettings.vue";
import UsersPrivilegeEmployees from "@/components/privileges/usersPrivileges/UsersPrivilegeEmployees.vue";
import UsersPrivilegeActivities from "@/components/privileges/usersPrivileges/UsersPrivilegeActivities.vue";
import UsersPrivilegeTransactionPointsHistory from "@/components/privileges/usersPrivileges/UsersPrivilegeTransactionPointsHistory.vue";
import UsersPrivilegeReservations from "@/components/privileges/usersPrivileges/UsersPrivilegeReservations.vue";
import UsersPrivilegeReservationsSchedules from "@/components/privileges/usersPrivileges/UsersPrivilegeReservationsSchedules.vue";
import UsersPrivilegeEnquiryReservations from "@/components/privileges/usersPrivileges/UsersPrivilegeEnquiryReservations.vue";
import UsersPrivilegeReservationsSchedulesPayments from "@/components/privileges/usersPrivileges/UsersPrivilegeReservationsSchedulesPayments.vue";
import UsersPrivilegeReservationsSchedulesCancle from "@/components/privileges/usersPrivileges/UsersPrivilegeReservationsSchedulesCancle.vue";
import UsersPrivilegeClients from "@/components/privileges/usersPrivileges/UsersPrivilegeClients.vue";
import UsersPrivilegeNews from "@/components/privileges/usersPrivileges/UsersPrivilegeNews.vue";
import UsersPrivilegeOffers from "@/components/privileges/usersPrivileges/UsersPrivilegeOffers.vue";
import UsersPrivilegePlaces from "@/components/privileges/usersPrivileges/UsersPrivilegePlaces.vue";
import UsersPrivilegePlaceSchedule from "@/components/privileges/usersPrivileges/UsersPrivilegePlaceSchedule.vue";
import UsersPrivilegePlaceMedia from "@/components/privileges/usersPrivileges/UsersPrivilegePlacesMedia.vue";
import UsersPrivilegePlacesWorkPlans from "@/components/privileges/usersPrivileges/UsersPrivilegePlacesWorkPlans.vue";
import UsersPrivilegePlacesWorkPlansDays from "@/components/privileges/usersPrivileges/UsersPrivilegePlacesWorkPlansDays.vue";

import UsersPrivilegeTreasuries from "@/components/privileges/usersPrivileges/UsersPrivilegeTreasuries.vue";
import UsersPrivilegeTreasuriesTransaction from "@/components/privileges/usersPrivileges/UsersPrivilegeTreasuriesTransaction.vue";
import UsersPrivilegeExpenses from "@/components/privileges/usersPrivileges/UsersPrivilegeExpenses.vue";
import UsersPrivilegeRevenus from "@/components/privileges/usersPrivileges/UsersPrivilegeRevenus.vue";
import UsersPrivilegeReports from "@/components/privileges/usersPrivileges/UsersPrivilegeReports.vue";
import UsersPrivilegeUsers from "@/components/privileges/usersPrivileges/UsersPrivilegeUsers.vue";

import UserRole from "@/models/settings/settingsOther/userRoles/UserRole";
import { USER_TYPE } from "@/utils/constants";
import { ROLE_TYPE } from "@/utils/constantLists";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EstablishmentPrivilegeForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
    UsersPrivilegeEmployees,
    UsersPrivilegeActivities,
    UsersPrivilegeTransactionPointsHistory,
    UsersPrivilegeClients,
    UsersPrivilegeNews,
    UsersPrivilegeOffers,
    UsersPrivilegeReservations,
    UsersPrivilegeReservationsSchedules,
    UsersPrivilegeReservationsSchedulesPayments,
    UsersPrivilegeReservationsSchedulesCancle,
    UsersPrivilegeEnquiryReservations,
    UsersPrivilegePlaces,
    UsersPrivilegePlaceSchedule,
    UsersPrivilegePlaceMedia,
    UsersPrivilegePlacesWorkPlans,
    UsersPrivilegePlacesWorkPlansDays,
    UsersPrivilegeTreasuries,
    UsersPrivilegeTreasuriesTransaction,
    UsersPrivilegeExpenses,
    UsersPrivilegeRevenus,
    UsersPrivilegAdditionalSettings,
    UsersPrivilegeReports,
    UsersPrivilegeUsers,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      establishmentRoleData: new UserRole(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: ["userEstablishmentPrivilegeDate", "userType", "submitName"],
  methods: {
    async changeEstablishmentRoleToken(establishmentRoleToken) {
      this.userEstablishmentPrivilegeDate.establishmentRoleToken =
        establishmentRoleToken;
      this.getUserEstablishmentPrivilege();
    },
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      this.establishmentRoleTokenOptions = [];
      this.establishmentRoleTokenOptions.push({
        value: "",
        text: this.$t("setting.others.establishmentRolesMsgs.select"),
      });
      try {
        let response =
          await this.establishmentRoleData.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken,
            this.roleTypeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.establishmentRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUserEstablishmentPrivilege() {
      this.isLoading = true;
      try {
        let response =
          await this.userEstablishmentPrivilegeDate.getUserEstablishmentPrivilege(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.userEstablishmentPrivilegeDate.fillData(
            response.data.usersEstablishmentPrivilegeData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async updateUserEstablishmentPrivilege() {
      this.$emit("updateUserEstablishmentPrivilege");
    },
  },
  async created() {
    this.getDialogOfEstablishmentRoles();
  },
  computed: {
    roleTypeToken() {
      if (this.userType == USER_TYPE.employee) {
        return ROLE_TYPE.Employee;
      } else {
        return ROLE_TYPE.Client;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
