<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="reservations.reservationsData.length != 0">
      <ReservationsTable
        :reservationsData="reservations.reservationsData"
        v-on:setReservationData="reservationData.fillData($event)"
        :reservationData="reservationData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="reservations.pagination"
        v-on:changePage="changePage"
      />

      <ReservationQRCode
        :reservationData="reservationData"
        v-on:refresh="getAllReservations()"
      />
    </div>

    <ReservationFloatBtns
      :theFilterData="reservations.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ReservationsTable from "@/components/reservations/reservations/ReservationsTable.vue";
import ReservationQRCode from "@/components/reservations/reservations/ReservationQRCode.vue";
// import ReservationCancel from "@/components/reservations/reservations/ReservationCancel.vue";
import ReservationFloatBtns from "@/components/reservations/reservations/ReservationFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Reservations from "@/models/reservations/reservations/Reservations";
import Reservation from "@/models/reservations/reservations/Reservation";

export default {
  name: "Reservations",
  components: {
    PreLoader,
    ExceptionWithImg,
    ReservationsTable,
    ReservationQRCode,
    // ReservationCancel,
    ReservationFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      reservations: new Reservations(),
      reservationData: new Reservation(),
    };
  },
  methods: {
    changePage(page) {
      this.reservations.pagination.selfPage = page;
      this.getAllReservations();
    },
    search(data) {
      this.reservations.filterData.fillData(data);
      this.reservations.reservationsData = [];
      this.getAllReservations();
    },
    async getAllReservations() {
      this.isLoading = true;
      try {
        const response = await this.reservations.reservation.getAllReservations(
          this.language,
          this.userAuthorizeToken,
          this.reservations.pagination,
          this.reservations.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.reservations.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.reservations.reservationsData = [];
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.reservations.reservationsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.reservations.reservationsData = [];
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.reservations.reservationsData = [];
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllReservations();
  },
};
</script>
