export default class UsersPrivilegeNews {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.treasuriesTransactionsReportView = false;
    this.statisticsReportView = false;
  }
  fillData(data) {
    if (data) {
      this.treasuriesTransactionsReportView =
        data.treasuriesTransactionsReportView;
      this.statisticsReportView = data.statisticsReportView;
    } else {
      this.setInitialValue();
    }
  }
}
