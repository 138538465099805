export default class UsersPrivilegePlacesMedia {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeMediaView = false;
    this.placeMediaAdd = false;
    this.placeMediaUpdate = false;
    this.approvedTypeUpdate = false;
    this.placeMediaArchive = false;
    this.placeMediaRestore = false;
    this.placeMediaDelete = false;
    this.placeMediaApproved = false;
  }
  fillData(data) {
    if (data) {
      this.placeMediaView = data.placeMediaView;
      this.placeMediaAdd = data.placeMediaAdd;
      this.placeMediaUpdate = data.placeMediaUpdate;
      this.approvedTypeUpdate = data.approvedTypeUpdate;
      this.placeMediaArchive = data.placeMediaArchive;
      this.placeMediaRestore = data.placeMediaRestore;
      this.placeMediaDelete = data.placeMediaDelete;
      this.placeMediaApproved = data.placeMediaApproved;
    } else {
      this.setInitialValue();
    }
  }
}
