<template>
  <b-modal id="UserRoleInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/user-roles.svg" class="icon-lg" />
        {{ $t("general.role.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="userRoleData.establishmentRoleNameCurrent"
        :title="$t('general.role.name')"
        :imgName="'user-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleNameAr"
        :title="$t('general.role.nameAr')"
        :imgName="'user-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleNameEn"
        :title="$t('general.role.nameEn')"
        :imgName="'user-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleNameUnd"
        :title="$t('general.role.nameUnd')"
        :imgName="'user-roles.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleDescriptionAr"
        :title="$t('general.role.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleDescriptionEn"
        :title="$t('general.role.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleDescriptionUnd"
        :title="$t('general.role.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateDMY(userRoleData.actionDate) +
          ' ' +
          timeToLang(userRoleData.actionTime)
        "
        :title="$t('general.role.actionDateTime')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="userRoleData.establishmentRoleNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateDMY,
} from "@/utils/functions";

export default {
  name: "UserRoleInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["userRoleData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    formateDateDMY(dateString, lang) {
      return formateDateDMY(dateString, lang);
    },
  },
};
</script>

<style scoped lang="scss"></style>
