<template>
  <b-modal id="PlaceScheduleAdd" scrollable centered hide-footer size="xl">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/plus.svg" class="icon-lg" />
        {{ $t("placesSchedules.add") }}
      </h3>
    </template>
    <div class="row">
      <div class="form-label-group col-6">
        <b-form-select
          v-model="placeScheduleData.placeScheduleTypeToken"
          :options="placeScheduleTypeTokenOptions"
          class="form-control"
        ></b-form-select>
        <img src="@/assets/images/type.svg" />
      </div>

      <CustomInput
        :className="'col-md-6'"
        :id="'placeScheduleCode'"
        :value="placeScheduleData.placeScheduleCode"
        :title="$t('placesSchedules.code')"
        :imgName="'number.svg'"
        v-on:changeValue="placeScheduleData.placeScheduleCode = $event"
      />
      <TimePicker
        :className="'col-md-12 col-lg-6'"
        :id="'placeScheduleTimeFrom'"
        :value="placeScheduleData.placeScheduleTimeFrom"
        :title="$t('placesSchedules.timeFrom')"
        v-on:changeValue="placeScheduleData.placeScheduleTimeFrom = $event"
        :language="language"
      />
      <TimePicker
        :className="'col-md-12 col-lg-6'"
        :id="'placeScheduleTimeTo'"
        :value="placeScheduleData.placeScheduleTimeTo"
        :title="$t('placesSchedules.timeTo')"
        v-on:changeValue="placeScheduleData.placeScheduleTimeTo = $event"
        :language="language"
      />
      <CustomInput
        :className="'col-md-12'"
        :id="'pricePerHour'"
        :value="placeScheduleData.pricePerHour"
        :title="$t('placesSchedules.pricePerHour')"
        :imgName="'money.svg'"
        v-on:changeValue="placeScheduleData.pricePerHour = $event"
      />

      <TextArea
        :className="'col-md-12'"
        :id="'placeScheduleNotes'"
        :value="placeScheduleData.placeScheduleNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
        v-on:changeValue="placeScheduleData.placeScheduleNotes = $event"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="addPlaceSchedule"
      >
        {{ $t("add") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('PlaceScheduleAdd')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PlaceSchedule from "@/models/places/placesSchedules/PlaceSchedule";
import { setDataMultiLang } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";
import TextArea from "@/components/general/TextArea.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import CustomInput from "@/components/general/CustomInput.vue";

export default {
  name: "PlaceScheduleAdd",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TextArea,
    TimePicker,
    CustomInput,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placeScheduleTypeTokenOptions: [],
      placeScheduleData: new PlaceSchedule(),
    };
  },
  methods: {
    async getPlaceScheduleTypes() {
      this.isLoading = true;
      this.placeScheduleTypeTokenOptions = [];
      this.placeScheduleTypeTokenOptions.push({
        value: "",
        text: this.$t("placesSchedules.selectType"),
      });
      let scheduleTypes =
        this.$store.getters.userData.constantsLists.listPlaceScheduleType;
      for (let item in scheduleTypes) {
        this.placeScheduleTypeTokenOptions.push({
          value: scheduleTypes[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            scheduleTypes[item]["itemNameAr"],
            scheduleTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async addPlaceSchedule() {
      this.isLoading = true;
      this.placeScheduleData.placeWorkPlanDayToken =
        this.placesWorkPlanDayData.placeWorkPlanDayToken;
      try {
        let response = await this.placeScheduleData.addOrUpdatePlaceSchedule(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("PlaceScheduleAdd");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  props: ["placesWorkPlanDayData"],
  computed: {},
  async created() {
    this.getPlaceScheduleTypes();
  },
};
</script>

<style scoped lang="scss"></style>
