<template>
  <b-modal id="PlacesWorkPlanInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/places-work-plans.svg" class="icon-lg" />
        {{ $t("placesWorkPlans.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanCode"
        :title="$t('placesWorkPlans.placeWorkPlanCode')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanNameAr"
        :title="$t('placesWorkPlans.nameAr')"
        :imgName="'places-work-plans.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanNameEn"
        :title="$t('placesWorkPlans.nameEn')"
        :imgName="'places-work-plans.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanNameUnd"
        :title="$t('placesWorkPlans.nameUnd')"
        :imgName="'places-work-plans.svg'"
      />

      <DataLabelGroup
        :className="'col-md-12'"
        :value="placesWorkPlanData.placeNameCurrent"
        :title="$t('places.name')"
        :imgName="'places.svg'"
      />

      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="
          formateDateDMY(placesWorkPlanData.startActiveDate) +
          ' ' +
          timeToLang(placesWorkPlanData.startActiveTime)
        "
        :title="$t('placesWorkPlans.startActiveDateTime')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="
          formateDateDMY(placesWorkPlanData.endActiveDate) +
          ' ' +
          timeToLang(placesWorkPlanData.endActiveTime)
        "
        :title="$t('placesWorkPlans.endActiveDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanDescriptionEn"
        :title="$t('placesWorkPlans.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanDescriptionAr"
        :title="$t('placesWorkPlans.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanDescriptionUnd"
        :title="$t('placesWorkPlans.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12 col-lg-6'"
        :value="placesWorkPlanData.placeWorkPlanNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-md-12'"
        :value="
          formateDateDMY(placesWorkPlanData.actionDate) +
          ' ' +
          timeToLang(placesWorkPlanData.actionTime)
        "
        :title="$t('general.role.actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateDMY,
} from "@/utils/functions";

export default {
  name: "PlacesWorkPlanInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["placesWorkPlanData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    formateDateDMY(date, lang = this.language) {
      return formateDateDMY(date, lang);
    },
  },
  async created() {},
};
</script>

<style scoped lang="scss"></style>
