<template>
  <div class="">
    <b-button v-b-toggle.places class="btn btn-lg btn-collapse">
      {{ $t("places.places") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="places">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeView"
              v-model="usersPrivilegePlaces.placeView"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeView">{{ $t("view") }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeAdd"
              v-model="usersPrivilegePlaces.placeAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeAdd">{{ $t("add") }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeUpdate"
              v-model="usersPrivilegePlaces.placeUpdate"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeUpdate">{{ $t("edit") }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeArchive"
              v-model="usersPrivilegePlaces.placeArchive"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeDelete"
              v-model="usersPrivilegePlaces.placeDelete"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeRestore"
              v-model="usersPrivilegePlaces.placeRestore"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegePlaces",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegePlaces"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
