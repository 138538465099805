<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="placesMedias.placesMediasData">
      <div class="row">
        <PlaceMediaCard
          v-for="(media, index) in placesMedias.placesMediasData"
          :key="index"
          :placeMediaData="media"
          v-on:setPlaceMediaData="placeMediaData.fillData($event)"
        />
      </div>

      <Pagination
        v-if="!isLoading"
        :paginationData="placesMedias.pagination"
        v-on:changePage="changePage"
      />

      <!-- <PlaceMediaInfo :placeMediaData="placeMediaData" /> -->
      <PlaceMediaDelete
        :placeMediaData="placeMediaData"
        v-on:refresh="getAllPlacesMedias()"
      />
    </div>

    <PlaceMediaFloatBtns
      :theFilterData="placesMedias.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PlaceMediaCard from "@/components/places/placesMedias/PlaceMediaCard.vue";
// import PlaceMediaInfo from "@/components/places/placesMedias/PlaceMediaInfo.vue";
import PlaceMediaDelete from "@/components/places/placesMedias/PlaceMediaDelete.vue";
import PlaceMediaFloatBtns from "@/components/places/placesMedias/PlaceMediaFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import PlacesMedias from "@/models/places/placesMedias/PlacesMedias";
import PlaceMedia from "@/models/places/placesMedias/PlaceMedia";

export default {
  name: "PlacesMedias",
  components: {
    PreLoader,
    ExceptionWithImg,
    PlaceMediaCard,
    // PlaceMediaInfo,
    PlaceMediaDelete,
    PlaceMediaFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      placesMedias: new PlacesMedias(),
      placeMediaData: new PlaceMedia(),
    };
  },
  methods: {
    changePage(page) {
      this.placesMedias.pagination.selfPage = page;
      this.getAllPlacesMedias();
    },
    search(data) {
      this.placesMedias.filterData.fillData(data);
      this.placesMedias.placesMediasData = null;
      this.getAllPlacesMedias();
    },
    async getAllPlacesMedias() {
      this.isLoading = true;
      try {
        const response = await this.placesMedias.placeMedia.getAllPlacesMedias(
          this.language,
          this.userAuthorizeToken,
          this.placesMedias.pagination,
          this.placesMedias.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.placesMedias.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.placesMedias.placesMediasData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.placesMedias.placesMediasData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placesMedias.placesMediasData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.placesMedias.placesMediasData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.placesMedias.filterData.placeToken = this.$route.params.placeToken;
    this.getAllPlacesMedias();
  },
};
</script>
