<template>
  <b-modal id="PaymentMethodeInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/method-paids.svg" class="icon-lg" />
        {{ $t("methodPaidData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeNameAr"
        :title="$t('nameAr')"
        :imgName="'method-paids.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeNameEn"
        :title="$t('nameEn')"
        :imgName="'method-paids.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeNameUnd"
        :title="$t('nameUnd')"
        :imgName="'method-paids.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeDescriptionEn"
        :title="$t('descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeDescriptionAr"
        :title="$t('descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentMethodeData.paymentMethodeDescriptionUnd"
        :title="$t('descriptionUnd')"
        :imgName="'description.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="paymentMethodeData.paymentMethodeNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { setDataMultiLang, timeToLang } from "@/utils/functions";

export default {
  name: "PaymentMethodeInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["paymentMethodeData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
