import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class ExpenseType {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.expensesTypeToken = "";
    this.expensesTypeNameCurrent = "";
    this.expensesTypeNameEn = "";
    this.expensesTypeNameAr = "";
    this.expensesTypeNameUnd = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.expensesTypeDescriptionCurrent = "";
    this.expensesTypeDescriptionEn = "";
    this.expensesTypeDescriptionAr = "";
    this.expensesTypeDescriptionUnd = "";
    this.expensesTypeNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.expensesTypeArchiveStatus = "";
  }
  fillData(data) {
    this.expensesTypeToken = data.expensesTypeToken;
    this.expensesTypeNameCurrent = data.expensesTypeNameCurrent;
    this.expensesTypeNameEn = data.expensesTypeNameEn;
    this.expensesTypeNameAr = data.expensesTypeNameAr;
    this.expensesTypeNameUnd = data.expensesTypeNameUnd;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.expensesTypeDescriptionCurrent = data.expensesTypeDescriptionCurrent;
    this.expensesTypeDescriptionEn = data.expensesTypeDescriptionEn;
    this.expensesTypeDescriptionAr = data.expensesTypeDescriptionAr;
    this.expensesTypeDescriptionUnd = data.expensesTypeDescriptionUnd;
    this.expensesTypeNotes = data.expensesTypeNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.expensesTypeArchiveStatus = data.expensesTypeArchiveStatus;
  }

  async getAllExpensesTypes(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/ExpensesTypes/GetAllExpensesTypes/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getExpenseTypeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/ExpensesTypes/GetExpensesTypeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&expensesTypeToken=${this.expensesTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfExpensesTypes(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/ExpensesTypes/GetDialogOfExpensesTypes?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateExpenseType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      expensesTypeToken: this.expensesTypeToken,
      expensesTypeNameCurrent: this.expensesTypeNameCurrent,
      expensesTypeNameEn: this.expensesTypeNameEn,
      expensesTypeNameAr: this.expensesTypeNameAr,
      expensesTypeNameUnd: this.expensesTypeNameUnd,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      expensesTypeDescriptionCurrent: this.expensesTypeDescriptionCurrent,
      expensesTypeDescriptionEn: this.expensesTypeDescriptionEn,
      expensesTypeDescriptionAr: this.expensesTypeDescriptionAr,
      expensesTypeDescriptionUnd: this.expensesTypeDescriptionUnd,
      expensesTypeNotes: this.expensesTypeNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      expensesTypeArchiveStatus: this.expensesTypeArchiveStatus,
    };

    try {
      let response = "";
      if (this.expensesTypeToken == "" || this.expensesTypeToken == undefined) {
        response = await axios.post(`/ExpensesTypes/AddExpensesType`, data);
      } else {
        response = await axios.post(`/ExpensesTypes/UpdateExpensesType`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveExpenseType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      expensesTypeToken: this.expensesTypeToken,
    };

    try {
      let response = await axios.post(
        `/ExpensesTypes/ArchiveExpensesType`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
