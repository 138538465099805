export default class UsersPrivilegeEnquiryReservations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.enquiryReservationsView = false;
    this.enquiryReservationsAdd = false;
    this.enquiryReservationsDelete = false;
  }
  fillData(data) {
    if (data) {
      this.enquiryReservationsView = data.enquiryReservationsView;
      this.enquiryReservationsAdd = data.enquiryReservationsAdd;
      this.enquiryReservationsDelete = data.enquiryReservationsDelete;
    } else {
      this.setInitialValue();
    }
  }
}
