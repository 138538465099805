<template>
  <div class="">
    <div v-if="this.$route.name === pageName">
      <PreLoader v-if="isLoading" />
      <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

      <div v-if="activitiesData">
        <div class="row">
          <ActivityCard
            v-for="activity in activitiesData"
            :key="activity.activityToken"
            :activityData="activity"
            v-on:setActivityData="activityData.fillData($event)"
          />
        </div>
        <Pagination
          v-if="!isLoading"
          :paginationData="paginationData"
          v-on:changePage="changePage"
        />
        <ActivityInfo :activityData="activityData" />
        <ActivityDelete
          :activityData="activityData"
          v-on:refresh="getAllActivities()"
        />
      </div>

      <ActivityFloatBtns :textSearch="textSearch" v-on:search="search" />
    </div>
    <router-view v-else :key="$router.path" />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import ActivityCard from "@/components/settings/settingsOther/activities/ActivityCard.vue";
import ActivityInfo from "@/components/settings/settingsOther/activities/ActivityInfo.vue";
import ActivityDelete from "@/components/settings/settingsOther/activities/ActivityDelete.vue";
import ActivityFloatBtns from "@/components/settings/settingsOther/activities/ActivityFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Activity from "@/models/settings/settingsOther/activities/Activity";

export default {
  name: "Activities",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActivityCard,
    ActivityInfo,
    ActivityDelete,
    ActivityFloatBtns,
    Pagination,
  },
  data() {
    return {
      pageName: "Activities",
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      activitiesData: null,
      activityData: new Activity(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllActivities();
    },
    search(text) {
      this.textSearch = text;

      this.getAllActivities();
    },
    async getAllActivities() {
      this.isLoading = true;
      try {
        const response = await this.activityData.getAllActivities(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.activitiesData = response.data.activitiesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.activitiesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.activitiesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.activitiesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.activitiesData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllActivities();
  },
};
</script>
