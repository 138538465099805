<template>
  <b-modal id="PaymentInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/money.svg" class="icon-lg" />
        {{ $t("paymentData") }}
      </h3>
    </template>

    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.payCode"
        :title="$t('payments.payCode')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.placeNameCurrent"
        :title="$t('placeName')"
        :imgName="'places.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.activityNameCurrnet"
        :title="$t('activities.name')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.reservationScheduleCode"
        :title="$t('payments.reservationScheduleCode')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.reservationScheduleNameCurrent"
        :title="$t('payments.reservationScheduleName')"
        :imgName="'work-schedule.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.remainingAmount + $t('EGP')"
        :title="$t('remainingAmount')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.payUserNameCurrent"
        :title="$t('payments.payUserName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.clientCode"
        :title="$t('clientCode')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.clientNameCurrent"
        :title="$t('clientName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.clientPhoneWithCC"
        :title="$t('phoneNumber')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.methodPaidNameCurrent"
        :title="$t('methodPaidName')"
        :imgName="'method-paids.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.methodPaidValue + $t('EGP')"
        :title="$t('payments.methodPaidValue')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.onePointEqualNumberMoneyValue"
        :title="$t('payments.onePointEqualNumberMoneyValue')"
        :imgName="'number.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="paymentData.pointsValue + $t('EGP')"
        :title="$t('payments.pointsValue')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="paymentData.paidActual + $t('EGP')"
        :title="$t('payments.paidActual')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="paymentData.payNote"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="formateDateTimeLang(paymentData.payDate, paymentData.payTime)"
        :title="$t('dateTime')"
        :imgName="'time.svg'"
      />
      <template v-if="paymentData.refundStatus">
        <DataLabelGroup
          :className="'col-lg-6'"
          :value="paymentData.refundUserNameCurrent"
          :title="$t('payments.refundUserName')"
          :imgName="'man.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-6'"
          :value="paymentData.refundReasonEn"
          :title="$t('payments.refundReasonEn')"
          :imgName="'reason.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-6'"
          :value="paymentData.refundReasonAr"
          :title="$t('payments.refundReasonAr')"
          :imgName="'reason.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-6'"
          :value="paymentData.refundReasonUnd"
          :title="$t('payments.refundReasonUnd')"
          :imgName="'reason.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-12'"
          :value="paymentData.refundNotes"
          :title="$t('notes')"
          :imgName="'notes.svg'"
        />
      </template>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  timeToLang,
  isDataExist,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "PaymentInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["paymentData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
