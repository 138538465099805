<template>
  <div v-if="transactionPointsHistoryData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("transactionPoints.code") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("transactionPoints.type") }}</th>
            <th>{{ $t("transactionPoints.value") }}</th>
            <th>{{ $t("transactionPoints.oldValue") }}</th>
            <th>{{ $t("transactionPoints.newValue") }}</th>
            <!-- <th>{{ $t("notes") }}</th>
            <th>{{ $t("userMakeAction") }}</th> -->
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("info") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(point, index) in transactionPointsHistoryData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(point.transactionPointsHistoryCode) }}</td>
            <td>{{ isDataExist(point.clientNameCurrent) }}</td>
            <td>{{ isDataExist(point.transactionPointsTypeNameCurrent) }}</td>
            <td>{{ isDataExist(point.transactionPointsValue) }}</td>
            <td>{{ isDataExist(point.transactionPointsOldValue) }}</td>
            <td>{{ isDataExist(point.transactionPointsNewValue) }}</td>
            <!-- <td>{{ isDataExist(point.notes) }}</td>
            <td>{{ isDataExist(point.userMakeActionNameCurrent) }}</td> -->
            <td>
              {{ formateDateTimeLang(point.actionDate, point.actionTime) }}
            </td>
            <td>
              <button
                v-b-modal.TransactionPointInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setTransactionPointData(point)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isDataExist, formateDateTimeLang } from "@/utils/functions";

export default {
  name: "TransactionPointsTable",
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["transactionPointsHistoryData", "transactionPointData"],
  methods: {
    setTransactionPointData(transactionPointData) {
      this.$emit("setTransactionPointData", transactionPointData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
