<template>
  <b-modal id="PlaceInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/places.svg" class="icon-lg" />
        {{ $t("places.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeCode"
        :title="$t('places.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeNameAr"
        :title="$t('places.nameAr')"
        :imgName="'places.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeNameEn"
        :title="$t('places.nameEn')"
        :imgName="'places.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeNameUnd"
        :title="$t('places.nameUnd')"
        :imgName="'places.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.activityNameCurrent"
        :title="$t('activities.name')"
        :imgName="'places.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeAddressAr"
        :title="$t('places.addressAr')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeAddressEn"
        :title="$t('places.addressEn')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeAddressUnd"
        :title="$t('places.addressUnd')"
        :imgName="'address.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.countryNameCurrent"
        :title="$t('country')"
        :imgName="'country.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.governorateNameCurrent"
        :title="$t('governate')"
        :imgName="'governate.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.cityNameCurrent"
        :title="$t('city')"
        :imgName="'city.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.districtNameCurrent"
        :title="$t('district')"
        :imgName="'district.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.minimumReservationTime"
        :title="$t('places.minimumReservationTime')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.maximumReservationTime"
        :title="$t('places.maximumReservationTime')"
        :imgName="'time.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="placeData.mapLink"
        :title="$t('places.mapLink')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeLatitude"
        :title="$t('places.placeLatitude')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeLongitude"
        :title="$t('places.placelongitude')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeEndLatitude"
        :title="$t('places.placeEndLatitude')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeEndLongitude"
        :title="$t('places.placeEndlongitude')"
        :imgName="'address.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6 phone-number'"
        :value="placeData.placePhone1WithCC"
        :title="$t('places.phone1')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6 phone-number'"
        :value="placeData.placePhone2WithCC"
        :title="$t('places.phone2')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6 phone-number'"
        :value="placeData.placePhone3WithCC"
        :title="$t('places.phone3')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6 phone-number'"
        :value="placeData.placePhone4WithCC"
        :title="$t('places.phone4')"
        :imgName="'phone.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeDescriptionAr"
        :title="$t('places.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeDescriptionEn"
        :title="$t('places.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeDescriptionUnd"
        :title="$t('places.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placeData.placeActiveStatus === true ? $t('yes') : $t('no')"
        :title="$t('general.activeStatus')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-md-12'"
        :value="
          formateDateDMY(placeData.actionDate) +
          ' ' +
          timeToLang(placeData.actionTime)
        "
        :title="$t('countries.actionDateTime')"
        :imgName="'time.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateDMY,
} from "@/utils/functions";

export default {
  name: "PlaceInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["placeData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
    formateDateDMY(date, lang = this.language) {
      return formateDateDMY(date, lang);
    },
  },
};
</script>

<style scoped lang="scss"></style>
