export default class UsersPrivilegeReservationsSchedulesPayments {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationsSchedulesPaymentsView = false;
    this.reservationsSchedulesPaymentsAdd = false;
    this.reservationsSchedulesPaymentsRefound = false;
  }
  fillData(data) {
    if (data) {
      this.reservationsSchedulesPaymentsView =
        data.reservationsSchedulesPaymentsView;
      this.reservationsSchedulesPaymentsAdd =
        data.reservationsSchedulesPaymentsAdd;
      this.reservationsSchedulesPaymentsRefound =
        data.reservationsSchedulesPaymentsRefound;
    } else {
      this.setInitialValue();
    }
  }
}
