export default class UsersPrivilegePlacesWorkPlans {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placesWorkPlansView = false;
    this.placesWorkPlansAdd = false;
    this.placesWorkPlansUpdate = false;
    this.placesWorkPlansArchive = false;
    this.placesWorkPlansRestore = false;
    this.placesWorkPlansDelete = false;
  }
  fillData(data) {
    if (data) {
      this.placesWorkPlansView = data.placesWorkPlansView;
      this.placesWorkPlansAdd = data.placesWorkPlansAdd;
      this.placesWorkPlansUpdate = data.placesWorkPlansUpdate;
      this.placesWorkPlansArchive = data.placesWorkPlansArchive;
      this.placesWorkPlansRestore = data.placesWorkPlansRestore;
      this.placesWorkPlansDelete = data.placesWorkPlansDelete;
    } else {
      this.setInitialValue();
    }
  }
}
