<template>
  <div class="home">
    <LoginForm :userType="userType" :pageName="$t('employeeLogin')" />
  </div>
</template>

<script>
import LoginForm from "@/components/user/LoginForm.vue";
import { USER_TYPE } from "@/utils/constants";

export default {
  name: "EmployeeLogin",
  components: {
    LoginForm,
  },
  data() {
    return {
      userType: USER_TYPE.employee,
    };
  },
};
</script>
