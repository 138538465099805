export default class UsersPrivilegePlaces {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeView = false;
    this.placeAdd = false;
    this.placeUpdate = false;
    this.placeArchive = false;
    this.placeRestore = false;
    this.placeDelete = false;
  }
  fillData(data) {
    if (data) {
      this.placeView = data.placeView;
      this.placeAdd = data.placeAdd;
      this.placeUpdate = data.placeUpdate;
      this.placeArchive = data.placeArchive;
      this.placeRestore = data.placeRestore;
      this.placeDelete = data.placeDelete;
    } else {
      this.setInitialValue();
    }
  }
}
