<template>
  <b-modal id="ReservationSchedulePay" scrollable centered hide-footer>
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/money.svg" class="icon-lg" />
        {{ $t("reservations.pay") }}
      </h3>
    </template>

    <form autocomplete="off" method="post">
      <div class="row">
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'methodPaidToken'"
          :value="reservationSchedulesPaymentData.methodPaidToken"
          :options="methodPaidTokenOptions"
          v-on:changeValue="
            reservationSchedulesPaymentData.methodPaidToken = $event
          "
          :title="$t('methodPaidSelect')"
          :imgName="'method-paids.svg'"
        />
        <CustomInputFloat
          :className="'col-md-12'"
          :id="'methodPaidValue'"
          :value="reservationSchedulesPaymentData.methodPaidValue"
          v-on:changeValue="
            reservationSchedulesPaymentData.methodPaidValue = $event
          "
          :title="$t('methodPaidValue')"
          :imgName="'money.svg'"
        />

        <CustomInputFloat
          :className="'col-md-12'"
          :id="'pointsValue'"
          :value="reservationSchedulesPaymentData.pointsValue"
          v-on:changeValue="
            reservationSchedulesPaymentData.pointsValue = $event
          "
          :title="$t('reservations.pointsValue')"
          :imgName="'money.svg'"
        />
        <CustomInputFloat
          :className="'col-md-12'"
          :id="'onePointEqualNumberMoneyValue'"
          :value="reservationSchedulesPaymentData.onePointEqualNumberMoneyValue"
          v-on:changeValue="
            reservationSchedulesPaymentData.onePointEqualNumberMoneyValue =
              $event
          "
          :title="$t('reservations.onePointEqualValue')"
          :imgName="'points.svg'"
        />

        <TextArea
          :className="'col-md-12'"
          :id="'approveNotes'"
          :value="reservationSchedulesPaymentData.approveNotes"
          v-on:changeValue="
            reservationSchedulesPaymentData.approveNotes = $event
          "
          :title="$t('notes')"
          :imgName="'notes.svg'"
        />
      </div>

      <div class="modal-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="newPayment"
        >
          {{ $t("ok") }}
        </button>
        <button
          name="submit"
          type="submit"
          class="btn btn-cancel"
          @click.prevent="$bvModal.hide('ReservationSchedulePay')"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import PaymentMethode from "@/models/settings/settingsOther/paymentMethodes/PaymentMethode";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ReservationSchedulePay",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInputFloat,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      methodPaidData: new PaymentMethode(),
      methodPaidTokenOptions: [],
    };
  },
  methods: {
    async newPayment() {
      this.isLoading = true;
      try {
        let response = await this.reservationSchedulesPaymentData.newPayment(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("ReservationSchedulePay");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        console.log(e);
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfPaymentMethodes() {
      this.isLoading = true;
      this.methodPaidTokenOptions = [];
      this.methodPaidTokenOptions.push({
        value: "",
        text: this.$t("methodPaidSelect"),
      });
      try {
        let response = await this.methodPaidData.getDialogOfPaymentMethodes(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.methodPaidTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  props: ["reservationSchedulesPaymentData"],
  computed: {},
  async created() {
    this.getDialogOfPaymentMethodes();
  },
};
</script>

<style scoped lang="scss"></style>
