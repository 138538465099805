<template>
  <div v-if="paymentsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("payments.payCode") }}</th>
            <th>{{ $t("placeName") }}</th>
            <th>{{ $t("activities.name") }}</th>
            <!-- <th>{{ $t("payments.reservationScheduleCode") }}</th> -->
            <th>{{ $t("payments.reservationScheduleName") }}</th>
            <th>{{ $t("payments.paidActual") }}</th>
            <th>{{ $t("remainingAmount") }}</th>
            <th>{{ $t("payments.payUserName") }}</th>
            <th>{{ $t("clientCode") }}</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("dateTime") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("refund") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paymentsData" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.payCode) }}</td>
            <td>{{ isDataExist(item.placeNameCurrent) }}</td>
            <td>{{ isDataExist(item.activityNameCurrnet) }}</td>
            <!-- <td>{{ isDataExist(item.reservationScheduleCode) }}</td> -->
            <td>{{ isDataExist(item.reservationScheduleNameCurrent) }}</td>
            <td>{{ moneyFormat(item.paidActual) }}</td>
            <td>{{ moneyFormat(item.remainingAmount) }}</td>
            <td>{{ isDataExist(item.payUserNameCurrent) }}</td>
            <td>{{ isDataExist(item.clientCode) }}</td>
            <td>{{ isDataExist(item.clientNameCurrent) }}</td>
            <td>
              {{ formateDateTimeLang(item.payDate, item.payTime) }}
            </td>
            <td>
              <button
                v-b-modal.PaymentInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setPaymentData(item)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td v-if="!item.refundStatus">
              <button
                v-b-modal.PaymentRefund
                class="btn p-0"
                :title="$t('refund')"
                @click="setPaymentData(item)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
            </td>
            <td v-else>{{ $t("refundDone") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";

export default {
  name: "PaymentsTable",
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["paymentsData", "paymentData"],
  methods: {
    setPaymentData(paymentData) {
      this.$emit("setPaymentData", paymentData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
