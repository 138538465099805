<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <PlacesWorkPlanForm
      v-if="!isLoading"
      :placesWorkPlanData="placesWorkPlanData"
      :submitName="$t('add')"
      v-on:addOrUpdatePlacesWorkPlan="addOrUpdatePlacesWorkPlan"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import PlacesWorkPlanForm from "@/components/places/placesWorkPlans/PlacesWorkPlanForm.vue";
import PlacesWorkPlan from "@/models/places/placesWorkPlans/PlacesWorkPlan";

export default {
  name: "PlacesWorkPlanAdd",
  mixins: [createToastMixin],
  components: {
    PlacesWorkPlanForm,
  },
  data() {
    return {
      isLoading: false,
      placesWorkPlanData: new PlacesWorkPlan(),
    };
  },
  methods: {
    async addOrUpdatePlacesWorkPlan() {
      this.isLoading = true;
      try {
        let response = await this.placesWorkPlanData.addOrUpdatePlacesWorkPlan(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.placesWorkPlanData.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {},
};
</script>

<style scoped lang="scss"></style>
