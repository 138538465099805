export default class UsersPrivilegeReservations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationsView = false;
    this.reservationsAdd = false;
  }
  fillData(data) {
    if (data) {
      this.reservationsView = data.reservationsView;
      this.reservationsAdd = data.reservationsAdd;
    } else {
      this.setInitialValue();
    }
  }
}
