import {
  STATUS,
  PAGE_SIZE,
  NEWS_MEDIA_TYPE_TOKEN_IMAGE,
} from "@/utils/constants";
import i18n from "@/i18n/index.js";
import axios from "axios";

export default class PlaceMedia {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.media = "";
    this.placeMediaToken = "";
    this.placeMediaNameEn = "";
    this.placeMediaNameAr = "";
    this.placeMediaNameUnd = "";
    this.placeMediaNameCurrent = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placeMediaTypeToken = NEWS_MEDIA_TYPE_TOKEN_IMAGE;
    this.placeMediaTypeNameCurrent = "";
    this.placeMediaTypeNameEn = "";
    this.placeMediaTypeNameAr = "";
    this.placeMediaTypeNameUnd = "";
    this.placeMediaPath = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.approvedTypeToken = "";
    this.approvedTypeNameCurrent = "";
    this.approvedTypeNameEn = "";
    this.approvedTypeNameAr = "";
    this.approvedTypeNameUnd = "";
    this.employeeApprovedToken = "";
    this.employeeApprovedNameCurrent = "";
    this.contractorApprovedToken = "";
    this.contractorApprovedNameCurrent = "";
    this.approvedDateTime = "";
    this.approvedDate = "";
    this.approvedTime = "";
    this.placeMediaArchiveStatus = "";
  }
  fillData(data) {
    this.placeMediaToken = data.placeMediaToken;
    this.placeMediaNameEn = data.placeMediaNameEn;
    this.placeMediaNameAr = data.placeMediaNameAr;
    this.placeMediaNameUnd = data.placeMediaNameUnd;
    this.placeMediaNameCurrent = data.placeMediaNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placeMediaTypeToken = data.placeMediaTypeToken;
    this.placeMediaTypeNameCurrent = data.placeMediaTypeNameCurrent;
    this.placeMediaTypeNameEn = data.placeMediaTypeNameEn;
    this.placeMediaTypeNameAr = data.placeMediaTypeNameAr;
    this.placeMediaTypeNameUnd = data.placeMediaTypeNameUnd;
    this.placeMediaPath = data.placeMediaPath;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.approvedTypeToken = data.approvedTypeToken;
    this.approvedTypeNameCurrent = data.approvedTypeNameCurrent;
    this.approvedTypeNameEn = data.approvedTypeNameEn;
    this.approvedTypeNameAr = data.approvedTypeNameAr;
    this.approvedTypeNameUnd = data.approvedTypeNameUnd;
    this.employeeApprovedToken = data.employeeApprovedToken;
    this.employeeApprovedNameCurrent = data.employeeApprovedNameCurrent;
    this.contractorApprovedToken = data.contractorApprovedToken;
    this.contractorApprovedNameCurrent = data.contractorApprovedNameCurrent;
    this.approvedDateTime = data.approvedDateTime;
    this.approvedDate = data.approvedDate;
    this.approvedTime = data.approvedTime;
    this.placeMediaArchiveStatus = data.placeMediaArchiveStatus;
  }

  async getAllPlacesMedias(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    if (filterData.placeToken == "" || filterData.placeToken == undefined) {
      let response = {
        data: {
          status: STATUS.ERROR,
          msg: i18n.t("placesMedias.pleaseSelectPlace"),
          data: "",
        },
      };
      return response;
    }
    try {
      let response = await axios.get(
        `/PlacesMedia/GetAllPlacesMedia?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&placeToken=${filterData.placeToken}&placeMediaToken=${filterData.placeMediaToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getPlaceMediaDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesMedia/GetPlaceMediaDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placeMediaToken=${this.placeMediaToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async getDialogOfPlacesMedia(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesMedia/GetDialogOfPlacesMedia?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePlaceMedia(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeToken: this.placeToken,
      placeMediaTypeToken: this.placeMediaTypeToken,
      placeMediaNameAr: this.placeMediaNameAr,
      placeMediaNameEn: this.placeMediaNameEn,
      placeMediaNameUnd: this.placeMediaNameUnd,
    };

    if (this.media != "" && this.media != undefined) {
      var formData = new FormData();
      formData.append("media", this.media);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.placeMediaToken == "" || this.placeMediaToken == undefined) {
        if (this.media != "" && this.media != undefined) {
          response = await axios.post(`/PlacesMedia/AddPlaceMedia`, formData);
        } else {
          response = await axios.post(
            `/PlacesMedia/AddPlaceMediaWithoutMedia`,
            data
          );
        }
      } else {
        if (this.media != "" && this.media != undefined) {
          response = await axios.post(
            `/PlacesMedia/UpdatePlaceMedia`,
            formData
          );
        } else {
          response = await axios.post(
            `/PlacesMedia/UpdatePlaceMediaWithoutMedia`,
            data
          );
        }
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePlaceMedia(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeMediaToken: this.placeMediaToken,
    };

    try {
      let response = await axios.post(`/PlacesMedia/ArchivePlaceMedia`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
