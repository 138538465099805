export default class UsersPrivilegePlaceSchedule {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeScheduleView = false;
    this.placeScheduleAdd = false;
    this.placeScheduleUpdate = false;
    this.placeApprovedTypeUpdate = false;
    this.placeScheduleArchive = false;
    this.placeScheduleRestore = false;
    this.placeScheduleDelete = false;
    this.placeScheduleApproved = false;
  }
  fillData(data) {
    if (data) {
      this.placeScheduleView = data.placeScheduleView;
      this.placeScheduleAdd = data.placeScheduleAdd;
      this.placeScheduleUpdate = data.placeScheduleUpdate;
      this.placeApprovedTypeUpdate = data.placeApprovedTypeUpdate;
      this.placeScheduleArchive = data.placeScheduleArchive;
      this.placeScheduleRestore = data.placeScheduleRestore;
      this.placeScheduleDelete = data.placeScheduleDelete;
      this.placeScheduleApproved = data.placeScheduleApproved;
    } else {
      this.setInitialValue();
    }
  }
}
