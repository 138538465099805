<template>
  <div class="home">
    <LoginForm
      :userType="userType"
      :pageName="$t('clientLogin')"
      :signUpStatus="true"
    />
  </div>
</template>

<script>
import LoginForm from "@/components/user/LoginForm.vue";
import { USER_TYPE } from "@/utils/constants";

export default {
  name: "ClientLogin",
  components: {
    LoginForm,
  },
  data() {
    return {
      userType: USER_TYPE.client,
    };
  },
};
</script>
