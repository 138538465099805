<template>
  <b-modal id="ReservationQRCode" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/reservations.svg" class="icon-lg" />
        {{ $t("reservations.name") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="reservationData.reservationCode"
        :title="$t('reservations.code')"
        :imgName="'number.svg'"
      />
    </div>
    <div class="img-info">
      <qrcode-vue
        :value="reservationData.reservationCode"
        :size="300"
        level="H"
        render-as="svg"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { isDataExist } from "@/utils/functions";
import QrcodeVue from "qrcode.vue";

export default {
  name: "ReservationQRCode",
  components: {
    DataLabelGroup,
    QrcodeVue,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["reservationData"],
  methods: {
    isDataExist(data) {
      return isDataExist(data);
    },
  },
};
</script>

<style scoped lang="scss"></style>
