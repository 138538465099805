import axios from "axios";

export default class ReservationSchedule {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationScheduleToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.reservationToken = "";
    this.reservationNameCurrent = "";
    this.reservationScheduleCode = "";
    this.reservationScheduleNameAr = "";
    this.reservationScheduleNameEn = "";
    this.reservationScheduleNameUnd = "";
    this.reservationScheduleStatusToken = "";
    this.reservationScheduleStatusNameCurrent = "";
    this.reservationScheduleStatusNameAr = "";
    this.reservationScheduleStatusNameEn = "";
    this.reservationScheduleStatusNameUnd = "";
    this.reservationScheduleNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.reservationScheduleArchiveStatus = "";
    this.requestDataUserToken = "";
    this.requestDataUserNameCurrent = "";
    this.requestDataDatetime = "";
    this.requestDataDate = "";
    this.requestDataTime = "";
    this.approveDataDatetime = "";
    this.approveDataDate = "";
    this.approveDataTime = "";
    this.approveUserToken = "";
    this.approveUserNameCurrent = "";
    this.approveNotes = "";
    this.clientToken = "";
    this.clientNameCurrent = "";
    this.clientNameAr = "";
    this.clientNameEn = "";
    this.clientNameUnd = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placeNameAr = "";
    this.placeNameEn = "";
    this.placeNameUnd = "";
    this.activityToken = "";
    this.activityNameCurrent = "";
    this.activityNameAr = "";
    this.activityNameEn = "";
    this.activityNameUnd = "";
    this.placeWorkPlanDayToken = "";
    this.dayToken = "";
    this.dayNameCurrent = "";
    this.dayNameAr = "";
    this.dayNameEn = "";
    this.dayNameUnd = "";
    this.dateTimeFrom = "";
    this.dateFrom = "";
    this.timeFrom = "";
    this.dateTimeTo = "";
    this.dateTo = "";
    this.timeTo = "";
    this.placeWorkPlanToken = "";
    this.placeWorkPlanNameCurrent = "";
    this.placeWorkPlanNameEn = "";
    this.placeWorkPlanNameAr = "";
    this.placeWorkPlanNameUnd = "";
    this.placeScheduleToken = "";
    this.placeScheduleNameCurrent = "";
    this.placeScheduleNameEn = "";
    this.placeScheduleNameAr = "";
    this.placeScheduleNameUnd = "";
    this.placeScheduleTimeFrom = "";
    this.placeScheduleTimeTo = "";
    this.placeScheduleDuration = "";
    this.pricePerHour = "";
    this.placeScheduleTypeToken = "";
    this.placeScheduleTypeNameCurrent = "";
    this.placeScheduleTypeNameAr = "";
    this.placeScheduleTypeNameEn = "";
    this.placeScheduleTypeNameUnd = "";
    this.feesTypeToken = "";
    this.feesTypeNameCurrent = "";
    this.feesTypeNameAr = "";
    this.feesTypeNameEn = "";
    this.feesTypeNameUnd = "";
    this.feesTypeValue = "";
    this.totalPaid = "";
    this.actualPrice = "";
    this.remainingAmount = "";
    this.refoundMethodPaidToken = "";
    this.refoundMethodPaidNameCurrent = "";
    this.refoundReasonAr = "";
    this.refoundReasonEn = "";
    this.refoundReasonUnd = "";
    this.refundPercentage = "";
    this.refoundValue = "";
    this.totalPaidWithRefound = "";
    this.refoundNotes = "";
    this.reservationsSchedulesPaymentsData = "";
  }
  fillData(data) {
    this.reservationScheduleToken = data.reservationScheduleToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.reservationToken = data.reservationToken;
    this.reservationNameCurrent = data.reservationNameCurrent;
    this.reservationScheduleCode = data.reservationScheduleCode;
    this.reservationScheduleNameAr = data.reservationScheduleNameAr;
    this.reservationScheduleNameEn = data.reservationScheduleNameEn;
    this.reservationScheduleNameUnd = data.reservationScheduleNameUnd;
    this.reservationScheduleStatusToken = data.reservationScheduleStatusToken;
    this.reservationScheduleStatusNameCurrent =
      data.reservationScheduleStatusNameCurrent;
    this.reservationScheduleStatusNameAr = data.reservationScheduleStatusNameAr;
    this.reservationScheduleStatusNameEn = data.reservationScheduleStatusNameEn;
    this.reservationScheduleStatusNameUnd =
      data.reservationScheduleStatusNameUnd;
    this.reservationScheduleNotes = data.reservationScheduleNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.reservationScheduleArchiveStatus =
      data.reservationScheduleArchiveStatus;
    this.requestDataUserToken = data.requestDataUserToken;
    this.requestDataUserNameCurrent = data.requestDataUserNameCurrent;
    this.requestDataDatetime = data.requestDataDatetime;
    this.requestDataDate = data.requestDataDate;
    this.requestDataTime = data.requestDataTime;
    this.approveDataDatetime = data.approveDataDatetime;
    this.approveDataDate = data.approveDataDate;
    this.approveDataTime = data.approveDataTime;
    this.approveUserToken = data.approveUserToken;
    this.approveUserNameCurrent = data.approveUserNameCurrent;
    this.approveNotes = data.approveNotes;
    this.clientToken = data.clientToken;
    this.clientNameCurrent = data.clientNameCurrent;
    this.clientNameAr = data.clientNameAr;
    this.clientNameEn = data.clientNameEn;
    this.clientNameUnd = data.clientNameUnd;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placeNameAr = data.placeNameAr;
    this.placeNameEn = data.placeNameEn;
    this.placeNameUnd = data.placeNameUnd;
    this.activityToken = data.activityToken;
    this.activityNameCurrent = data.activityNameCurrent;
    this.activityNameAr = data.activityNameAr;
    this.activityNameEn = data.activityNameEn;
    this.activityNameUnd = data.activityNameUnd;
    this.placeWorkPlanDayToken = data.placeWorkPlanDayToken;
    this.dayToken = data.dayToken;
    this.dayNameCurrent = data.dayNameCurrent;
    this.dayNameAr = data.dayNameAr;
    this.dayNameEn = data.dayNameEn;
    this.dayNameUnd = data.dayNameUnd;
    this.dateTimeFrom = data.dateTimeFrom;
    this.dateFrom = data.dateFrom;
    this.timeFrom = data.timeFrom;
    this.dateTimeTo = data.dateTimeTo;
    this.dateTo = data.dateTo;
    this.timeTo = data.timeTo;
    this.placeWorkPlanToken = data.placeWorkPlanToken;
    this.placeWorkPlanNameCurrent = data.placeWorkPlanNameCurrent;
    this.placeWorkPlanNameEn = data.placeWorkPlanNameEn;
    this.placeWorkPlanNameAr = data.placeWorkPlanNameAr;
    this.placeWorkPlanNameUnd = data.placeWorkPlanNameUnd;
    this.placeScheduleToken = data.placeScheduleToken;
    this.placeScheduleNameCurrent = data.placeScheduleNameCurrent;
    this.placeScheduleNameEn = data.placeScheduleNameEn;
    this.placeScheduleNameAr = data.placeScheduleNameAr;
    this.placeScheduleNameUnd = data.placeScheduleNameUnd;
    this.placeScheduleTimeFrom = data.placeScheduleTimeFrom;
    this.placeScheduleTimeTo = data.placeScheduleTimeTo;
    this.placeScheduleDuration = data.placeScheduleDuration;
    this.pricePerHour = data.pricePerHour;
    this.placeScheduleTypeToken = data.placeScheduleTypeToken;
    this.placeScheduleTypeNameCurrent = data.placeScheduleTypeNameCurrent;
    this.placeScheduleTypeNameAr = data.placeScheduleTypeNameAr;
    this.placeScheduleTypeNameEn = data.placeScheduleTypeNameEn;
    this.placeScheduleTypeNameUnd = data.placeScheduleTypeNameUnd;
    this.feesTypeToken = data.feesTypeToken;
    this.feesTypeNameCurrent = data.feesTypeNameCurrent;
    this.feesTypeNameAr = data.feesTypeNameAr;
    this.feesTypeNameEn = data.feesTypeNameEn;
    this.feesTypeNameUnd = data.feesTypeNameUnd;
    this.feesTypeValue = data.feesTypeValue;
    this.totalPaid = data.totalPaid;
    this.actualPrice = data.actualPrice;
    this.remainingAmount = data.remainingAmount;
    this.refoundMethodPaidToken = data.refoundMethodPaidToken;
    this.refoundMethodPaidNameCurrent = data.refoundMethodPaidNameCurrent;
    this.refoundReasonAr = data.refoundReasonAr;
    this.refoundReasonEn = data.refoundReasonEn;
    this.refoundReasonUnd = data.refoundReasonUnd;
    this.refundPercentage = data.refundPercentage;
    this.refoundValue = data.refoundValue;
    this.totalPaidWithRefound = data.totalPaidWithRefound;
    this.refoundNotes = data.refoundNotes;
    this.reservationsSchedulesPaymentsData =
      data.reservationsSchedulesPaymentsData;
  }

  async approveReservationSchedule(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationScheduleToken: this.reservationScheduleToken,
      approveNotes: this.approveNotes,
    };

    try {
      let response = await axios.post(
        `/ReservationsSchedules//ApprovedReservationSchedule`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
