<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEmployee"
    >
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.backgroundImage = url(${defaultImg})`"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <b-button v-b-toggle.personalData class="btn btn-lg btn-collapse">
        {{ $t("personalData") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="personalData">
        <div class="my-card">
          <span class="my-card-title">{{ $t("personalData") }}</span>

          <div class="row">
            <CustomInput
              :className="'col-md-6 col-lg-4'"
              :id="'employeeNameAr'"
              :value="employeeData.employeeNameAr"
              :title="$t('employeeNameAr')"
              :imgName="'employees.svg'"
              v-on:changeValue="employeeData.employeeNameAr = $event"
            />
            <CustomInput
              :className="'col-md-6 col-lg-4'"
              :id="'employeeNameEn'"
              :value="employeeData.employeeNameEn"
              :title="$t('employeeNameEn')"
              :imgName="'employees.svg'"
              v-on:changeValue="employeeData.employeeNameEn = $event"
            />
            <CustomInput
              :className="'col-md-6 col-lg-4'"
              :id="'employeeNameUnd'"
              :value="employeeData.employeeNameUnd"
              :title="$t('employeeNameUnd')"
              :imgName="'employees.svg'"
              v-on:changeValue="employeeData.employeeNameUnd = $event"
            />

            <TextArea
              :className="'col-md-6 col-lg-4'"
              :id="'employeeAddressAr'"
              :value="employeeData.employeeAddressAr"
              :title="$t('addressAr')"
              :imgName="'address.svg'"
              v-on:changeValue="employeeData.employeeAddressAr = $event"
            />
            <TextArea
              :className="'col-md-6 col-lg-4'"
              :id="'employeeAddressEn'"
              :value="employeeData.employeeAddressEn"
              :title="$t('addressEn')"
              :imgName="'address.svg'"
              v-on:changeValue="employeeData.employeeAddressEn = $event"
            />
            <TextArea
              :className="'col-md-6 col-lg-4'"
              :id="'employeeAddressUnd'"
              :value="employeeData.employeeAddressUnd"
              :title="$t('addressUnd')"
              :imgName="'address.svg'"
              v-on:changeValue="employeeData.employeeAddressUnd = $event"
            />

            <DatePicker
              :className="'col-md-6 col-lg-6'"
              :id="'employeeBirthdate'"
              :value="employeeData.employeeBirthdate"
              :title="$t('birthdate')"
              :language="language"
              v-on:changeValue="employeeData.employeeBirthdate = $event"
            />
            <!-- <div class="form-label-group col-md-6 col-lg-6">
              <b-form-select
                v-model="employeeData.employeeQualificationToken"
                :options="employeeQualificationTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/qualifications.svg" />
            </div> -->
            <div class="form-label-group col-md-6 col-lg-6">
              <b-form-select
                v-model="employeeData.employeeGenderToken"
                :options="employeeGenderTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/gender.svg" />
            </div>

            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.employeeCountryToken"
                :options="countryTokenOptions"
                class="form-control"
                @change="changeGovernate()"
              ></b-form-select>
              <img src="@/assets/images/country.svg" />
            </div>
            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.employeeGovernorateToken"
                :options="governateTokenOptions"
                class="form-control"
                @change="changeCity()"
              ></b-form-select>
              <img src="@/assets/images/governate.svg" />
            </div>
            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.employeeCityToken"
                :options="cityTokenOptions"
                class="form-control"
                @change="changeDistrict()"
              ></b-form-select>
              <img src="@/assets/images/city.svg" />
            </div>
            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.employeeDistrictToken"
                :options="districtTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/district.svg" />
            </div>
            <!-- 
            <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.employeeInsuranceCompanyToken"
                :options="employeeInsuranceCompanyTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/type.svg" />
            </div>
            <CustomInput
              :className="'col-md-6 col-lg-4'"
              :id="'employeeInsuranceNumber'"
              :value="employeeData.employeeInsuranceNumber"
              :title="$t('insuranceNumber')"
              :imgName="'number.svg'"
              v-on:changeValue="employeeData.employeeInsuranceNumber = $event"
            /> -->

            <!-- <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.employeeBankToken"
                :options="employeeBankTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/banks.svg" />
            </div> -->
            <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.employeeCitizenShipTypeToken"
                :options="employeeCitizenShipTypeTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/citizenship.svg" />
            </div>

            <DatePicker
              :className="'col-md-6 col-lg-4'"
              :id="'employeeResidencyStartDate'"
              :value="employeeData.employeeResidencyStartDate"
              :title="$t('employeeResidencyStartDate')"
              :language="language"
              v-on:changeValue="
                employeeData.employeeResidencyStartDate = $event
              "
            />
            <DatePicker
              :className="'col-md-6 col-lg-4'"
              :id="'employeeResidencyEndDate'"
              :value="employeeData.employeeResidencyEndDate"
              :title="$t('employeeResidencyEndDate')"
              :language="language"
              v-on:changeValue="employeeData.employeeResidencyEndDate = $event"
            />
            <DatePicker
              :className="'col-md-6 col-lg-6'"
              :id="'employeeAddingDateToSystem'"
              :value="employeeData.employeeAddingDateToSystem"
              :title="$t('employeeAddingDateToSystem')"
              :language="language"
              v-on:changeValue="
                employeeData.employeeAddingDateToSystem = $event
              "
            />

            <CustomInput
              :className="'col-md-12 col-lg-12'"
              :id="'employeeResidencyNumber'"
              :value="employeeData.employeeResidencyNumber"
              :title="$t('employeeResidencyNumber')"
              :imgName="'number.svg'"
              v-on:changeValue="employeeData.employeeResidencyNumber = $event"
            />
            <div class="form-label-group col-md-6">
              <b-form-select
                v-model="employeeData.employeeMaritalToken"
                :options="employeeMaritalTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/maritalStatus.svg" />
            </div>
            <TextArea
              :className="'col-md-6'"
              :id="'employeeMaritalStatusNotes'"
              :value="employeeData.employeeMaritalStatusNotes"
              :title="$t('maritalStatusNotes')"
              :imgName="'notes.svg'"
              v-on:changeValue="
                employeeData.employeeMaritalStatusNotes = $event
              "
            />

            <div class="form-label-group col-md-6">
              <b-form-select
                v-model="employeeData.employeeMilitaryToken"
                :options="employeeMilitaryTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/militaryStatus.svg" />
            </div>
            <TextArea
              :className="'col-md-6'"
              :id="'employeeMilitaryStatusNotes'"
              :value="employeeData.employeeMilitaryStatusNotes"
              :title="$t('militaryStatusNotes')"
              :imgName="'notes.svg'"
              v-on:changeValue="
                employeeData.employeeMilitaryStatusNotes = $event
              "
            />
            <!-- 
            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.employeeBloodTypeToken"
                :options="bloodTypeTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/blood-types.svg" />
            </div> -->
          </div>
        </div>
      </b-collapse>

      <b-button v-b-toggle.employeeData class="btn btn-lg btn-collapse">
        {{ $t("employeeData") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="employeeData">
        <div class="my-card">
          <span class="my-card-title">{{ $t("employeeData") }}</span>

          <div class="row">
            <TelPicker
              :className="'col-md-6 col-lg-6'"
              :value="employeeData.employeeCCWithCCName"
              v-on:changeCC="employeeData.employeeCCWithCCName = $event"
            />
            <CustomInput
              :className="'col-md-6 col-lg-6'"
              :id="'employeePhone'"
              :value="employeeData.employeePhone"
              :title="$t('phoneNumber')"
              :imgName="'phone.svg'"
              v-on:changeValue="employeeData.employeePhone = $event"
            />
            <CustomInput
              :className="'col-md-6 col-lg-6'"
              :id="'employeeEmail'"
              :value="employeeData.employeeEmail"
              :title="$t('email')"
              :imgName="'email.svg'"
              v-on:changeValue="employeeData.employeeEmail = $event"
            />

            <CustomInput
              :className="'col-md-6 col-lg-6'"
              :id="'employeeUserName'"
              :value="employeeData.employeeUserName"
              :title="$t('userName')"
              :imgName="'man.svg'"
              v-on:changeValue="employeeData.employeeUserName = $event"
            />

            <CustomInput
              :className="'col-md-6 col-lg-6'"
              :id="'employeeCode'"
              :value="employeeData.employeeCode"
              :title="$t('employeeCode')"
              :imgName="'number.svg'"
              v-on:changeValue="employeeData.employeeCode = $event"
            />
            <div class="form-label-group col-6">
              <b-form-select
                v-model="employeeData.establishmentRoleToken"
                :options="establishmentRoleTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/establishment-roles.svg" />
            </div>
            <div class="form-label-group col-md-6 col-lg-6">
              <b-form-select
                v-model="employeeData.employeeTypeToken"
                :options="employeeTypeTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/type.svg" />
            </div>
            <CustomCheckbox
              :className="'col-md-6 col-lg-6'"
              :value="employeeData.employeeActiveStatus"
              :title="$t('employeeActiveStatus')"
              v-on:changeValue="employeeData.employeeActiveStatus = $event"
            />
          </div>
        </div>
      </b-collapse>

      <b-button v-b-toggle.identifiersCardsData class="btn btn-lg btn-collapse">
        {{ $t("identifiersCardsData") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="identifiersCardsData">
        <div class="my-card">
          <span class="my-card-title">{{ $t("identifiersCardsData") }}</span>

          <div class="row">
            <CustomInput
              :className="'col-md-6'"
              :id="'employeeNationalNumber'"
              :value="employeeData.employeeNationalNumber"
              :title="$t('nationalNumber')"
              :imgName="'nationalNumber.svg'"
              v-on:changeValue="employeeData.employeeNationalNumber = $event"
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeNationalNumberExpireDate'"
              :value="employeeData.employeeNationalNumberExpireDate"
              :title="$t('nationalNumberExpireDate')"
              :language="language"
              v-on:changeValue="
                employeeData.employeeNationalNumberExpireDate = $event
              "
            />

            <CustomInput
              :className="'col-md-6'"
              :id="'employeeDrivingLicenceNumber'"
              :value="employeeData.employeeDrivingLicenceNumber"
              :title="$t('drivingLicenceNumber')"
              :imgName="'drivingLicense.svg'"
              v-on:changeValue="
                employeeData.employeeDrivingLicenceNumber = $event
              "
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeDrivingLicenceExpireDate'"
              :value="employeeData.employeeDrivingLicenceExpireDate"
              :title="$t('drivingLicenceExpireDate')"
              :language="language"
              v-on:changeValue="
                employeeData.employeeDrivingLicenceExpireDate = $event
              "
            />

            <CustomInput
              :className="'col-md-6'"
              :id="'employeePassportNumber'"
              :value="employeeData.employeePassportNumber"
              :title="$t('passportNumber')"
              :imgName="'passportNumber.svg'"
              v-on:changeValue="employeeData.employeePassportNumber = $event"
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeePassportExpireDate'"
              :value="employeeData.employeePassportExpireDate"
              :title="$t('passportExpireDate')"
              :language="language"
              v-on:changeValue="
                employeeData.employeePassportExpireDate = $event
              "
            />
          </div>
        </div>
      </b-collapse>

      <b-button v-b-toggle.generalData class="btn btn-lg btn-collapse">
        {{ $t("generalData") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="generalData">
        <div class="my-card">
          <span class="my-card-title">{{ $t("generalData") }}</span>

          <div class="row">
            <!-- <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.knownMethodToken"
                :options="knownMethodTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/type.svg" />
            </div>

            <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.employeeRelativeRelationToken"
                :options="employeeRelativeRelationTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/relative-relation.svg" />
            </div>
            <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="employeeData.employeeTypeToken"
                :options="employeeTypeTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/type.svg" />
            </div>
             -->

            <TextArea
              :className="'col-md-12 col-lg-12'"
              :id="'employeeNotes'"
              :value="employeeData.employeeNotes"
              :title="$t('notes')"
              :imgName="'notes.svg'"
              v-on:changeValue="employeeData.employeeNotes = $event"
            />
          </div>
        </div>
      </b-collapse>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/employees" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { ROLE_TYPE } from "@/utils/constantLists";
import DEFAULT_IMG_CLIENT from "@/assets/images/employee.svg";
import { setDataMultiLang } from "@/utils/functions";
import axios from "axios";
import DatePicker from "@/components/general/DatePicker.vue";
import TelPicker from "@/components/general/TelPicker.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import Country from "@/models/settings/settingsOther/countries/Country";
import Governate from "@/models/settings/settingsOther/governates/Governate";
import City from "@/models/settings/settingsOther/cities/City";
import District from "@/models/settings/settingsOther/districts/District";

export default {
  name: "EmployeeForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DatePicker,
    TelPicker,
    CustomInput,
    CustomCheckbox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      countryData: new Country(),
      governateData: new Governate(),
      cityData: new City(),
      districtData: new District(),
      defaultImg: DEFAULT_IMG_CLIENT,
      establishmentRoleTokenOptions: [],
      employeeInsuranceCompanyTokenOptions: [],
      countryTokenOptions: [],
      governateTokenOptions: [],
      cityTokenOptions: [],
      districtTokenOptions: [],
      employeeQualificationTokenOptions: [],
      employeeBankTokenOptions: [],
      employeeRelativeRelationTokenOptions: [],
      employeeGenderTokenOptions: [],
      employeeTypeTokenOptions: [],
      employeeMaritalTokenOptions: [],
      employeeMilitaryTokenOptions: [],
      employeeCitizenShipTypeTokenOptions: [],
      knownMethodTokenOptions: [],
      bloodTypeTokenOptions: [],
      showPassword: false,
    };
  },
  props: ["employeeData", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.employeeData.image = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG_CLIENT;
        this.employeeData.image = "";
      }
      // console.log(this.imageSrc);
    },

    async getDialogOfUserRoles() {
      this.isLoading = true;
      this.establishmentRoleTokenOptions = [];
      this.establishmentRoleTokenOptions.push({
        value: "",
        text: this.$t("general.role.select"),
      });
      try {
        const response = await axios.get(
          `/EstablishmentRoles/GetDialogOfEstablishmentRoles?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&roleTypeToken=${ROLE_TYPE.Employee}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.establishmentRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfInsuranceCompanies() {
      this.isLoading = true;
      this.employeeInsuranceCompanyTokenOptions = [];
      this.employeeInsuranceCompanyTokenOptions.push({
        value: "",
        text: this.$t("selectInsuranceCompany"),
      });
      try {
        const response = await axios.get(
          `/InsuranceCompanies/GetDialogOfInsuranceCompanies?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeInsuranceCompanyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfCountries() {
      this.isLoading = true;
      this.countryTokenOptions = [];
      this.countryTokenOptions.push({
        value: "",
        text: this.$t("countries.select"),
      });
      try {
        const response = await this.countryData.getDialogOfCountries(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.countryTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.countryTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeGovernate() {
      this.employeeData.employeeGovernorateToken = "";
      this.employeeData.employeeCityToken = "";
      this.employeeData.districtToken = "";
      this.getDialogOfGovernates(this.employeeData.employeeCountryToken);
    },
    async getDialogOfGovernates(countryToken) {
      this.isLoading = true;
      this.governateTokenOptions = [];
      this.governateTokenOptions.push({
        value: "",
        text: this.$t("governates.select"),
      });
      try {
        const response = await this.governateData.getDialogOfGovernates(
          this.language,
          this.userAuthorizeToken,
          countryToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.governateTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.governateTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeCity() {
      this.employeeData.employeeCityToken = "";
      this.employeeData.districtToken = "";
      this.getDialogOfCities(this.employeeData.employeeGovernorateToken);
    },
    async getDialogOfCities(cityToken) {
      this.isLoading = true;
      this.cityTokenOptions = [];
      this.cityTokenOptions.push({
        value: "",
        text: this.$t("cities.select"),
      });
      try {
        const response = await this.cityData.getDialogOfCities(
          this.language,
          this.userAuthorizeToken,
          cityToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.cityTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.cityTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeDistrict() {
      this.employeeData.districtToken = "";
      this.getDialogOfDistricts(this.employeeData.employeeCityToken);
    },
    async getDialogOfDistricts(cityToken) {
      this.isLoading = true;
      this.districtTokenOptions = [];
      this.districtTokenOptions.push({
        value: "",
        text: this.$t("districts.select"),
      });
      try {
        const response = await this.districtData.getDialogOfDistricts(
          this.language,
          this.userAuthorizeToken,
          cityToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.districtTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.districtTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfQualifications() {
      this.isLoading = true;
      this.employeeQualificationTokenOptions = [];
      this.employeeQualificationTokenOptions.push({
        value: "",
        text: this.$t("selectQualification"),
      });
      try {
        const response = await axios.get(
          `/Qualifications/GetDialogOfQualifications?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeQualificationTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfBanks() {
      this.isLoading = true;
      this.employeeBankTokenOptions = [];
      this.employeeBankTokenOptions.push({
        value: "",
        text: this.$t("selectBank"),
      });
      try {
        const response = await axios.get(
          `/Banks/GetDialogOfBanks?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeBankTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfRelativeRelations() {
      this.isLoading = true;
      this.employeeRelativeRelationTokenOptions = [];
      this.employeeRelativeRelationTokenOptions.push({
        value: "",
        text: this.$t("selectRelativeRelation"),
      });
      try {
        const response = await axios.get(
          `/RelativeRelations/GetDialogOfRelativeRelations?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeRelativeRelationTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfGenders() {
      this.isLoading = true;
      this.employeeGenderTokenOptions = [];
      this.employeeGenderTokenOptions.push({
        value: "",
        text: this.$t("selectGender"),
      });
      let genders = this.$store.getters.userData.constantsLists.listGender;
      for (let item in genders) {
        this.employeeGenderTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeTypes() {
      this.isLoading = true;
      this.employeeTypeTokenOptions = [];
      this.employeeTypeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployeeType"),
      });
      let genders =
        this.$store.getters.userData.constantsLists.listEmployeeType;
      for (let item in genders) {
        this.employeeTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfMaritals() {
      this.isLoading = true;
      this.employeeMaritalTokenOptions = [];
      this.employeeMaritalTokenOptions.push({
        value: "",
        text: this.$t("selectMaritalStatus"),
      });
      let maritals = this.$store.getters.userData.constantsLists.listMarital;
      for (let item in maritals) {
        this.employeeMaritalTokenOptions.push({
          value: maritals[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            maritals[item]["itemNameAr"],
            maritals[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfMilitaries() {
      this.isLoading = true;
      this.employeeMilitaryTokenOptions = [];
      this.employeeMilitaryTokenOptions.push({
        value: "",
        text: this.$t("selectMilitaryStatus"),
      });
      let militaries = this.$store.getters.userData.constantsLists.listMilitary;
      for (let item in militaries) {
        this.employeeMilitaryTokenOptions.push({
          value: militaries[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            militaries[item]["itemNameAr"],
            militaries[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfCitizenShipType() {
      this.isLoading = true;
      this.employeeCitizenShipTypeTokenOptions = [];
      this.employeeCitizenShipTypeTokenOptions.push({
        value: "",
        text: this.$t("selectCitizenShipType"),
      });
      let itemsData =
        this.$store.getters.userData.constantsLists.listCitizenShipType;
      for (let item in itemsData) {
        this.employeeCitizenShipTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeType() {
      this.isLoading = true;
      this.employeeTypeTokenOptions = [];
      this.employeeTypeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployeeType"),
      });
      let itemsData =
        this.$store.getters.userData.constantsLists.listEmployeeType;
      for (let item in itemsData) {
        this.employeeTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfKnownMethods() {
      this.isLoading = true;
      this.knownMethodTokenOptions = [];
      this.knownMethodTokenOptions.push({
        value: "",
        text: this.$t("selectKnownMethod"),
      });
      try {
        const response = await axios.get(
          `KnowingMethods/GetDialogOfKnowingMethods?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.knownMethodTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfBloodTypes() {
      this.isLoading = true;
      this.bloodTypeTokenOptions = [];
      this.bloodTypeTokenOptions.push({
        value: "",
        text: this.$t("selectBloodType"),
      });
      try {
        const response = await axios.get(
          `/BloodTypes/GetDialogOfBloodTypes?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.bloodTypeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.bloodTypeTokenOptions = null;
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployee() {
      this.$emit("addOrUpdateEmployee");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {
    "employeeData.employeeCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.employeeCountryCode = cc[1];
      this.employeeData.employeeCountryCodeName = cc[0];
    },
  },
  async created() {
    this.getDialogOfUserRoles();
    this.getDialogOfInsuranceCompanies();
    this.getDialogOfCountries();
    this.getDialogOfGovernates(this.employeeData.employeeCountryToken);
    this.getDialogOfCities(this.employeeData.employeeGovernorateToken);
    this.getDialogOfDistricts(this.employeeData.employeeCityToken);
    this.getDialogOfQualifications();
    this.getDialogOfBanks();
    this.getDialogOfRelativeRelations();
    this.getDialogOfGenders();
    this.getDialogOfEmployeeTypes();
    this.getDialogOfMaritals();
    this.getDialogOfMilitaries();
    this.getDialogOfCitizenShipType();
    this.getDialogOfEmployeeType();
    this.getDialogOfKnownMethods();
    this.getDialogOfBloodTypes();
  },
};
</script>

<style lang="scss"></style>
